export type Digits = {
  id: number;
  letters: string;
};

const digitList: readonly Digits[] = [
  { id: 1, letters: "un" },
  { id: 2, letters: "deux" },
  { id: 3, letters: "trois" },
  { id: 4, letters: "quatre" },
  { id: 5, letters: "cinq" },
  { id: 6, letters: "six" },
  { id: 7, letters: "sept" },
  { id: 8, letters: "huit" },
  { id: 9, letters: "neuf" },
] as const;

function shuffleArray(array: Digits[]): Digits[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function getRandomItems(count: number, code: Digits[]): Digits[] {
  const copyArr = [...digitList];
  const result: Digits[] = [];
  for (let i = 0; i < code.length; i++) {
    const index = copyArr.findIndex(item => item.id === code[i].id);
    if (index !== -1) {
      const item = copyArr.splice(index, 1)[0];
      result.push(item);
      count--;
    }
  }
  for (let i = 0; i < count; i++) {
    if (copyArr.length > 0) {
      const randomIndex = Math.floor(Math.random() * copyArr.length);
      const item = copyArr.splice(randomIndex, 1)[0];
      result.push(item);
    }
  }
  return shuffleArray(result);
}

export function getRandomItemsCode(count: number): Digits[] {
  const indices = Array.from({ length: digitList.length }, (_, i) => i);
  const result: Digits[] = [];
  for (let i = 0; i < count; i++) {
    if (indices.length > 0) {
      const randomIndex = Math.floor(Math.random() * indices.length);
      const itemIndex = indices.splice(randomIndex, 1)[0]; // remove the used index
      const item = digitList[itemIndex];
      result.push(item);
    }
  }
  return result;
}
