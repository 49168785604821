import { platform, PlatformType } from "~ui-lib";

export interface IConfigConnexionUrls {
  background: string;
  inscription: string;
  connexion: string;
  qrCodeConnexionSrc: string;
  qrCodeSubscribeSrc: string;
}

type EnabledFeatures = {
  account: boolean;
  cmp: boolean;
  live: boolean;
  login: boolean;
  lowDevice: boolean;
};

const fullEnabledFeatures: EnabledFeatures = {
  account: true,
  cmp: true,
  live: true,
  login: true,
  lowDevice: false,
};

const _enabledFeaturesMap: Record<PlatformType, EnabledFeatures> = {
  [PlatformType.tizen]: fullEnabledFeatures,
  [PlatformType.webos]: fullEnabledFeatures,
  [PlatformType.hisense]: fullEnabledFeatures,
  [PlatformType.orange]: {
    account: false,
    cmp: false,
    live: false,
    login: false,
    lowDevice: true,
  },
  [PlatformType.philips]: fullEnabledFeatures,
  [PlatformType.other]: {
    account: false,
    cmp: false,
    live: false,
    login: false,
    lowDevice: true,
  },
};

export const Config = {
  delaySplashScreen: 2000,
  server: {
    dev: "https://proxy-mediation.yatta.francetv.fr/prod/v2/mobile",
    prod: "https://proxy-mediation.yatta.francetv.fr/prod/v2/mobile",
    preprod: "https://proxy-mediation.yatta.francetv.fr/preprod/v2/mobile",
  },
  // n'est plus utilisé -> à retirer lors d'un nettoyage
  player: {
    prod: "https://proxy-mediation.yatta.francetv.fr/prod/v2/gateway-player",
    preprod: "https://proxy-mediation.yatta.francetv.fr/preprod/v2/gateway-player",
  },
  // n'est plus utilisé -> à retirer lors d'un nettoyage
  ads: {
    prod: "https://proxy-mediation.yatta.francetv.fr/prod/v2/pub",
    preprod: "https://proxy-mediation.yatta.francetv.fr/preprod/v2/pub",
  },
  seeks: {
    prod: "https://proxy-mediation.yatta.francetv.fr/prod/v2/transaction",
    preprod: "https://proxy-mediation.yatta.francetv.fr/preprod/v2/transaction",
  },
  ratings: {
    prod: "",
    preprod: "",
  },
  favorites: {
    prod: "https://proxy-mediation.yatta.francetv.fr/prod/v2/transaction",
    preprod: "https://proxy-mediation.yatta.francetv.fr/preprod/v2/transaction",
  },
  didomi: {
    prod: {
      organizationId: "france-tv",
      apiUrl: "https://consent.france.tv",
      privateAPIKey: "3ce0ddb3-c3cc-4378-ba9e-e861e3b073dd",
      secretAPIKey: "FZxUbdgjMxWgDKeYeWrTJ7fgAW8b8Uyypyx42cyGzf22pWtNaULgWZeEntFp7xWd",
    },
    preprod: {
      organizationId: "france-tv-preprod",
      apiUrl: "https://consent.francetv-preprod.fr",
      privateAPIKey: "aaa66b23-55ab-453a-98f7-ed62746aff4c",
      secretAPIKey: "GHjLWfih96aBeinTMrRPtpHdiBwGBEmFkAy9rnzyDdM9WzjpxJ9in9PrDdPJZ9j8",
    },
  },
  piano: {
    prod: "https://proxy-mediation.yatta.francetv.fr/prod/v2/xiti",
    preprod: "https://proxy-mediation.yatta.francetv.fr/preprod/v2/xiti",
  } as const,
  proxyGin: {
    prod: "",
    preprod: "",
  },
  connexion: {
    prod: ((): IConfigConnexionUrls => {
      return {
        background: "https://www.france.tv/export/smart_tv/splash_screen.mp4",
        inscription: "france.tv/appstv/inscription",
        connexion: "france.tv/appstv/connexion",
        qrCodeConnexionSrc: require("~images/connection/QRCodeConnexionProd.png"),
        qrCodeSubscribeSrc: require("~images/connection/QRCodeSubscribeProd.png"),
      };
    })(),
    preprod: ((): IConfigConnexionUrls => {
      return {
        background: "https://www.francetv-preprod.fr/export/smart_tv/splash_screen.mp4",
        inscription: "https://www.francetv-preprod.fr/appstv/inscription",
        connexion: "https://www.francetv-preprod.fr/appstv/connexion",
        qrCodeConnexionSrc: require("~images/connection/QRCodeConnexionPreprod.png"),
        qrCodeSubscribeSrc: require("~images/connection/QRCodeSubscribePreprod.png"),
      };
    })(),
  },
  spideo: {
    prod: "https://reco.webservices.francetelevisions.fr",
    preprod: "https://reco.webservices.ftvg-preprod.fr",
  },
  enabledFeatures: _enabledFeaturesMap[platform.type],
  openedPlayerFromDeeplink: false,
};
