import "./playerProgress.scss";

import { Plugin } from "~pages/../datas/plugin";
import { debounce, DOMHelper, Keys, Listenable, platform, PlatformType, Point, screenRectOf, View } from "~ui-lib";

import { Player, PlayerState } from "../../libs/player";
import { PlayableItem } from "../../models/playableItem";
import { Thumbnail } from "../../models/spritesheet";
import { ApiOrange } from "../../tools/apiOrange/apiOrange";
import { getOrangeProviderIdFromBroadcastChannel } from "../../tools/apiOrange/channelOrangeProviderIdMap";
import { PlayHistoryHelper } from "../../tools/playerHistoryHelper";
import { PlayerPageSpriteSheet } from "./playerPage";

const pad = function (thing: number | string): string | number {
  return thing < 10 ? "0" + thing : thing;
};

const getFormatedTime = function (timeInSeconds: number): string {
  let timeT = "00:00:00";
  let ht = "";
  const totalTimeHour = Math.floor(timeInSeconds / 3600),
    totalTimeMinute = Math.floor((timeInSeconds % 3600) / 60),
    totalTimeSecond = Math.floor(timeInSeconds % 60);
  if (!isNaN(totalTimeHour) && !isNaN(totalTimeMinute) && !isNaN(totalTimeSecond)) {
    if (totalTimeHour > 0) {
      ht = pad(totalTimeHour) + ":";
    } else {
      ht = "00:";
    }
    timeT = ht + pad(totalTimeMinute) + ":" + pad(totalTimeSecond);
  }
  return timeT;
};

export class PlayerProgress extends View {
  progressDOM: HTMLElement;
  currentTimeDOM: HTMLElement;
  durationTimeDOM: HTMLElement;
  timelineDOM: HTMLElement;
  TIME_INCREMENT_FW = 30;
  TIME_INCREMENT_RW = 30;

  onCurrentTimeUnregister: () => void;
  onDurationUnregister: () => void;
  progressSeekTimeUnregister: () => void;

  player: Player;
  item: PlayableItem;
  isLive$: Listenable<boolean>;
  hitsTimer?: number;
  ItemVideoViewBox: HTMLElement | null;
  private spritesheets: PlayerPageSpriteSheet | undefined;
  private spritesheetTimeLine: HTMLElement | null = null;
  private spritesheetCard: HTMLElement[] | null = null;

  constructor(
    isLive$: Listenable<boolean>,
    player: Player,
    item: PlayableItem,
    spritesheets: PlayerPageSpriteSheet | undefined
  ) {
    super(DOMHelper.createDivWithParent(null, null, "playerProgressContainer"));
    this.spritesheets = spritesheets;
    this.isLive$ = isLive$;
    this.player = player;
    this.item = item;

    this.currentTimeDOM = DOMHelper.createDivWithParent(this.rootElement, null, "playerCurrentTime", "");
    this.durationTimeDOM = DOMHelper.createDivWithParent(this.rootElement, null, "playerDuration", "");
    this.timelineDOM = DOMHelper.createDivWithParent(this.rootElement, null, "playerTimeline");
    this.progressDOM = DOMHelper.createDivWithParent(this.timelineDOM, null, "playerTimelinePercent");
    DOMHelper.createDivWithParent(this.progressDOM, null, "round");
    this.ItemVideoViewBox = document.getElementById("ItemVideoViewBox");
    this.spritesheetsTimeLine();
    // initial setting
    this.updateProgressUI();
    this.onCurrentTimeUnregister = this.player.currentTime$.didChange(this.updateProgressUI);
    this.onDurationUnregister = this.player.duration$.didChange(this.updateProgressUI);
    this.progressSeekTimeUnregister = this.player.seekTime$.didChange(this.updateProgressUI);
    window.clearInterval(this.hitsTimer);
    if (Plugin.getInstance().user.isActive()) {
      let minimumTimePing: number;
      this.hitsTimer = window.setInterval(() => {
        if (!this.player.isAdVideoPlaying()) {
          const time = this.player.seekTime$.value ?? this.player.currentTime$.value;
          if (!minimumTimePing) {
            minimumTimePing = this.player.duration$.value - (this.player.duration$.value * 98) / 100;
          } else if (minimumTimePing < time) {
            this._putHits();
            console.error("-------------------- minimumTimePing " + time);
          } else {
            console.error("-------------------- otherPing " + time);
          }
        } else console.error("is an ads timer !!!");
      }, 10000);
    }
  }

  private updateUISpritesheets(thumbnails: (Thumbnail | null)[]) {
    let spritesheetIndex = 0;
    for (const thumbnail of thumbnails) {
      const spritesheetCard = this.spritesheetCard?.[spritesheetIndex];
      if (spritesheetCard !== undefined) {
        if (thumbnail === null) {
          spritesheetCard.style.visibility = "hidden";
        } else {
          const cardElementWidth = spritesheetCard.clientWidth;
          const cardElementHeight = spritesheetCard.clientHeight;

          spritesheetCard.style.backgroundSize = `${cardElementWidth * thumbnail.columns}px ${
            cardElementHeight * thumbnail.lines
          }px`;
          spritesheetCard.style.backgroundImage = `url("${thumbnail.source}")`;
          spritesheetCard.style.backgroundColor = "black";
          spritesheetCard.style.backgroundPositionX = -thumbnail.xOffset * cardElementWidth + "px";
          spritesheetCard.style.backgroundPositionY = -thumbnail.yOffset * cardElementHeight + "px";
          spritesheetCard.style.visibility = "visible";
        }
      }

      spritesheetIndex++;
    }
  }

  private updateSpritesheets(position: number) {
    if (this.spritesheets !== undefined) {
      const { columns, lines, interval, images, width, height } = this.spritesheets;

      const getTileIndexWithPosition = (position: number) => {
        return Math.round(position / interval);
      };

      const TILES_PER_IMAGE = columns * lines;
      const MAX_TILES = getTileIndexWithPosition(this.item.metadata.duration);

      if (TILES_PER_IMAGE > 0) {
        /**
         * TODO: Is TIME_INCREMENT_FW equals to TIME_INCREMENT_RW ?
         */
        const thumbnailImages: (Thumbnail | null)[] = [
          getTileIndexWithPosition(position - 2 * this.TIME_INCREMENT_FW), // Position - 2 seeks intervals
          getTileIndexWithPosition(position - 1 * this.TIME_INCREMENT_FW), // Position - 1 seek  interval
          getTileIndexWithPosition(position), // Current position
          getTileIndexWithPosition(position + 1 * this.TIME_INCREMENT_FW), // Position + 1 seek  interval
          getTileIndexWithPosition(position + 2 * this.TIME_INCREMENT_FW), // Position + 2 seeks intervals
        ]
          .map(tileIndex => {
            if (tileIndex < 0) {
              /**
               * Tile index is negative
               * Meaning that current position is certainly at the begining of the video
               **/
              return null;
            } else if (tileIndex > MAX_TILES) {
              /**
               * Tile index is upper than the max number of tiles
               * Meaning that current position is certainly at the end of the video
               */
              return null;
            } else {
              return tileIndex;
            }
          })
          .map(tileIndex => {
            if (tileIndex === null) {
              /**
               * TileIndex is not reachable, propagating null value
               */
              return null;
            } else {
              /**
               * Image offset in tileset,
               *
               * Example, 2x3 tileset
               * TILES_PER_IMAGE = 9 tiles
               *  Tileset 1        Tileset 2
               *  [0, 1, 2],       [6,  7,  8],
               *  [3, 4, 5]        [9, 10, 11]
               * For index 2, we have an offset of 2,
               * For index 8, we also have an offset of 2
               */
              const imageOffset = tileIndex % TILES_PER_IMAGE;

              /**
               * Tileset index
               *
               * Example, 2x3 tileset
               * TILES_PER_IMAGE = 9 tiles
               *  Tileset 1        Tileset 2
               *  [0, 1, 2],       [6,  7,  8],
               *  [3, 4, 5]        [9, 10, 11]
               * For index 2, we have an tileset index of 0,
               * For index 8, we also have an offset of 1
               */
              const tilesetIndex = (tileIndex - imageOffset) / TILES_PER_IMAGE;

              /**
               * Converting offset into x, y positions
               * x["2D"] = x["1D"] % ROW_LENGTH;
               * y["2D"] = |x["1D"] / ROW_LENGTH|;
               *
               * Example:
               * For index 2, we have y = 2 % 3 = 2, and x = 2 / 3 = 1
               */
              const x = imageOffset % lines;
              const y = Math.trunc(imageOffset / lines);

              const tileWidth = width / columns;
              const tileHeight = height / lines;

              return {
                source: images?.[tilesetIndex].image.src,
                xOffset: x,
                yOffset: y,
                tileHeight,
                tileWidth,
                columns,
                lines,
              };
            }
          });

        this.updateUISpritesheets(thumbnailImages);
      }
    }
  }

  private spritesheetsTimeLine() {
    this.spritesheetTimeLine = DOMHelper.createDivWithParent(this.rootElement, null, "spritesheetTimeLine", null);
    this.spritesheetCard = [
      DOMHelper.createDivWithParent(this.spritesheetTimeLine, null, "cardTimeline", null),
      DOMHelper.createDivWithParent(this.spritesheetTimeLine, null, "cardTimeline", null),
      DOMHelper.createDivWithParent(this.spritesheetTimeLine, null, "cardTimeline middleCardTimeline", null),
      DOMHelper.createDivWithParent(this.spritesheetTimeLine, null, "cardTimeline", null),
      DOMHelper.createDivWithParent(this.spritesheetTimeLine, null, "cardTimeline", null),
    ];
  }

  private _putHits() {
    const user = Plugin.getInstance().user;
    if (this.isLive$.value === false && user.isActive()) {
      // ensure offset if between 0 and duration
      const offset = Math.max(
        0,
        Math.min(this.item.metadata.duration, Math.floor(this.player.seekTime$.value ?? this.player.currentTime$.value))
      );
      Plugin.getInstance()
        .putHits(user, this.item, offset)
        .subscribe(
          value => {
            Log.api.log("[putHits] next !", value);
          },
          error => {
            Log.api.log("[putHits] Error !", error);
          }
        );
      PlayHistoryHelper.updateOffset(this.item, offset);
      ApiOrange.fetchReplayPosition(this.item.id, offset, getOrangeProviderIdFromBroadcastChannel(this.item));
    }
  }

  private _getPlayerPosition = () => {
    return this.player.seekTime$.value === undefined ? this.player.position() : this.player.seekTime$.value;
  };

  private _seek = debounce((callback?: () => void) => {
    callback?.();
  }, 800);

  private _launchSeek = (key: Keys.left | Keys.right) => {
    if (
      [PlayerState.PLAYING, PlayerState.PAUSED, PlayerState.BUFFERING].indexOf(this.player.state$.value) !== -1 &&
      !this.isLive$.value
    ) {
      if (this.player.state$.value !== PlayerState.PAUSED) {
        this.player.sendEStat(PlayerState.PAUSED);
      }
      this.player.pause();
      let position =
        key === Keys.left
          ? Math.max(0, this._getPlayerPosition() - this.TIME_INCREMENT_RW)
          : Math.min(this._getPlayerPosition() + this.TIME_INCREMENT_FW, this.player.duration());
      // FIX from SAMSUNG : try not to jump to last possible second and limit it to jumping to video.duration-5
      if (platform.type === PlatformType.tizen && key === Keys.right) {
        if (this._getPlayerPosition() <= this.player.duration() - 5) {
          position = Math.min(position, this.player.duration() - 5);
        } else {
          // if the current postion is between duration-5 and duration, we don't jump because it will cause a rewind instead of forward
          position = this._getPlayerPosition();
        }
      }
      // END FIX
      const metadataHtmlElement = document.getElementById("metadataContainer");
      if (this.spritesheetTimeLine && this.spritesheets !== undefined) {
        if (metadataHtmlElement) {
          metadataHtmlElement.style.visibility = "hidden";
        }
        this.spritesheetTimeLine.style.visibility = "visible";
        this.updateSpritesheets(position);
      }
      this.player.setSeekTime(~~position);
      this._seek(() => {
        if (this.spritesheetTimeLine && this.spritesheets !== undefined) {
          const metadataHtmlElement = document.getElementById("metadataContainer");
          if (metadataHtmlElement) {
            metadataHtmlElement.style.visibility = "";
          }
          this.spritesheetTimeLine.style.visibility = "hidden";
          this.spritesheetCard?.map(card => (card.style.visibility = "hidden"));
        }
        this.player.sendEStat(PlayerState.PLAYING);
        this.player.play();
      });
    }
  };

  // arrow function so that it's automatically bound to this
  updateProgressUI = () => {
    const time = this.player.seekTime$.value ?? this.player.currentTime$.value;
    this.currentTimeDOM.innerText = getFormatedTime(time);
    this.durationTimeDOM.innerText = getFormatedTime(this.player.duration$.value);
    if (time < this.player.duration$.value) {
      this.progressDOM.style.width = `${(time / this.player.duration$.value) * 100}%`;
    }

    if (!this.player.isAdVideoPlaying()) {
      const time = this.player.seekTime$.value ?? this.player.currentTime$.value;
      if (
        this.player.isPlaying$.value == true &&
        time >= this.player.duration$.value - 10 &&
        time < this.player.duration$.value
      ) {
        if (this.ItemVideoViewBox) {
          this.ItemVideoViewBox.classList.add("active");
          const percentage = Math.abs(time - this.player.duration$.value) / 10;
          const progressBarPercent = document.getElementById("NextContentProgressBarPercent");
          if (progressBarPercent) progressBarPercent.style.width = `${Math.abs(percentage * 100 - 100)}%`;
        }
      } else {
        if (this.ItemVideoViewBox) {
          this.ItemVideoViewBox.classList.remove("active");
        }
      }
    }
  };

  onRelease = () => {
    window.clearInterval(this.hitsTimer);
    this.onCurrentTimeUnregister();
    this.onDurationUnregister();
    this.progressSeekTimeUnregister();
  };

  onNav = (key: Keys): boolean => {
    switch (key) {
      /*case Keys.select:
        this.player.seek();
        return true;*/

      /*case Keys.down:
        this.player.play();
        return false; // Must handle seek reset and remove focus from scrubber*/

      case Keys.left:
      case Keys.right:
        this._launchSeek(key);
        return true;

      case Keys.play:
        this.player.sendEStat(PlayerState.PLAYING);
        this.player.play();
        return true;

      case Keys.pause:
        this.player.sendEStat(PlayerState.PAUSED);
        this.player.pause();
        return true;

      case Keys.playPause:
        this.player.sendEStat(
          this.player.state$.value === PlayerState.PLAYING ? PlayerState.PAUSED : PlayerState.PLAYING
        );
        this.player.playPause();
        return true;

      /*case Keys.back:
        if (this.player.state$.value != PlayerState.SEEKING) return false;
        this.player.play();
        return true;*/

      default:
        return false;
    }
  };

  onMouseDown = (point: Point) => {
    const controlRect = screenRectOf(this.timelineDOM);
    if (controlRect) {
      const widhPercentage = (point.x - controlRect.origin.x) / controlRect.size.width;

      this._seek(() => {
        this.player.sendEStat(PlayerState.PAUSED);
        this.player.pause();
        let position = this.player.duration$.value * widhPercentage;
        // FIX from SAMSUNG : try not to jump to last possible second and limit it to jumping to video.duration-5
        if (platform.type === PlatformType.tizen && position > this._getPlayerPosition()) {
          if (this._getPlayerPosition() <= this.player.duration() - 5) {
            position = Math.min(position, this.player.duration() - 5);
          } else {
            // if the current postion is between duration-5 and duration, we don't jump because it will cause a rewind instead of forward
            position = this._getPlayerPosition();
          }
        }
        // END FIX
        this.player.setSeekTime(position);
        this.player.sendEStat(PlayerState.PLAYING);
        this.player.play();
      });
    }
    return true;
  };
}
