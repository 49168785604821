import "./channelTab.scss";

import { Channel } from "~models/channel";
import { ItemCollection } from "~models/itemCollection";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { MEASwimlane } from "~swimlaneViews/meaSwimlane";
import { PlaylistMixedSwimlane } from "~swimlaneViews/playlistMixedSwimlane";
import { PlaylistProgramSwimlane } from "~swimlaneViews/playlistProgramSwimlane";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";

import { parseMarkerPianoPageDisplay } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { Region } from "../../models/region";
import { sendOrangePageViewEvent } from "../../tools/analytics/orangeStats";
import { sendClickPianoEventOnSelectTile, sendPianoAnalytic } from "../../tools/analytics/piano";
import { getOrangeProviderIdFromBroadcastChannel } from "../../tools/apiOrange/channelOrangeProviderIdMap";
import { ChannelSwimlane } from "../../views/swimlanes/channelSwimlane";
import { LoaderStaticPageHelper } from "../loaderStatic/loaderStaticPage";

export class ChannelTab extends View {
  listComponent?: IListComponent<ItemCollection>;
  private _scrollIndexUnregister?: () => void;
  private _source: Channel | Region;
  private _background?: DynamicBackground;
  private readonly _position: number | undefined;

  /**
   * List of allowed slider types on ChannelTab
   */
  private readonly _validSwimlaneTypes = [
    "mise_en_avant",
    "playlist_video",
    "playlist_program",
    "playlist_mixed",
    "regions",
  ];

  constructor(source: Channel | Region, position?: number) {
    super(DOMHelper.createDivWithParent(null, "ChannelTab"));
    this._position = position;

    // Show loader and start anim
    LoaderStaticPageHelper.display();

    /**
     * `Live` slider should only be displayed on Region tab.
     */
    if (source.type === "region") {
      this._validSwimlaneTypes.push("live");
    }

    this._source = source;
    this._fetchSource();
  }

  onShown() {
    const markerPiano = parseMarkerPianoPageDisplay(this._source.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
    } else {
      Log.analytics.error("Failed to send piano analytics");
    }
    sendOrangePageViewEvent({
      pTitle: this._source.title,
      providerId: getOrangeProviderIdFromBroadcastChannel(this._source),
    });
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDetailed(this._source)
      .subscribe(
        value => {
          sendClickPianoEventOnSelectTile(this._source, value[0], this._position);

          // Here use it to create the UI
          Log.api.log("[DETAILED CHANNEL] next !", value);
          this._source = value[0];
          this._onSourceReady(value[0].items);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.error("[DETAILED CHANNEL] Error !", error);
        },
        () => {}
      );
  }

  private _onSourceReady(source: ItemCollection[]) {
    const channelsWithStaticBackground = ["franceinfo", "la1ere"];
    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      staticSource: this._source.getBackgroundImgUrl(),
      staticRowTypes: ["regions"],
      isStatic: channelsWithStaticBackground.includes(this._source.extras.channel_url) ? true : false,
    });

    DOMHelper.createImg(
      this.rootElement,
      "ChannelLogo",
      this._source.type == "region" ? "region" : null,
      this._source.getLogoImgUrl(),
      ""
    );
    const validSwimlanes: ItemCollection[] = [];
    source.forEach(sl => {
      if (this._validSwimlaneTypes.includes(sl.type)) {
        validSwimlanes.push(sl);
      }
    });

    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, null, "listContainer"),
        modelSource: new StaticModelSource(validSwimlanes),
        viewFactory: model => {
          if (model.type == "mise_en_avant") {
            // if source is a region or Portail des Outre-Mers "la1ere" channel, the "mise_en_avant" swimlane is displayed as a "playlist_mixed" swimlane
            return this._source.type === "region" ||
              (this._source.type === "channel" && this._source.extras.channel_url === "la1ere")
              ? new PlaylistMixedSwimlane(model)
              : new MEASwimlane(model);
          } else if (model.type === "playlist_video") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type === "playlist_program") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type === "playlist_mixed") {
            return new PlaylistMixedSwimlane(model);
          } else if (model.type === "playlist_collection") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type === "live") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type === "regions") {
            return new ChannelSwimlane(model);
          } else {
            //default
            return new PlaylistMixedSwimlane(model);
          }
        },
        arrowFactory: verticalArrowFactory,
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
        mouseFocusInPageOnly: true,
      },
      mainList => {
        LoaderStaticPageHelper.hide();

        const defaultIndex = 0;
        mainList.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(mainList.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );
    this._background.setItemCollectionList(this.listComponent);

    this._scrollIndexUnregister = this.listComponent.scrollIndex$.didChange((newIndex, oldIndex) => {
      DOMHelper.addClass(this.listComponent?.viewFromIndex(newIndex)?.rootElement, "onTop");
      DOMHelper.removeClass(this.listComponent?.viewFromIndex(oldIndex)?.rootElement, "onTop");
      if (newIndex !== undefined) {
        this.listComponent?.setFocusOnIndex(newIndex);
      }
    });
  }

  onRelease = () => {
    this._background?.onRelease();
    this._scrollIndexUnregister?.();
    LoaderStaticPageHelper.hide();
  };
}
