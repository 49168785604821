import "./homeTab.scss";

import { CGU, CGUType } from "~models/cgu";
import { Channel } from "~models/channel";
import { ItemCollection } from "~models/itemCollection";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { CGUSwimlane } from "~swimlaneViews/cguSwimlane";
import { MEASwimlane } from "~swimlaneViews/meaSwimlane";
import { PlaylistMixedSwimlane } from "~swimlaneViews/playlistMixedSwimlane";
import { PlaylistProgramSwimlane } from "~swimlaneViews/playlistProgramSwimlane";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import { createListComponent, DOMHelper, IListComponent, Listenable, ListenableSource, View } from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";
import { ChannelSwimlane } from "~views/swimlanes/channelSwimlane";

import { Config } from "../../config";
import { fetchResumeSwimlane } from "../../datas/fetchResumeSwimlane";
import { Plugin } from "../../datas/plugin";
import { checkDeepLink } from "../../main";
import { BrowsableItem } from "../../models/browsableItem";
import { MEABanner } from "../../models/meaBanner";
import { sendOrangePageViewEvent } from "../../tools/analytics/orangeStats";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { DEFAULT_ORANGE_PROVIDER_ID } from "../../tools/apiOrange/orangeProviderId";
import { Didomi } from "../../tools/cmp/didomi";
import { MEABannerView } from "../../views/MEABannerView";

const _validSwimlaneTypes = [
  "mise_en_avant",
  "playlist_video",
  "playlist_program",
  "playlist_mixed",
  "playlist_collection",
  "live",
  "seeks",
  "cguSwimlane",
  "playlist_channel",
];

let deepLinkChecked = false;

export class HomeTab extends View {
  listComponent?: IListComponent<ItemCollection | MEABanner>;
  private _scrollIndexUnregister?: () => void;
  private _background?: DynamicBackground;
  private _sourceRecommendations?: ItemCollection[] = undefined;
  private _sourceBecauseYouWatched?: ItemCollection[] = undefined;
  private _sourceHome?: ItemCollection[] = undefined;
  private _sourceChannels?: ItemCollection = undefined;
  private _sourceResume?: ItemCollection[] = undefined;
  private _sourceCGU?: ItemCollection = undefined;
  private _source$?: Listenable<(ItemCollection | MEABanner)[]> = undefined;
  private _item?: BrowsableItem = undefined;
  private _resumeSwimlaneIndex?: number; // save the index where the resumeSwimlane should be insert when we refresh this swimlane on onShown
  private _sendPianoAnalytics?: () => void;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "ShowContent", "ShowContent"));

    this._fetchSources();
  }

  onShown() {
    if (!deepLinkChecked) {
      checkDeepLink();
      deepLinkChecked = true;
    }
    sendPianoAnalytic("page.display", { page: "accueil", page_type: "accueil" }, {});
    sendOrangePageViewEvent({
      pTitle: "accueil",
      providerId: DEFAULT_ORANGE_PROVIDER_ID,
    });

    if (this._source$) {
      this.refetchResumeSwimlane();
    }
  }

  private _fetchSources() {
    Plugin.getInstance()
      .fetchHome()
      .subscribe(
        value => {
          console.log("[HOME] Next !", value);
          this._sourceHome = value[0].items;
          this._item = value[0];
          this._onASourceReady();
        },
        error => {
          console.log("[HOME] Error !", error);
        },
        () => {
          console.log("[HOME] Complete !");
        }
      );

    Plugin.getInstance()
      .fetchChannels()
      .subscribe(
        value => {
          console.log("[CHANNELS] Next !", value);
          const validChannels = [
            "france-2",
            "france-3",
            "france-4",
            "france-5",
            "slash",
            "la1ere",
            "franceinfo",
            "paris-h24",
          ];
          this._sourceChannels = new ItemCollection(
            "channels",
            "playlist_channel",
            "Chaînes",
            null,
            [],
            [],
            null,
            false
          );
          value.forEach((element: Channel) => {
            if (validChannels.includes(element.extras.channel_url)) {
              element.itemCollection = this._sourceChannels;
              this._sourceChannels?.items.push(element);
            }
          });

          this._onASourceReady();
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[CHANNELS] Error !", error);
        },
        () => {
          console.log("[CHANNELS] Complete !");
        }
      );

    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      void fetchResumeSwimlane()
        .then(value => {
          Log.app.log("[RESUME] then ", value);
          this._sourceResume = value;
          this._onASourceReady();
        })
        .catch(error => {
          Log.app.log("[RESUME] catch error !", error);
        });

      if (Didomi.isVendorAllowedToTrack("spideo-TcYnKH8L")) {
        Plugin.getInstance()
          .fetchRecommendations(user)
          .subscribe(
            value => {
              Log.app.log("[RECO FOR YOU] Next !", value);
              this._sourceRecommendations = value;
              this._onASourceReady();
            },
            error => {
              // Here use it to trigger and display an error
              Log.app.log("[RECO FOR YOU] Error !", error);
            }
          );
        Plugin.getInstance()
          .fetchBecauseYouWatched(user)
          .subscribe(
            value => {
              Log.app.log("[BECAUSE YOU WATCHED] Next !", value);
              if (value[0] && value[0].items && value[0].items.length && value[0].items.length < 5) {
                this._sourceBecauseYouWatched = [];
              } else {
                this._sourceBecauseYouWatched = value;
              }
              this._onASourceReady();
            },
            error => {
              // Here use it to trigger and display an error
              Log.app.log("[BECAUSE YOU WATCHED] Error !", error);
            }
          );
      } else {
        this._sourceRecommendations = [];
        this._sourceBecauseYouWatched = [];
        this._onASourceReady();
      }
    } else {
      this._sourceResume = [];
      this._sourceRecommendations = [];
      this._sourceBecauseYouWatched = [];
      this._onASourceReady();
    }
  }

  private _onASourceReady() {
    if (
      !this._sourceChannels ||
      !this._sourceHome ||
      !this._sourceResume ||
      !this._sourceRecommendations ||
      !this._sourceBecauseYouWatched
    ) {
      return;
    }

    const source: (ItemCollection | MEABanner)[] = [];

    // adding MEA first
    let firstSwimlanePushedInSourceAsMEAorMEABanner = false;
    if (this._sourceHome[0]?.type === "mise_en_avant" && this._sourceHome[0].items.length) {
      const meaImmersiveItem = this._sourceHome[0].items[0];
      firstSwimlanePushedInSourceAsMEAorMEABanner = true;
      //Add key to identify MEA Banner origin when calling analytic functions
      meaImmersiveItem.extras = meaImmersiveItem.extras ?? {};
      meaImmersiveItem.extras.isMeaImmersive = true;

      source.push(new MEABanner(meaImmersiveItem));
      this._sourceHome[0].items.shift();
      if (this._sourceHome[0].items.length > 0) {
        source.push(this._sourceHome[0]);
      }
    }
    // adding channels
    source.push(this._sourceChannels);
    // adding resume content if not empty
    // we need to save the index where the resumeSwimlane should be insert when we refresh this swimlane on onShown
    this._resumeSwimlaneIndex = source.length;
    if (this._sourceResume.length && this._sourceResume[0].items && this._sourceResume[0].items.length) {
      source.push(this._sourceResume[0]);
    }
    // adding recommendation playlists
    if (
      this._sourceRecommendations.length &&
      this._sourceRecommendations[0].items &&
      this._sourceRecommendations[0].items.length
    ) {
      source.push(this._sourceRecommendations[0]);
    }
    if (
      this._sourceBecauseYouWatched.length &&
      this._sourceBecauseYouWatched[0].items &&
      this._sourceBecauseYouWatched[0].items.length
    ) {
      source.push(this._sourceBecauseYouWatched[0]);
    }

    // adding others home's playlists
    for (let ic = firstSwimlanePushedInSourceAsMEAorMEABanner ? 1 : 0; ic < this._sourceHome.length; ic++) {
      /**
       * New API structure is returning unhandled link structure
       * We were previously appending those special row by hand dynamically
       * Since we are not handling link row yet from API, thoses rows
       * are currently being filtered.
       * Some other swimlane types are also not handled like "categories_home"
       * So we filter only swimlane that the app handled in _validSwimlaneTypes
       */
      const currentSwimlane = this._sourceHome[ic];
      if (currentSwimlane !== undefined) {
        if (_validSwimlaneTypes.includes(currentSwimlane.type)) {
          source.push(currentSwimlane);
        } else {
          Log.app.warn("model", currentSwimlane.type, "not handled yet!");
        }
      }
    }
    // adding CGU and legals here
    const itemsCGU: CGU[] = Config.enabledFeatures.account
      ? [new CGU("mentionsLegales", CGUType.mentionsLegales, "Mentions légales"), new CGU("cgu", CGUType.cgu, "CGU")]
      : [new CGU("mentionsLegales", CGUType.mentionsLegales, "Mentions légales")];
    this._sourceCGU = new ItemCollection(
      "cguAndLegals",
      "cguSwimlane",
      Config.enabledFeatures.account ? "Mentions légales & cgu" : "Mentions légales",
      null,
      itemsCGU,
      [],
      null,
      false
    );
    this._sourceCGU.items.forEach((item: CGU) => {
      item.itemCollection = this._sourceCGU;
    });
    source.push(this._sourceCGU);

    this._source$ = new Listenable(source);

    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      staticSource: this._item?.getBackgroundImgUrl(),
    });
    const contentRoot = DOMHelper.createDivWithParent(this.rootElement, null, "contentHomeTabSwimlane");

    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: contentRoot,
        modelSource: new ListenableSource(this._source$, true),
        viewFactory: model => {
          if (model instanceof MEABanner) {
            return new MEABannerView(model.item);
          } else if (model.type == "mise_en_avant") {
            return new MEASwimlane(model);
          } else if (model.type == "playlist_video") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "playlist_program") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "playlist_mixed") {
            return new PlaylistMixedSwimlane(model);
          } else if (model.type == "playlist_collection") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "live") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "seeks") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "cguSwimlane") {
            return new CGUSwimlane(model);
          } else if (model.type == "playlist_channel") {
            return new ChannelSwimlane(model);
          } else if (model.type == "recommendations") {
            return new PlaylistMixedSwimlane(model);
          } else {
            //default
            Log.app.warn("model", model.type, "not handled yet!");
            return new PlaylistMixedSwimlane(model);
          }
        },
        arrowFactory: verticalArrowFactory,
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
        mouseFocusInPageOnly: true,
      },
      mainList => {
        const defaultIndex = 0;
        mainList.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(mainList.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );
    this._background.setItemCollectionList(this.listComponent);

    this._scrollIndexUnregister = this.listComponent.scrollIndex$.didChange((newIndex, oldIndex) => {
      DOMHelper.addClass(this.listComponent?.viewFromIndex(newIndex)?.rootElement, "onTop");
      DOMHelper.removeClass(this.listComponent?.viewFromIndex(oldIndex)?.rootElement, "onTop");
      if (newIndex !== undefined) {
        this.listComponent?.setFocusOnIndex(newIndex);
      }
    });

    this._sendPianoAnalytics = this.listComponent.focusedView$.didChange(newView => {
      if (newView instanceof MEABannerView) {
        const item = newView.getItem();
        sendPianoAnalytic(
          "publisher.impression",
          {},
          {
            feature: "bloc_" + newView.rootElement.innerText,
            zone: "mea_immersive",
            content_type: item.type,
            content_title: item.title,
          }
        );
      } else if (newView instanceof MEASwimlane) {
        sendPianoAnalytic("publisher.impression", {}, { feature: "bloc_mea_3X4", zone: "mea" });
      }
    });
  }

  onRelease = () => {
    this._background?.onRelease();
    this._scrollIndexUnregister?.();
  };

  refetchResumeSwimlane = () => {
    const user = Plugin.getInstance().user;
    if (this._source$ && user.isActive()) {
      void fetchResumeSwimlane()
        .then(value => {
          Log.app.log("[RESUME SWIMLANE] Next !", value);
          if (this._resumeSwimlaneIndex === undefined) {
            Log.app.log("[REFETCH RESUME SWIMLANE] _resumeSwimlaneIndex is undefined. Should not happen");
            return;
          }
          if (this._source$) {
            // adding MEAs and Channels
            const newSource = [...this._source$.value.slice(0, this._resumeSwimlaneIndex)];

            // adding resume swimlane if not empty
            if (value?.length && value[0].items && value[0].items.length) {
              newSource.push(value[0]);
            }

            // adding the other swimlanes
            const sourceResumeIsNotEmpty =
              !!this._sourceResume?.length && !!this._sourceResume[0].items && !!this._sourceResume[0].items.length;
            if (sourceResumeIsNotEmpty) {
              newSource.push(...this._source$.value.slice(this._resumeSwimlaneIndex + 1));
            } else {
              newSource.push(...this._source$.value.slice(this._resumeSwimlaneIndex));
            }

            // update listenable swimlane source
            const resumeSwimlaneIsFocused =
              sourceResumeIsNotEmpty && this.listComponent?.focusedIndex$.value == this._resumeSwimlaneIndex;
            this._sourceResume = value;
            this._source$.value = newSource;

            // restore focus on resume swimlane if it was previously focused
            if (resumeSwimlaneIsFocused) {
              this.listComponent?.onContentReady().then(() => {
                if (this._resumeSwimlaneIndex === undefined) {
                  Log.app.log("[REFETCH RESUME SWIMLANE] _resumeSwimlaneIndex is undefined. Should not happen");
                  return;
                }
                this.listComponent?.setFocusOnIndex(this._resumeSwimlaneIndex);
              });
            }
          }
        })
        .catch(error => {
          // Here use it to trigger and display an error
          Log.app.log("[RESUME SWIMLANE] Error !", error);
        });
    }
  };
}
