import "./popupErrorAuth.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";

import { navigationStack } from "../../main";

interface Button {
  label: string;
  action?: () => void;
}

class PopupButton extends View {
  constructor(button: Button) {
    super(DOMHelper.createDivWithParent(null, null, "popupContainerButton", null));
    DOMHelper.createDivWithParent(this.rootElement, null, "popupContainerButtonText", button.label);
  }
}

export class PopupErrorAuth extends View {
  private _containerElement: HTMLElement;
  private _listButtonsElement: HTMLElement;

  constructor(params: { button1?: Button; button2?: Button; button3?: Button }) {
    super(DOMHelper.createDivWithParent(null, "PopupOverlay"));
    this._containerElement = DOMHelper.createDivWithParent(this.rootElement, null, "popupContainer");
    this._listButtonsElement = DOMHelper.createDivWithParent(
      this._containerElement,
      null,
      "popupContainerButtonContainer"
    );
    const { button1, button2, button3 } = params;

    const buttons = [];
    button1 && buttons.push(button1);
    button2 && buttons.push(button2);
    button3 && buttons.push(button3);
    DOMHelper.createDivWithParent(
      this._containerElement,
      null,
      "popupContainerText",
      "L'application n'est pas disponible actuellement"
    );
    this.delegate = createListComponent(
      {
        rootElement: this._listButtonsElement,
        modelSource: new StaticModelSource(buttons.map((button, index) => ({ ...button, id: index }))),
        viewFactory: button => {
          return new PopupButton(button);
        },
        onSelect: button => {
          navigationStack.removePage(this);
          button.action?.();
          return true;
        },
        horizontal: true,
        noTransform: true,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }
}
