import { Storage } from "~libs/storage";

import { PlatformType } from "../../bud-lite-tv/src/lib";
import { UserData } from "../tools/apiOrange/userData";
import { Didomi } from "../tools/cmp/didomi";
import { UserInfo } from "./userInfo";

export const USER_ID_STORAGE_KEY = "userId" as const;

import base64url from "base64-url";

export interface ILoggedInUser {
  id: string;
  infos: UserInfo;
  datas: UserData;
}

export class User implements Partial<ILoggedInUser> {
  id?: string;
  infos?: UserInfo;
  datas?: UserData;

  constructor() {}

  connect(userId: string, userInfo: UserInfo) {
    Storage.setItem(USER_ID_STORAGE_KEY, userId);
    this.id = userId;
    this.infos = userInfo;

    /**
     * User id & infos are filed
     * We want to update newly logged in user consents
     * with current user consents (identified by its device id)
     */
    void Didomi.sendCurrentUserConsents();
  }

  connectOrange(userData: UserData): User {
    this.id = base64url.encode(userData.userId);
    Storage.setItem(USER_ID_STORAGE_KEY, this.id);
    this.datas = userData;

    return this;
  }

  deconnect() {
    Storage.removeItem(USER_ID_STORAGE_KEY);
    this.id = undefined;
    this.infos = undefined;

    /**
     * User id & infos are undefined
     * We want to update anonymous user consents
     * with logged-in user consents (identified by its user id)
     */
    void Didomi.sendCurrentUserConsents();
  }

  isActive(): this is ILoggedInUser {
    const infos = PlatformType.orange ? this.datas : this.infos;
    return this.id !== undefined && infos !== undefined;
  }
}
