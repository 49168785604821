import "./programTab.scss";

import { parseMarkerPianoPageDisplay } from "~datas/parser";
import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { Plugin } from "~pages/../datas/plugin";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import { sendClickPianoEventOnSelectTile, sendPianoAnalytic } from "~tools/analytics/piano";
import { createListComponent, DOMHelper, IDelegate, IListComponent, StaticModelSource, View } from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";

import { sendOrangePageViewEvent } from "../../tools/analytics/orangeStats";
import { getOrangeProviderIdFromBroadcastChannel } from "../../tools/apiOrange/channelOrangeProviderIdMap";
import { FavoritesTab } from "../favorites/favoritesTab";
import { LoaderStaticPageHelper } from "../loaderStatic/loaderStaticPage";
import { ProgramDesc } from "./programDesc";

export class ProgramTab extends View {
  private _source: Program;
  private _programDescView: ProgramDesc | undefined;
  private readonly _programPath: string;
  listComponent?: IListComponent<Program | ItemCollection> & IDelegate;
  private _scrollIndexUnregister?: () => void;
  favoritesTab?: FavoritesTab;
  /**
   * Source has been fetched.
   * Meaning that source data is not partial.
   */
  private _isSourceReady = false;
  private readonly _position: number | undefined;

  constructor(source: Program, favoritesTab?: FavoritesTab, position?: number) {
    super(DOMHelper.createDivWithParent(null, "ProgramTab"));
    this._source = source;
    this._programPath = this._source.extras?.program_path;
    this.favoritesTab = favoritesTab;
    this._position = position;

    // Show loader and start anim
    LoaderStaticPageHelper.display();

    this._fetchSource();
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDetailed(this._source)
      .subscribe(
        value => {
          sendClickPianoEventOnSelectTile(this._source, value[0], this._position);

          // Here use it to create the UI
          Log.api.log("[DETAILED PROGRAM] Next !", value);
          this._source = value[0];
          const items = value[0].items ? value[0].items : [];
          this._onSourceReady(items);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.error("[DETAILED PROGRAM] Error !", error);
        },
        () => {}
      );
  }

  private _onSourceReady = (items: ItemCollection[]) => {
    new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      source: this._source.getBackgroundImgUrl(),
    });
    this._isSourceReady = true;
    this._sendAnalyticsData();

    const contentRoot = DOMHelper.createDivWithParent(this.rootElement, null, "contentHomeTabSwimlane");
    const hasItems = items.length > 0;
    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(contentRoot, "VerticalSwimlaneComponent", "list"),
        modelSource: new StaticModelSource([this._source, ...items]),
        horizontal: false,
        pageSize: 1,
        visibleAfter: 1,
        spatialFocus: true,
        viewFactory: model => {
          Log.app.log("[DETAILED PROGRAM] model", model);
          if (model instanceof Program) {
            return (this._programDescView = new ProgramDesc(model as Program, this._programPath, hasItems));
          }
          if (model instanceof ItemCollection) {
            if (model.type === "program") {
              return (this._programDescView = new ProgramDesc(model as Program, this._programPath, hasItems));
            }
            return new PlaylistVideoSwimlane(model);
          }
          return (this._programDescView = new ProgramDesc(this._source, this._programPath, hasItems));
        },
        arrowFactory: verticalArrowFactory,
        mouseFocusInPageOnly: true,
      },
      list => {
        LoaderStaticPageHelper.hide();

        const defaultIndex = 0;
        list.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(this.listComponent?.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );

    this._scrollIndexUnregister = this.listComponent.scrollIndex$.didChange((newIndex, oldIndex) => {
      DOMHelper.addClass(this.listComponent?.viewFromIndex(newIndex)?.rootElement, "onTop");
      DOMHelper.removeClass(this.listComponent?.viewFromIndex(oldIndex)?.rootElement, "onTop");
      if (newIndex !== undefined) {
        this.listComponent?.setFocusOnIndex(newIndex);
      }
    });
  };

  private _sendAnalyticsData = () => {
    const markerPiano = parseMarkerPianoPageDisplay(this._source.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
    } else {
      Log.analytics.error("Failed to send piano analytics");
    }
    sendOrangePageViewEvent({
      pTitle: this._source.title,
      providerId: getOrangeProviderIdFromBroadcastChannel(this._source),
    });
  };

  onShown() {
    if (this._isSourceReady) {
      this._sendAnalyticsData();
    }
    this._programDescView?.fetchPlayButton();
  }

  onRelease = () => {
    this._scrollIndexUnregister?.();
    LoaderStaticPageHelper.hide();
  };
}
