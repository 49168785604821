import "./playerAudioSubtitles.scss";

import {
  createListComponent,
  DOMHelper,
  IListComponent,
  IPage,
  Keys,
  StaticModelSource,
  View,
} from "../../../bud-lite-tv/src/lib";
import { IPlayerTracks, Player, PlayerState, Track } from "../../libs/player";
import { navigationStack } from "../../main";

enum TracksViewType {
  SUBTITLES = "SUBTITLES",
  AUDIO = "AUDIO",
}

const trackLabelValues: Map<string, string> = new Map();

trackLabelValues.set("fr", "français");
trackLabelValues.set("fra", "français");
trackLabelValues.set("fre", "français");
trackLabelValues.set("qad", "français (AD)");
trackLabelValues.set("qaa", "version originale");
trackLabelValues.set("en", "anglais");
trackLabelValues.set("eng", "anglais");
trackLabelValues.set("qsm", "français (SME)");

const getLabelFromLanguage = (language: string): string => {
  return trackLabelValues.get(language) ?? "";
};

class TrackChoiceView extends View {
  constructor(buttonParams: { selected: boolean; label: string }) {
    super(DOMHelper.createDivWithParent(null, null, "trackChoice"));

    DOMHelper.createDivWithParent(this.rootElement, null, "trackChoiceBullet");

    this.selected(buttonParams.selected);

    DOMHelper.createTextWithParent(this.rootElement, null, "trackChoiceLabel", buttonParams.label);
  }

  selected = (selected: boolean) => {
    if (selected) {
      this.rootElement.classList.add("selected");
    } else {
      this.rootElement.classList.remove("selected");
    }
  };
}

class TrackSelectorView extends View {
  private _list: IListComponent;
  private _tracks: (
    | Track
    | { id: "subtitle_disable_button"; type: "subtitle_disable_button"; label: "Désactivé"; enabled: boolean }
  )[] = [];
  private _currentSelectedView?: TrackChoiceView;

  constructor(player: Player, tracksSelectorType: TracksViewType, tracks: Track[]) {
    super(DOMHelper.createDivWithParent(null, null, "textTrackSelector"));

    this._tracks = tracks;
    // Create a fake track to include the disable button for the model source
    if (tracksSelectorType === TracksViewType.SUBTITLES) {
      const aSubIsEnabled = this._tracks.some(track => {
        return track.enabled;
      });
      this._tracks.push({
        id: "subtitle_disable_button",
        type: "subtitle_disable_button",
        enabled: !aSubIsEnabled,
        label: "Désactivé",
      });
    }
    DOMHelper.createTextWithParent(
      this.rootElement,
      null,
      "textTrackSelectorTitle",
      tracksSelectorType === TracksViewType.AUDIO ? "audio" : "sous-titres"
    );

    this._list = this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(this._tracks),
      viewFactory: (item, _) => {
        const view = new TrackChoiceView({
          selected: item.enabled,
          label: item.type === "subtitle_disable_button" ? item.label : getLabelFromLanguage(item.language),
        });
        if (item.enabled) {
          this._currentSelectedView = view;
        }
        return view;
      },
      onSelect: (model, index) => {
        this._currentSelectedView?.selected(false);

        if (tracksSelectorType === TracksViewType.SUBTITLES) {
          player.hideAllSubtitles();
        }

        const selectedView = this._list.viewFromIndex(index);
        this._currentSelectedView = selectedView instanceof TrackChoiceView ? selectedView : undefined;
        this._currentSelectedView?.selected(true);

        if (model.type === "subtitle") {
          player.setSubtitle(model.index);
        } else if (model.type === "audio") {
          player.setAudio(model.index);
        } else if (model.type === "subtitle_disable_button") {
          // allready done with player.hideAllSubtitles();
        }

        return true;
      },
      fitToChildren: true,
      pageSize: this._tracks.length,
      horizontal: false,
    });
  }
}

export class PlayerAudioSubtitlesView extends View implements IPage {
  private _list?: IListComponent;

  private _categoriesToDisplay: TracksViewType[] = [];
  private _lastPlayerState?: PlayerState;
  private _player: Player;

  private _tracks: IPlayerTracks = {
    audios: [],
    subtitles: [],
  };

  constructor(player: Player) {
    super(DOMHelper.createDivWithParent(null, null, "playerAudioSubtitles"));
    // const data = webapis.avplay.getTotalTrackInfo;

    this._player = player;

    this._lastPlayerState = this._player.state$.value;
    if (this._player.state$.value == PlayerState.PLAYING) {
      player.pause();
    }

    this._tracks = player.getAudiosAndSubtitles();

    if (this._tracks.audios.length > 1) {
      this._categoriesToDisplay.push(TracksViewType.AUDIO);
    }
    if (this._tracks.subtitles.length > 0) {
      this._categoriesToDisplay.push(TracksViewType.SUBTITLES);
    }

    this._list = this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        horizontal: false,
        modelSource: new StaticModelSource(this._categoriesToDisplay),
        pageSize: 2,
        viewFactory: model => {
          return new TrackSelectorView(
            player,
            model,
            model === TracksViewType.SUBTITLES ? this._tracks.subtitles : this._tracks.audios
          );
        },
      },
      () => {
        this._list?.setFocusOnIndex(0);
      }
    );
  }

  onNav = (key: Keys) => {
    switch (key) {
      case Keys.back:
        navigationStack.removePage(this);
        if (this._lastPlayerState && this._lastPlayerState == PlayerState.PLAYING) {
          this._player.play();
        }
        return true;
      default:
        return false;
    }
  };
}
