import { z } from "zod";

export const Consent = z.object({
  enabled: z.boolean(),
  id: z.string(),
  userId: z.string(),
  partnerUserId: z.string().optional(),
});

export type Consent = z.infer<typeof Consent>;
