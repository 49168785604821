import { Plugin } from "../../datas/plugin";
import { OrangeDeviceInfo } from "../apiOrange/orangeDeviceInfo";
import { DEFAULT_ORANGE_PROVIDER_ID, OrangeProviderId } from "../apiOrange/orangeProviderId";

interface IOrangeStatsCommonParams {
  v: string;
  ts: string;
  partnerStatID: string;
  providerId: OrangeProviderId;
  clientType: string;
}
interface IOrangeStatsEventParams {
  category: string;
  label?: string;
  contentID?: string;
  contentLabel?: string;
  contentSubLabel?: string;
  eventType: "click" | "exit" | "play" | "stop";
  pTitle?: string;
}

interface IOrangeStatsPageViewParams {
  stbType: string;
  stbFirmware: string;
  accessType: string;
  pSubtitle?: string;
  eventType: "pageview";
  pTitle: string;
}

interface IOrangeStatsPageViewParamsFinal extends IOrangeStatsCommonParams, IOrangeStatsPageViewParams {}
interface IOrangeStatsEventParamsFinal extends IOrangeStatsCommonParams, IOrangeStatsEventParams {}

interface IOrangeRecosCommonParams {
  v: string;
  ts: string;
  partnerRecoID: string;
  stbType: string;
}

interface IOrangeRecosEventParams {
  contentID: string;
  contentLabel: string;
  contentSubLabel: string;
  eventType: "play" | "stop";
  providerId: OrangeProviderId;
}
interface IOrangeRecosEventParamsFinal extends IOrangeRecosCommonParams, IOrangeRecosEventParams {}

const appURL = new URLSearchParams(window.location.search);

const _stbType = OrangeDeviceInfo.modelName ?? "";
const _stbFirmware = OrangeDeviceInfo.version ?? "";
const _accessType = appURL.get("env");

const _configStats = {
  prod: "https://pdata.orange.fr/replay-ftv-stats/_pdb.gif?",
  pre: "https://pdata.staging.orange.fr/replay-ftv-stats/_pdb.gif?",
} as const;

const _configReco = {
  prod: "https://pdata.orange.fr/replay-ftv-reco/_pdb.gif?",
  pre: "https://pdata.staging.orange.fr/replay-ftv-reco/_pdb.gif?",
} as const;

const _backendTarget: "prod" | "pre" = (() => {
  if (__BACKEND_TARGET__ === "prod" || __BACKEND_TARGET__ === "proxy") {
    return "prod";
  }
  if (__BACKEND_TARGET__ === "pre") {
    return "pre";
  }

  // default
  return "pre";
})();

const _targetUrls = {
  stats: _configStats[_backendTarget],
  reco: _configReco[_backendTarget],
} as const;

const httpStatusCodeLogMessages: Map<number, string> = new Map();
httpStatusCodeLogMessages.set(200, "Success");
httpStatusCodeLogMessages.set(301, "Permanent redirection");
httpStatusCodeLogMessages.set(302, "Temporary redirection");
httpStatusCodeLogMessages.set(304, "Not modified");
httpStatusCodeLogMessages.set(403, "Access denied");
httpStatusCodeLogMessages.set(404, "Page not found");
httpStatusCodeLogMessages.set(500, "Server error");
httpStatusCodeLogMessages.set(503, "Server error");
httpStatusCodeLogMessages.set(504, "Server not responding");

const sendOrangeAnalytic = (
  target: "reco" | "stats",
  finalParams: IOrangeStatsEventParamsFinal | IOrangeStatsPageViewParamsFinal | IOrangeRecosEventParamsFinal
): void => {
  const parametersList = [];
  for (const property in finalParams) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = ["partnerStatID", "partnerRecoID"].includes(property)
      ? (finalParams as any)[property]
      : encodeURIComponent((finalParams as any)[property]);
    parametersList.push(encodedKey + "=" + encodedValue);
  }

  const parametersListString = parametersList.join("&");

  fetch(_targetUrls[target] + parametersListString, {
    method: "GET",
  })
    .then(response => {
      const log = `[OrangeStats] sendOrangeAnalytic ${response.status} ${
        httpStatusCodeLogMessages.get(response.status) ?? ""
      }`;
      [200, 301, 302, 304].includes(response.status) ? Log.api.log(log) : Log.api.error(log);
    })
    .catch(err => {
      Log.api.error("[OrangeStats] " + err);
    });
};

const getLocalParams = (): { v: string; ts: string; partnerStatID: string; clientType: string } | undefined => {
  const userConsents = Plugin.getInstance().user.datas?.consents;
  const statsConsent = userConsents?.find(item => item.id === "STATS");

  return statsConsent?.enabled
    ? {
        v: "1.0",
        ts: Date.now() + "",
        partnerStatID: statsConsent.userId,
        clientType: "basic",
      }
    : undefined;
};

const getLocalRecoParams = (): IOrangeRecosCommonParams | undefined => {
  const userConsents = Plugin.getInstance().user.datas?.consents;
  const recoConsent = userConsents?.find(item => item.id === "RECOS");

  return recoConsent?.enabled
    ? {
        v: "1.0",
        ts: Date.now() + "",
        partnerRecoID: recoConsent.userId,
        stbType: _stbType,
      }
    : undefined;
};

let _currentPageviewPTitle: string | undefined = undefined;

export const sendOrangePageViewEvent = (
  expectedParams: Pick<IOrangeStatsPageViewParamsFinal, "pSubtitle" | "providerId" | "pTitle">
) => {
  _currentPageviewPTitle = expectedParams.pTitle;

  const localParams = getLocalParams();
  if (localParams === undefined) {
    return;
  }

  const finalParams: IOrangeStatsPageViewParamsFinal = {
    ...expectedParams,
    ...localParams,
    eventType: "pageview",
    stbType: _stbType,
    stbFirmware: _stbFirmware,
    accessType: _accessType ?? "",
  };
  sendOrangeAnalytic("stats", finalParams);
};

export const sendOrangeEvent = (
  expectedParams: Pick<
    IOrangeStatsEventParamsFinal,
    "category" | "contentID" | "contentLabel" | "contentSubLabel" | "eventType" | "label" | "providerId"
  >
) => {
  const localParams = getLocalParams();
  if (localParams === undefined) {
    return;
  }

  const finalParams: IOrangeStatsEventParamsFinal = {
    pTitle: _currentPageviewPTitle,
    ...expectedParams,
    ...localParams,
  };

  sendOrangeAnalytic("stats", finalParams);
};

export const sendOrangeClickMenuEvent = (expectedParams: Pick<IOrangeStatsEventParamsFinal, "label">) => {
  const localParams = getLocalParams();
  if (localParams === undefined) {
    return;
  }

  const finalParams: IOrangeStatsEventParamsFinal = {
    eventType: "click",
    category: "menu",
    pTitle: _currentPageviewPTitle,
    providerId: DEFAULT_ORANGE_PROVIDER_ID,
    ...expectedParams,
    ...localParams,
  };

  sendOrangeAnalytic("stats", finalParams);
};

export const sendOrangeRecoEvent = (expectedParams: IOrangeRecosEventParams) => {
  const localParams = getLocalRecoParams();
  if (localParams === undefined || !expectedParams.contentID) {
    return;
  }

  const finalParams: IOrangeRecosEventParamsFinal = {
    ...expectedParams,
    ...localParams,
  };

  sendOrangeAnalytic("reco", finalParams);
};
