import { Extrait } from "../../models/extrait";
import { Flux } from "../../models/flux";
import { Integrale } from "../../models/integrale";
import { PlayableItem } from "../../models/playableItem";
import { Unit } from "../../models/unit";
import { Tile } from "../../pages/rootPage";
import { DEFAULT_ORANGE_PROVIDER_ID, OrangeProviderId } from "./orangeProviderId";

const _channelOrangeProviderIdMap = new Map<string, OrangeProviderId>([
  ["france-2", "FR2RNG"],
  ["france-3", "FR3RNG"],
  ["france-4", "FR4RNG"],
  ["france-5", "FR5RNG"],
  ["franceinfo", "FRINFO"],
  ["la1ere", "FRORNG"],
  ["slash", "SLASHRNG"],
  // ["okoo", "OKOORNG"], // broadcast channel === france-4 && category === enfants
  // ["regions", "REGIONSRNG"], // broadcast channel startwith "france-3-" ??
]);

export const getOrangeProviderIdFromBroadcastChannel = (item: Tile | PlayableItem): OrangeProviderId => {
  // TODO: règles de gestion à vérifier

  let broadCastChannel: unknown = undefined;
  if (item instanceof Integrale || item instanceof Unit || item instanceof Extrait || item instanceof Flux) {
    broadCastChannel = item?.media?.broadcast?.extras?.broadcast_channel;
  } else {
    broadCastChannel = item?.extras.channel ?? item?.extras.channel_path ?? item?.extras?.channel?.extras?.channel_path;
  }

  if (broadCastChannel === undefined) {
    return DEFAULT_ORANGE_PROVIDER_ID;
  }
  if (typeof broadCastChannel === "string" && broadCastChannel.startsWith("france-3-")) {
    return "REGIONSRNG";
  }
  const category = item?.extras?.category?.extras?.url_complete;
  if (broadCastChannel === "france-4" && category === "enfants") {
    return "OKOORNG";
  }
  return typeof broadCastChannel === "string"
    ? _channelOrangeProviderIdMap.get(broadCastChannel) ?? DEFAULT_ORANGE_PROVIDER_ID
    : DEFAULT_ORANGE_PROVIDER_ID;
};
