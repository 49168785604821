import { z } from "zod";

import { Consent } from "./consents";
import { RleContent } from "./rleContent";

export const UserData = z.object({
  consents: z.array(Consent),
  hasAccess: z.boolean(),
  rlecontents: z.array(RleContent),
  userId: z.string(),
});

export type UserData = z.infer<typeof UserData>;
