import { platform, PlatformType } from "../../../bud-lite-tv/src/lib";
import { Player } from "../../libs/player";
import { Didomi } from "../cmp/didomi";
const youbora = require("youboralib");

enum YouboraAccountCode {
  prod = "francetelevisions",
  dev = "francetelevisionsdev",
}

interface IYouboraWindow {
  plugin: IYouboraPlugin;
}

export interface YouboraVideoParams {
  username?: string | null;
  /**
   * 	You must set this parameter to let users search and filter real time data.
   */
  "content.title": string;
  /**
   * Filter your data by the episode title.
   * This field should be used for content that has this relevant field (eg. series instead of movies).
   */
  "content.episodeTitle"?: string;
  /**
   * If you set this parameter, what you set will be displayed as Program on NPAW Suite.
   * This can be changed multiple times during the view (e.g., user is watching a single TV channel, but the programs/TV shows keep changing).
   */
  "content.program"?: string;
  /**
   * Filter your data by a specific season or sequence of related series programs, such as first, second, or third season of a specific series.
   */
  "content.season"?: number;
  /**
   * Filter your data by a specific content ID (internal content identifier code specifically set by each customer).
   */
  "content.id"?: string;
  /**
   * Media duration in seconds: This parameter is drawn from the player, but will be overwritten should the plugin be integrated.
   */
  "content.duration"?: string;
  /**
   * Filter your data by the Digital Rights Management technology being used to encrypt and protect ad content.
   */
  "content.drm"?: string | null;
  /**
   * Filter your data by the channel being tuned into to view content.
   */
  "content.channel"?: string;
  /**
   * Filter your data by a specific content type such as trailer, main, extra, clip, preview.
   */
  "content.type"?: string;
  /**
   * Filter your data by a specific content genre such as romance, thriller, fantasy, among many others.
   */
  "content.genre"?: string;
  /**
   * This parameter indicates whether a stream URL resource is a live stream or Video on Demand (VoD) content.
   * Accordingly, if 'true' (@YES), then our Analytics app will treat that content as a Live stream.
   * If 'false' (@NO), then it will treat that content as VoD.
   */
  "content.isLive": boolean;
  /**
   * 	Media URL: This must be set when integrating the plugin.
   */
  "content.resource": string;
  /**
   * The original CDN is automatically detected from the media resource host.
   * But it can be overwritten with this parameter, if needed.
   * Find the CDNs list here. You need to use the “code” string.
   */
  "content.cdn"?: string;
  /**
   * Filter your data by the price which the customer has paid to view a specific piece of content.
   * This is only applicable when a user has made a transaction for this purchase.
   *
   * <!> Optional <!> Can be set to 0 if needed
   */
  "content.price"?: number;
  /**
   * Filter your data by the cost of the subscription that the user has signed up for.
   *
   * <!> Optional <!> Can be set to 0 if needed
   */
  "content.cost"?: number;
  /**
   * App name
   */
  "app.name"?: string;
  /**
   * App version
   */
  "app.releaseVersion"?: string;
  /**
   * Filter your data by the video codec used within the container format.
   */
  "content.encoding.videoCodec"?: "video codec name";
  /**
   * Filter your data by the audio codec used within the container format.
   */
  "content.encoding.audioCodec"?: "audio codec name";
  // /**
  //  * Filter your data by a specific IMBD ID.
  //  * <!> Not required <!>
  //  */
  // "content.imdbId"?: string;
  // /**
  //  * Filter your data by a specific Gracenote ID.
  //  * <!> Not required <!>
  //  */
  // "content.gracenoteId"?: string;
  // /**
  //  * Filter your data by a specific package such as Warner Bros, Paramount, Disney, Netflix, HBO, etc.
  //  * <!> Not required <!>
  //  */
  // "content.package"?: string;
  "content.customDimension.1"?: string;
  "content.customDimension.2"?: string;
  "content.customDimension.3"?: string;
  "content.customDimension.4"?: string;
  "content.customDimension.5"?: string;
  "content.customDimension.6"?: string;
  "content.customDimension.7"?: string | null;
  "content.customDimension.8"?: string;
  obfuscateIp?: boolean;
}

const PLAYER_DOM_ELEMENT_ID = "playerVideo";

interface IYouboraPlugin {
  removeAdapter: () => void;
  removeAdsAdapter: () => void;
  setAdapter: (adapter: unknown) => void;
  setAdsAdapter: (adapter: unknown) => void;
  setOptions: (videoParams: YouboraVideoParams) => void;
}

export interface IYouboraAdapter {
  fireJoin: () => void;
  fireStart: () => void;
  fireStop: () => void;
  fireResume: () => void;
  firePause: () => void;
  fireSeekBegin: () => void;
  fireSeekEnd: () => void;
  fireInit: () => void;
  fireError: () => void;
}

export interface IYouboraSamsungAdapter extends IYouboraAdapter {
  oncurrentplaytime: () => void;
  onstreamcompleted: () => void;
  onbufferingcomplete: () => void;
}

type YouboraAdParam = {
  position: "pre" | "mid" | "post";
  adNumber: number;
  adResource: string;
  adCampaign: string;
};

export interface IYouboraAdAdapter extends Omit<IYouboraAdapter, "fireStart" | "fireJoin"> {
  fireManifest: () => void;
  fireBreakStart: () => void;
  fireBreakStop: () => void;
  fireStart: (params: YouboraAdParam) => void;
  fireJoin: (
    params: YouboraAdParam & {
      adTitle: string;
    }
  ) => void;
  fireQuartile: (quartile: 1 | 2 | 3) => void;
}

export class YouboraManager<T extends IYouboraAdapter> {
  /**
   * Static value to disable Youbora if needed
   * ie. youbora_consent = "disabled"
   */
  public static disableYoubora = false;

  private static _initialized = false;
  private readonly _player: Player;
  private _videoParams: YouboraVideoParams | undefined;

  private _adapter: T | undefined;
  private _adsAdapter: IYouboraAdAdapter | undefined;
  private _plugin: IYouboraPlugin;

  constructor(player: Player) {
    this._requireDepedencies();

    this._player = player;

    const accountCode = __BACKEND_TARGET__ === "prod" ? YouboraAccountCode.prod : YouboraAccountCode.dev;

    Log.analytics.warn(`[YOUBORA][ACCOUNT_CODE] ${accountCode}`);

    this._plugin = ((window as unknown) as IYouboraWindow).plugin = new youbora.Plugin({
      accountCode: accountCode,
    });
  }

  /*
   ** Initialize Youbora’s plugin as soon as you can in your workflow.
   ** Player does not need to exist yet.
   */
  private _requireDepedencies() {
    if (YouboraManager._initialized === false) {
      YouboraManager._initialized = true;
      switch (platform.type) {
        case PlatformType.tizen:
          console.log("[YOUBORA][TIZEN] require adapter");
          require("youbora-adapter-samsungavplay");
          break;
        case PlatformType.webos:
          console.log("[YOUBORA][WEBOS] require adapter");
          require("youbora-adapter-lghtml5");
          break;
        case PlatformType.orange:
          console.log("[YOUBORA][DASHJS] require adapter");
          require("youbora-adapter-dashjs");
          break;
        case PlatformType.other:
          console.log("[YOUBORA][HTML5] require adapter");
          require("youbora-adapter-html5");
          break;
        default:
          console.error("[YOUBORA][ERROR] require adapter");
          break;
      }
    }
  }

  public releaseAdapters() {
    try {
      this._plugin.removeAdapter();
      this._plugin.removeAdsAdapter();
      this._adapter = undefined;
      this._adsAdapter = undefined;
    } catch (e) {
      console.error("[YouboraManager] Failed to release adapters", e);
    }
  }

  public initiliazeVideoAdapters(player?: unknown) {
    const that = this;

    if (this._adapter !== undefined || this._adsAdapter !== undefined) {
      console.error("[YouboraManager] Adapter were not releasd properly ! Attempting to release adapters.");
      this.releaseAdapters();
    }

    // Adding new ads adapter
    this._adsAdapter = new youbora.Adapter();
    this._plugin.setAdsAdapter(this._adsAdapter);

    const commonOverride = {
      getPlayerVersion: function (this: IYouboraAdapter) {
        /**
         * Redefines `.getPlayerVersion()` method.
         *
         * This will override `playerVersion` property for every Youbora event
         * ! This should be used only for Web OS & Web browser
         */
        if (platform.type === PlatformType.webos) {
          /**
           * On Web OS, since we are using the native HTML5 player
           * we do not have any player version available.
           *
           * We decided to return Web OS version as Youbora player version
           */
          return platform.version;
        } else {
          /**
           * On Web browser, we are using a native HTML player,
           *
           * Since this player is not used for TV and should not be used to debug the app,
           * the player version has been set as a constant.
           */
          return "Fake player";
        }
      },
      getPlayhead: function (this: IYouboraAdapter) {
        /**
         * Redefines `.getPlayhead()` method.
         *
         * This will override `playhead` property for every Youbora event
         *
         * Since we are using the same player for both ads and video content,
         * we need to insure that the playhead is returned only when ad video is not
         * playing.
         */
        if (that._player.adPlaying$.value === false) {
          return that._player.duration$.value;
        } else {
          return null;
        }
      },
      endedListener: function (this: IYouboraAdapter) {
        /**
         * Redefines `.endedListener()` method.
         *
         * Since we are using the same player for both ads and video content,
         * we need to insure that the session is sotpped only when stopping to watch
         * ads / video but not every time an ad video is stopped.
         */
        if (that._player.adPlaying$.value === false) {
          this.fireStop();
        }
      },
    };

    // Adding new video adapter depining on platform
    switch (platform.type) {
      case PlatformType.tizen:
        {
          const SamsungAvPlay = youbora.adapters.SamsungAvplay.extend({
            onstreamcompleted: commonOverride.endedListener,
            getPlayhead: commonOverride.getPlayhead,
            onerror: function (eventType: unknown) {
              this.fireFatalError(eventType);

              this.fireStart();
            },
          });

          this._adapter = new SamsungAvPlay(PLAYER_DOM_ELEMENT_ID);
          Log.analytics.warn(`[YouboraManager][TIZEN] setAdapter`, this._adapter);
          this._plugin.setAdapter(this._adapter);
        }
        break;
      case PlatformType.webos:
        {
          const LgHTML5 = youbora.adapters.LgHtml5.extend({
            endedListener: commonOverride.endedListener,
            getPlayerVersion: commonOverride.getPlayerVersion,
            getPlayhead: commonOverride.getPlayhead,
            playingListener: function () {
              /**
               * LG Adapter redefines `.playingListener()` event
               *
               * With ad event implementing, when using default `.playingListener` for Web OS adapter
               * an `/breakStart` & `/breakStop` event are created for each ads
               * instead of only one `/breakStart` event before every ads
               * and one `/breakStop` event after every ads
               *
               * `.playingListener()` is redefined as default HTML5 adapter event
               * which is not causing this issue
               */
              this.fireResume();
              this.fireSeekEnd();
            },
          });
          this._adapter = new LgHTML5(PLAYER_DOM_ELEMENT_ID);
          Log.analytics.warn(`[YouboraManager][WEBOS] setAdapter`, this._adapter);
          this._plugin.setAdapter(this._adapter);
        }
        break;
      case PlatformType.orange:
        if (player) {
          const dashJsAdapter = youbora.adapters.DashJS.extend({
            endedListener: commonOverride.endedListener,
            getPlayerVersion: commonOverride.getPlayerVersion,
            getPlayhead: commonOverride.getPlayhead,
          });
          this._adapter = new dashJsAdapter(player);
          Log.analytics.warn(`[YouboraManager][DASHJS] setAdapter`, this._adapter);
          this._plugin.setAdapter(this._adapter);
        }
        break;
      case PlatformType.other:
        {
          const Html5Adapter = youbora.adapters.Html5.extend({
            endedListener: commonOverride.endedListener,
            getPlayerVersion: commonOverride.getPlayerVersion,
            getPlayhead: commonOverride.getPlayhead,
          });
          this._adapter = new Html5Adapter(PLAYER_DOM_ELEMENT_ID);
          Log.analytics.warn(`[YouboraManager][HTML5] setAdapter`, this._adapter);
          this._plugin.setAdapter(this._adapter);
        }
        break;
      default:
        Log.analytics.error(`[YouboraManager][ERROR] setAdapter`);
        break;
    }
  }

  public initVideoAdapterParams(videoParams: YouboraVideoParams) {
    Log.analytics.log("[A], initVideoAdapterParams");

    /**
     * cmp.consents.purposes[0].enabled represents the state of `Mesure d'audience` toggle button
     * - true: purpose has been enabled, we can collect private informations such as IP address, user id...
     * - false: purpose has been disabled, we should NOT collect private informations
     *
     *  cmp.consents.vendors.enabled.includes("youbora-ParxjGGA") represents the state of `Youbora` partner toggle button
     * - true: Youbora has been enabled, we should collect private information IF AND ONLY IF purpose has been enabled
     * - false: Youbora has been disabled, we should NOT collect private informations
     */
    if (Didomi.isVendorAllowedToTrack("youbora-YdPrzZLh")) {
      /**
       * If youbora_consent is optin,
       *
       * we should NOT obfuscate IP address
       * we should send device ID as userId
       */
      videoParams.username = localStorage.getItem("osn_device_id");
      videoParams["obfuscateIp"] = false;
    } else {
      /**
       * If youbora_consent is exempt,
       *
       * we should obfuscate IP address
       * we should NOT send device ID as userId
       */
      videoParams["obfuscateIp"] = true;
    }
    Log.analytics.warn("[YouboraManager] setOption", videoParams);
    this._videoParams = videoParams;
    this._plugin.setOptions(this._videoParams);
  }

  public getAdapter(): T | undefined {
    return this._adapter;
  }

  public getAdsAdapter(): IYouboraAdAdapter | undefined {
    return this._adsAdapter;
  }
}
