import "./programDesc.scss";

import { parseMarkerPiano, parsePlayButtonAction } from "~datas/parser";
import { Program } from "~models/program";
import { Plugin } from "~pages/../datas/plugin";
import { pushPlayerPage } from "~pages/rootPage";
import { sendPianoAnalytic } from "~tools/analytics/piano";
import { getChannelPicto } from "~tools/getChannelPics";
import { popUpUserNeedConnectToReview, pushPopUpLogin } from "~tools/pushPopUpLogin";
import { checkImage } from "~tools/tools";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { FavoriteButton, FavoriteButtonType } from "~views/buttons/favoriteButton";
import { PlayButton } from "~views/buttons/playButton";

import { Config } from "../../config";
import { PlayableItem } from "../../models/playableItem";
import { ILoggedInUser } from "../../models/user";
import { sendOrangeEvent } from "../../tools/analytics/orangeStats";
import { getOrangeProviderIdFromBroadcastChannel } from "../../tools/apiOrange/channelOrangeProviderIdMap";
import { Didomi } from "../../tools/cmp/didomi";
import { LikeOrDislike, TaxonomyRatingsHelper, UpdateTaxonomyRating } from "../../tools/ratingsHelper";
export type PlayButtonAction = { id: number; duration: number; label: string; episode_title: string; type: string };

type WatchedResponse = {
  watchNext: {
    id: number;
    episode_title: string;
    remaining_duration: number;
    percentageViewed: number;
  } | null;
};

const WatchedResponse = {
  parse: (value: any): WatchedResponse => {
    if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value) &&
      (value.watchNext === null ||
        (typeof value.watchNext === "object" &&
          value.watchNext !== null &&
          !Array.isArray(value.watchNext) &&
          typeof value.watchNext.id === "number" &&
          Number.isFinite(value.watchNext.id) &&
          typeof value.watchNext.episode_title === "string" &&
          typeof value.watchNext.remaining_duration === "number" &&
          Number.isFinite(value.watchNext.remaining_duration) &&
          typeof value.watchNext.percentageViewed === "number" &&
          Number.isFinite(value.watchNext.percentageViewed)))
    ) {
      return value;
    }
    const msg = "Parse error WatchedResponse";
    Log.app.error(msg, value);
    throw msg;
  },
};

export class ProgramDesc extends View {
  private _program: Program;
  private _programPath: string;
  private readonly _hasItems: boolean;
  private _playButtonView = new PlayButton();
  private _favoriteButtonView?: FavoriteButton;
  private _likeThumbButtonView?: FavoriteButton;
  private _notLikeThumbButtonView?: FavoriteButton;
  private _isLiked: LikeOrDislike = 0;
  private _isFavorites = false;
  private _playButtonVideoItem?: PlayableItem;
  private _playButtonVideoId?: string;

  constructor(program: Program, programPath: string, hasItems: boolean) {
    super(DOMHelper.createDivWithParent(null, null, "programDesc"));
    this._program = program;
    this._programPath = programPath;
    this._hasItems = hasItems;
    this._fetchSource();
    this._fetchIsReviewContent();
  }

  private _fetchIsReviewContent() {
    this._isLiked = TaxonomyRatingsHelper.get(this._program.id) ?? 0;
    this._likeThumbButtonView?.updateIsFavorite(this._isLiked === 1);
    this._notLikeThumbButtonView?.updateIsFavorite(this._isLiked === -1);
  }

  private _fetchSource() {
    const user = Plugin.getInstance().user;
    if (this._program.id && user.isActive()) {
      Plugin.getInstance()
        .getIsFavorite(user, this._program.id, "program")
        .subscribe(
          value => {
            Log.api.log("[getIsFavorite] Next !", value);
            this._isFavorites = value;
            this._onSourceReady();
          },
          error => {
            Log.api.error("[getIsFavorite] Error !", error);
            this._onSourceReady();
          }
        );
    } else {
      this._onSourceReady();
    }
  }

  private _fetchDelFavorite(user: ILoggedInUser) {
    Plugin.getInstance()
      .delFavorite(user, this._program.id, "program")
      .subscribe(
        value => {
          Log.api.log("[delFavorite] Next !", value);
        },
        error => {
          Log.api.error("[delFavorite] Error !", error);
        }
      );
  }

  private _fetchAddFavorite(user: ILoggedInUser) {
    Plugin.getInstance()
      .addFavorite(user, this._program.id, "program")
      .subscribe(
        value => {
          Log.api.log("[addFavorite] Next !", value);
        },
        error => {
          Log.api.error("[addFavorite] Error !", error);
        }
      );
  }

  private _fetchPlayButtonActionFromProgramMeta() {
    Plugin.getInstance()
      .fetchProgramMeta(this._programPath)
      .subscribe(
        value => {
          Log.api.log("[Program Meta] next !", value);
          const buttonAction = parsePlayButtonAction(value?.[0]);

          if (buttonAction !== undefined) {
            this._playButtonView?.updateButton(buttonAction.label, buttonAction.episode_title, {
              duration: buttonAction.duration,
            });
            this._playButtonVideoId = buttonAction.id.toString();
            this._findPlayButtonVideoItem();
          }
        },
        error => {
          Log.api.error("[Program Meta] Error !", error);
        }
      );
  }

  private _findPlayButtonVideoItem() {
    if (this._playButtonVideoId !== undefined && this._playButtonVideoId.length > 0) {
      Plugin.getInstance()
        .fetchItemById(this._playButtonVideoId)
        .subscribe(
          value => {
            Log.api.log("[PlayButton FindItem] next !", value);
            this._playButtonVideoItem = value[0];
          },
          error => {
            Log.api.log("[PlayButton FindItem] error !", error);
          }
        );
    }
  }

  fetchPlayButton() {
    const user = Plugin.getInstance().user;

    if (user.isActive()) {
      Plugin.getInstance()
        .fetchProgramWatchNext(user, this._program.id)
        .subscribe(
          value => {
            Log.api.log("[ProgramDesc PlayButton] fetchProgramWatchNext next !", value);
            try {
              const watchNext = WatchedResponse.parse(value[0]).watchNext;
              if (watchNext !== null) {
                this._playButtonView?.updateButton("Reprendre", watchNext.episode_title, {
                  duration: undefined,
                  remaining_duration: watchNext.remaining_duration,
                  percentage: watchNext.percentageViewed,
                });
                this._playButtonVideoId = watchNext.id.toString();
                this._findPlayButtonVideoItem();
              } else {
                this._fetchPlayButtonActionFromProgramMeta();
              }
            } catch (_) {
              this._fetchPlayButtonActionFromProgramMeta();
            }
          },
          error => {
            Log.api.error("[ProgramDesc PlayButton] fetchProgramWatchNext Error !", error);
          }
        );
    } else {
      this._fetchPlayButtonActionFromProgramMeta();
    }
  }

  private _onSourceReady = () => {
    // Creating div with the logo of the program
    const containerImage = DOMHelper.createDivWithParent(this.rootElement, null, "containerImage");
    const titleImg = DOMHelper.createDivWithParent(containerImage, null, "titleImg");

    titleImg.innerText = this._program.title;
    if (this._program.artworks?.length) {
      const logoImgUrl = this._program.getLogoImgUrl();
      if (logoImgUrl.length) {
        checkImage(
          logoImgUrl,
          () => {
            titleImg.innerText = "";
            titleImg.style.width = "481px";
            titleImg.style.backgroundImage = `url("${logoImgUrl}")`;
          },
          () => {}
        );
      }
    }

    DOMHelper.createDivImg(
      this.rootElement,
      null,
      "logoChannel",
      getChannelPicto(this._program) || require("~images/channels/picto/france-tv.png")
    );
    DOMHelper.createDivWithParent(this.rootElement, null, "summary", this._program.summary);

    this._program.sponsored &&
      DOMHelper.createSpanWithParent(this.rootElement, null, "sponsoredTag programPage", "sponsorisé");

    const buttonsContainer = DOMHelper.createDivWithParent(this.rootElement, null, "buttonsContainer");
    const buttons: (FavoriteButtonType | "playButton")[] = [];

    if (this._hasItems) {
      buttons.push("playButton");
    }
    if (Plugin.getInstance().user.id !== undefined || Config.enabledFeatures.login === true) {
      buttons.push("favorite");
    }

    if (Didomi.isVendorAllowedToTrack("spideo-TcYnKH8L")) {
      buttons.push("likeThumb");
      buttons.push("notLikeThumb");
    }

    const providerId = getOrangeProviderIdFromBroadcastChannel(this._program);

    this.delegate = createListComponent(
      {
        rootElement: buttonsContainer,
        modelSource: new StaticModelSource<FavoriteButtonType | "playButton">(buttons),
        horizontal: true,
        pageSize: 4,
        viewFactory: model => {
          if (model === "playButton") {
            return this._playButtonView;
          } else if (model === "likeThumb") {
            this._likeThumbButtonView = new FavoriteButton(this._isLiked === 1, model);
            return this._likeThumbButtonView;
          } else if (model === "notLikeThumb") {
            this._notLikeThumbButtonView = new FavoriteButton(this._isLiked === -1, model);
            return this._notLikeThumbButtonView;
          } else {
            this._favoriteButtonView = new FavoriteButton(this._isFavorites, model);
            return this._favoriteButtonView;
          }
        },
        onSelect: model => {
          switch (model) {
            case "playButton":
              if (this._playButtonVideoItem !== undefined) {
                pushPlayerPage(this._playButtonVideoItem, undefined);
              }
              return true;
            case "favorite": {
              const user = Plugin.getInstance().user;

              const category = this._isFavorites ? "favori_retrait" : "favori_ajout";
              this._sendPianoAnalyticsClick(category);
              sendOrangeEvent({
                eventType: "click",
                category,
                providerId,
                label: "ma liste",
                contentID: this._program.id,
                contentLabel: this._program.title,
              });
              if (user.isActive()) {
                if (this._isFavorites) {
                  this._fetchDelFavorite(user);
                } else {
                  this._fetchAddFavorite(user);
                }
                this._isFavorites = !this._isFavorites;
                this._favoriteButtonView?.updateIsFavorite(this._isFavorites);
              } else {
                pushPopUpLogin("Vous souhaitez mettre ce programme en favoris ? Connectez-vous !");
              }
              return true;
            }
            case "likeThumb": {
              const user = Plugin.getInstance().user;
              if (user.isActive()) {
                if (this._isLiked === -1) {
                  this._isLiked = 1;
                  this._notLikeThumbButtonView?.updateIsFavorite(false);
                } else if (this._isLiked === 0) {
                  this._isLiked = 1;
                } else {
                  this._isLiked = 0;
                }

                const category = this._isLiked === 1 ? "ajout_like_programme" : "retrait_like_programme";
                this._sendPianoAnalyticsClick(category);
                sendOrangeEvent({
                  eventType: "click",
                  category,
                  providerId,
                  label: "j'aime",
                  contentID: this._program.id,
                  contentLabel: this._program.title,
                });
                UpdateTaxonomyRating(user, this._program.id, this._programPath, this._isLiked);
                this._likeThumbButtonView?.updateIsFavorite(this._isLiked === 1);
              } else {
                this._sendPianoAnalyticsClick("tentative_like_programme");
                popUpUserNeedConnectToReview();
              }

              return true;
            }
            case "notLikeThumb": {
              const user = Plugin.getInstance().user;

              if (user.isActive()) {
                if (this._isLiked === 1) {
                  this._isLiked = -1;
                  this._likeThumbButtonView?.updateIsFavorite(false);
                } else if (this._isLiked === 0) {
                  this._isLiked = -1;
                } else {
                  this._isLiked = 0;
                }
                this._sendPianoAnalyticsClick(
                  this._isLiked === -1 ? "ajout_dislike_programme" : "retrait_dislike_programme"
                );
                UpdateTaxonomyRating(user, this._program.id, this._programPath, this._isLiked);
                this._notLikeThumbButtonView?.updateIsFavorite(this._isLiked === -1);
              } else {
                this._sendPianoAnalyticsClick("tentative_like_programme");
                popUpUserNeedConnectToReview();
              }
              return true;
            }
          }
        },
      },
      list => list.setFocusOnIndex(0)
    );
    this.fetchPlayButton();
  };

  private _sendPianoAnalyticsClick = (clickValue: string) => {
    const markerPiano = parseMarkerPiano(this._program.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic("click.action", {}, { ...markerPiano.additional_properties, click: clickValue });
    } else {
      Log.analytics.error("Failed to send piano analytics click");
    }
  };
}
