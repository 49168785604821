import { z } from "zod";

export const RleContent = z.object({
  contentId: z.string(),
  // providerId: z.string(),
  timecode: z.number(),
  // resumeTimecode: z.number(),
  // timecodeUpdateDate: z.number(),
  // watchingState: z.string(),
});

export type RleContent = z.infer<typeof RleContent>;
