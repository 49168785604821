import "./parentalControlPopup.scss";

import { createListComponent, DOMHelper, IListComponent, IPage, StaticModelSource, View } from "~ui-lib";

import { navigationStack } from "../../main";
import { DigitButtonViewItem } from "./digitButtonView";
import { Digits, getRandomItems, getRandomItemsCode } from "./digitItems";

const _numberCodeDigit = 3;

export class ParentalControlPopup extends View implements IPage {
  private _codeElement: HTMLElement;
  private _codeValueTextElement: HTMLElement;
  private _topContainer: HTMLElement;
  private _botContainer: HTMLElement;
  private _randomCode: Digits[];
  private _codeValue: string;
  private _randomDigits: Digits[];
  private _list?: IListComponent<Digits>;
  private _digitListElement: HTMLElement;
  private _digitCounter: number;
  private onSuccess: () => void;

  constructor(onSuccess: () => void) {
    super(DOMHelper.createDivWithParent(null, "ParentalControlPopupOverlay", "ParentalControlPopupOverlay"));
    this._codeValue = "";
    for (let i = 0; i < _numberCodeDigit; i++) {
      if (i !== _numberCodeDigit - 1) {
        this._codeValue += "- ";
      } else {
        this._codeValue += "-";
      }
    }
    this._randomCode = getRandomItemsCode(_numberCodeDigit);
    console.log(this._randomCode);
    this._digitCounter = 0;
    this.onSuccess = onSuccess;

    const popup = DOMHelper.createDivWithParent(this.rootElement, "ParentalControlPopupContainer", null);
    this._topContainer = DOMHelper.createDivWithParent(
      popup,
      null,
      "parentalControlPopupTopContainer",
      "Pour quitter le mode enfant, tapez le code ci-dessous"
    );
    let randomCodeText = "";
    for (let i = 0; i < _numberCodeDigit; i++) {
      if (i !== 0) {
        randomCodeText += " - ";
      }
      randomCodeText += this._randomCode[i].letters;
    }
    DOMHelper.createDivWithParent(this._topContainer, null, "parentalControlPopupNumericCode", randomCodeText);
    this._codeElement = DOMHelper.createDivWithParent(this._topContainer, null, "parentalControlPopupCode");
    this._codeValueTextElement = DOMHelper.createDivWithParent(
      this._codeElement,
      null,
      "parentalControlPopupCodeValue",
      this._codeValue
    );

    this._botContainer = DOMHelper.createDivWithParent(popup, null, "parentalControlPopupBotContainer", "code erroné");

    this._digitListElement = DOMHelper.createDivWithParent(this._botContainer, null, "parentalControlPopupDigit");

    this._randomDigits = getRandomItems(6, this._randomCode);
    this.delegate = this._list = createListComponent(
      {
        rootElement: this._digitListElement,
        modelSource: new StaticModelSource(this._randomDigits),
        viewFactory: model => {
          return new DigitButtonViewItem(model.id.toString());
        },
        horizontal: false,
        crossSectionWidth: 3,
        pageSize: 2,
        onSelect: (model: Digits) => {
          this._onSelectDigit(model);
          return true;
        },
      },
      list => {
        this._list = list;
        list.setFocusOnIndex(0);
      }
    );
  }

  isPopup = () => true;

  private _onSelectDigit(digit: Digits) {
    this._codeValue = this._codeValue.replace("-", digit.id.toString());
    this._codeValueTextElement.innerHTML = this._codeValue;
    this._codeValueTextElement.style.color = "black";
    this._checkCode(digit);
    this._digitCounter++;
  }

  private _checkCode(digit: Digits) {
    let errorOccurred = false;
    if (digit.letters !== this._randomCode[this._digitCounter].letters) {
      this._codeValueTextElement.innerHTML = this._codeValue;
      this._botContainer.style.color = "red";
      errorOccurred = true;
    } else {
      for (let i = 0; i < this._digitCounter; i++) {
        const copy = this._codeValue.split(" ");
        if (copy[i] !== this._randomCode[i].id.toString()) {
          errorOccurred = true;
          break;
        }
      }
      if (errorOccurred) {
        this._botContainer.style.color = "red";
      } else {
        this._botContainer.style.color = "transparent";
      }
    }
    if (this._digitCounter === _numberCodeDigit - 1) {
      let code = "";
      for (let i = 0; i < _numberCodeDigit; i++) {
        code += this._randomCode[i].id.toString() + " ";
      }
      code = code.trim();
      if (this._codeValue === code) {
        navigationStack.removePage(this);
        this.onSuccess();
      } else {
        this._codeValue = "- ".repeat(_numberCodeDigit).trim();
        this._botContainer.style.color = "red";
        this._codeValueTextElement.innerHTML = "- ".repeat(_numberCodeDigit).trim();
        this._codeValueTextElement.style.color = "#8f9ba7";
        this._digitCounter = -1;
      }
    }
  }
}
