import { BrowsableItem } from "~models/browsableItem";
import { Channel } from "~models/channel";
import { Extrait } from "~models/extrait";
import { Integrale } from "~models/integrale";
import { Unit } from "~models/unit";

import { PlayableItem } from "../models/playableItem";

const _validChannelName = [
  "france-2",
  "france-3",
  "france-4",
  "france-5",
  "franceinfo",
  "culturebox",
  "slash",
  "okoo",
  "la1ere",
  "paris-h24",
];

export function findChannel(item: BrowsableItem) {
  let channelString = "";
  if (
    (item instanceof Integrale || item instanceof Unit || item instanceof Extrait) &&
    item?.media?.broadcast?.extras?.broadcast_channel
  ) {
    channelString = item.media.broadcast.extras.broadcast_channel;
  }
  if (typeof item?.extras?.channel === "string") {
    channelString = item.extras.channel;
  } else if (item?.extras?.channel instanceof Channel) {
    channelString = item?.extras?.channel?.extras?.channel_path;
  }
  return channelString;
}

export function getChannelPicto(item: BrowsableItem, whiteIcon?: boolean) {
  const channelString = findChannel(item);
  if (_validChannelName.includes(channelString)) {
    try {
      // serie is a _validChannelName but has no logoWhite or picto
      // may be you have to add them
      return require(`~images/channels/${whiteIcon ? "logoWhite" : "picto"}/` + channelString + ".png");
    } catch (e) {
      return undefined;
    }
  } else {
    if (channelString) {
      console.log("Unhandle channel string : " + channelString);
    }
    return undefined;
  }
}

export function getChannelLogo(item: BrowsableItem) {
  const channelString = findChannel(item);
  if (_validChannelName.includes(channelString)) {
    return require("~images/channels/logo/" + channelString + ".png");
  } else {
    if (channelString) {
      console.log("Unhandle channel string : " + channelString);
    }
    return undefined;
  }
}

export function getChannelLogoPlayer(item: PlayableItem) {
  const channelString = findChannel(item);
  if (_validChannelName.includes(channelString)) {
    return require("~images/channels/logoPlayer/" + channelString + ".png");
  } else {
    if (channelString) {
      console.log("Unhandle channel string : " + channelString);
    }
    return undefined;
  }
}
