import "./rootPage.scss";

import { Category } from "~models/category";
import { Channel } from "~models/channel";
import { Collection } from "~models/collection";
import { Event } from "~models/event";
import { Extrait } from "~models/extrait";
import { Integrale } from "~models/integrale";
import { Program } from "~models/program";
import { Region } from "~models/region";
import { Unit } from "~models/unit";
import { CategoryDetailTab } from "~pages/category/categoryDetailTab";
import { ChannelTab } from "~pages/channel/channelTab";
import { CollectionTab } from "~pages/collection/collectionTab";
import { EventTab } from "~pages/event/eventTab";
import { PlayerMagnetoPage } from "~pages/player/playerMagnetoPage";
import { PlayerPage } from "~pages/player/playerPage";
import { ProgramTab } from "~pages/program/programTab";
import { UnitTab } from "~pages/unit/unitTab";
import { sendClickPianoEvent } from "~tools/analytics/piano";
import { OrangeDeviceInfo } from "~tools/apiOrange/orangeDeviceInfo";
import { createListComponent, DOMHelper, IListComponent, IPage, Keys, StaticModelSource, View } from "~ui-lib";

import { Config } from "../config";
import { parseMarkerPianoPageDisplay } from "../datas/parser";
import { Plugin } from "../datas/plugin";
import { exitApp, navigationStack } from "../main";
import { Flux } from "../models/flux";
import { ItemCollection } from "../models/itemCollection";
import { PlayableItem } from "../models/playableItem";
import { Tag } from "../models/tag";
import { sendOrangeEvent } from "../tools/analytics/orangeStats";
import { getOrangeProviderIdFromBroadcastChannel } from "../tools/apiOrange/channelOrangeProviderIdMap";
import { pushPopUpLogin } from "../tools/pushPopUpLogin";
import { MainMenuItemSlug } from "../views/mainMenu/itemMenu";
import { MainMenuComponent } from "../views/mainMenu/mainMenu";
import { ConnexionPage } from "./connexion/connexionPage";
import { FavoritesTab } from "./favorites/favoritesTab";
import { HomeTab } from "./home/homeTab";
import { OkooTab } from "./okoo/okooTab";
import { ParentalControlPopup } from "./parentalControlPopup/parentalControlPopup";

enum TopLevelID {
  menu = "menu",
  content = "content",
}

export const pushTabWithMenu = (content: View, htmlEltId: string, slugOn?: MainMenuItemSlug) => {
  navigationStack.pushPage(new RootPage(content, htmlEltId, slugOn));
  pianoAnalyticsSent = false;
};

let pianoAnalyticsSent = false;

export const isKidPage = (page: unknown) => {
  if (page instanceof RootPage) {
    const content = page.getContent();
    if (content instanceof OkooTab || (content instanceof CategoryDetailTab && content.isCategoryKid)) {
      return true;
    }
  }
  return false;
};

export const pushPlayerPage = (
  model: PlayableItem,
  index?: number,
  progress?: number,
  nextContents?: ItemCollection[]
) => {
  // index can be undefined when call from a deeplink or call from "Regarder Maintenant" in UnitDesc or when playerPage launch automatically the next content
  if (!pianoAnalyticsSent) {
    checkPianoProperties(model, index);
  }
  pianoAnalyticsSent = false;
  if (
    !Config.enabledFeatures.login ||
    Plugin.getInstance().user.isActive() ||
    (!Plugin.getInstance().user.isActive() && !model.login)
  ) {
    const playerPage = OrangeDeviceInfo.isNewBox()
      ? new PlayerPage(model, progress, nextContents, index)
      : new PlayerMagnetoPage(model, index);
    navigationStack.pushPage(playerPage);
    playerPage.init(); // wait that player page is pushed on navigation before launch other states screen (loader/errors)
  } else {
    pushPopUpLogin("Vous souhaitez voir cette vidéo ?\nConnectez-vous !");
  }
};

export const pushConnexionPage = () => {
  const connectPage = new ConnexionPage();
  navigationStack.pushPage(connectPage);
  connectPage.init(); // wait that player page is pushed on navigation before launch other states screen (loader/errors)
};

export type Tile = Event | Program | Unit | Collection | Integrale | Extrait | Channel | Region;

export const onSelectTile = (model: Tile, index: number) => {
  sendOrangeEvent({
    eventType: "click",
    category: model.type,
    providerId: getOrangeProviderIdFromBroadcastChannel(model),
    contentID: model.id,
    contentLabel: model.title,
  });
  if (model instanceof Program) {
    const topPage = navigationStack.topPage;
    if (topPage instanceof RootPage && topPage?.rootElement.id == "FavoritesPage") {
      const view = topPage.getContent();
      if (view instanceof FavoritesTab) {
        pushTabWithMenu(new ProgramTab(model, view, index), "ProgramPage");
      }
    } else pushTabWithMenu(new ProgramTab(model, undefined, index), "ProgramPage");
    return true;
  }

  if (model instanceof Unit) {
    if (model.metadata.extras.is_live) {
      Plugin.getInstance()
        .fetchDirects()
        .subscribe(
          value => {
            // Here use it to create the UI
            Log.app.log("[DIRECTS] Next !", value);
            pushPlayerPage(model, index, undefined, value);
          },
          error => {
            // Here use it to trigger and display an error
            Log.app.log("[DIRECTS] Error !", error);
          },
          () => {
            Log.app.log("[DIRECTS] Complete !");
          }
        );
    } else {
      pushTabWithMenu(new UnitTab(model, index), "UnitPage");
    }
    return true;
  }

  if (model instanceof Collection) {
    console.log("[XITI] root page", model);
    pushTabWithMenu(new CollectionTab(model, index), "CollectionPage");
    return true;
  }

  if (model instanceof Category) {
    pushTabWithMenu(new CategoryDetailTab(model, index), "CategoryPage");
    return true;
  }

  if (model instanceof Integrale || model instanceof Flux) {
    if (model.metadata.extras.is_live) {
      Plugin.getInstance()
        .fetchDirects()
        .subscribe(
          value => {
            // Here use it to create the UI
            Log.app.log("[DIRECTS] Next !", value);
            pushPlayerPage(model, index, undefined, value);
          },
          error => {
            // Here use it to trigger and display an error
            Log.app.log("[DIRECTS] Error !", error);
          },
          () => {
            Log.app.log("[DIRECTS] Complete !");
          }
        );
    } else {
      pushPlayerPage(model, index, undefined);
    }
    return true;
  }

  if (model instanceof Extrait) {
    pushPlayerPage(model, index);
    return true;
  }

  if (model instanceof Event) {
    pushTabWithMenu(new EventTab(model, index), "EventPage");
    return true;
  }

  if (model instanceof Tag) {
    pushTabWithMenu(new EventTab(model, index), "TagPage");
    return true;
  }

  if (model instanceof Channel) {
    pushTabWithMenu(new ChannelTab(model, index), "ChannelPage");
    return true;
  }

  if (model instanceof Region) {
    pushTabWithMenu(new ChannelTab(model, index), "RegionPage");
    return true;
  }

  console.log("Model type not handle yet : ");
  console.log(model);
  return true;
};

const checkPianoProperties = (item: Tile | PlayableItem, position?: number) => {
  const markerPiano = parseMarkerPianoPageDisplay(item.extras);

  if (item instanceof Event || markerPiano === undefined) {
    Plugin.getInstance()
      .fetchDetailed(item)
      .subscribe(value => {
        value[0]["itemCollection"] = {};
        value[0]["itemCollection"]["type"] = item.itemCollection?.type;
        sendClickPianoEvent(value[0], position);
      });
  } else {
    sendClickPianoEvent(item, position);
  }
  pianoAnalyticsSent = true;
};

export class RootPage extends View implements IPage {
  private static _mainMenu: MainMenuComponent = MainMenuComponent.getInstance(); // static because we need the menu items being fetched only one time by session
  private _content: View;
  private _list?: IListComponent;
  private _slugOn?: MainMenuItemSlug;
  private _isALevelOnePage = false; // (level 1 pages are pages that user can opened from the menu (home, search, live, ...))

  constructor(content: View, htmlEltId: string, slugOn?: MainMenuItemSlug) {
    super(DOMHelper.createDivWithParent(null, htmlEltId, null));
    this._content = content;
    if (slugOn) {
      this._slugOn = slugOn;
      RootPage._mainMenu.selectMenuItemSlug(this._slugOn);
      this._isALevelOnePage = true; // conditioned by param slugOn, might be better to add another parameter in the constructor
      navigationStack.destroyStack();
    }

    this.delegate = this._list = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource([TopLevelID.menu, TopLevelID.content]),
        viewFactory: model => {
          switch (model) {
            case TopLevelID.menu:
              return RootPage._mainMenu;
            case TopLevelID.content:
              return this._content;
            default:
              return this._content;
          }
        },
        horizontal: true,
        pageSize: 2,
        visibleBefore: 1,
        visibleAfter: 1,
        spatialFocus: true,
      },
      list => {
        // GL : Don't know why but we need to focus before menu and then foxus content to be sure to have the content focus by default
        list.setFocusOnId(TopLevelID.menu);
        list.setFocusOnId(TopLevelID.content);
      }
    );
  }

  getContent() {
    return this._content;
  }

  onNav = (key: Keys): boolean => {
    switch (key) {
      case Keys.back:
        // the exitPopup is displayed
        // - when user click "back" and the MENU IS FOCUSED (event if user is in a level 2, 3, 4, ... page)
        // - or if user is in the homePage (even if then menu is not opened)
        // else if user is on a level 1 page (level 1 pages are pages that user can opened from the menu (search, live, ...))
        // - the app open the "homePage"
        //
        // in the other cases ( user on level 2, 3, 4, ... page and focus in content)
        // - return false (the navigationStack will handle the "back" and will remove the page)
        if (this._list?.focusedId$.value === TopLevelID.menu || this._content instanceof HomeTab) {
          exitApp();
        } else if (isKidPage(this)) {
          navigationStack.pushPage(
            new ParentalControlPopup(() => {
              if (this._isALevelOnePage) {
                pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
              }
              navigationStack.removePage(this);
            })
          );
          return true;
        } else {
          if (this._isALevelOnePage) {
            pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
          }
          navigationStack.removePage(this);
        }
        return true;
    }
    return false;
  };
}
