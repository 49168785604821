import "./searchTab.scss";

import { Channel } from "~models/channel";
import { ItemCollection } from "~models/itemCollection";
import { KeyComponent } from "~pages/search/keyComponent";
import { PlaylistProgramSwimlane } from "~swimlaneViews/playlistProgramSwimlane";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import { createListComponent, debounce, DOMHelper, Listenable, ListenableSource, View } from "~ui-lib";
import { EmptySearchView } from "~views/emptySearchView";
import { ChannelSwimlane } from "~views/swimlanes/channelSwimlane";

import { Plugin } from "../../datas/plugin";
import { sendOrangePageViewEvent } from "../../tools/analytics/orangeStats";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { DEFAULT_ORANGE_PROVIDER_ID } from "../../tools/apiOrange/orangeProviderId";
import { verticalArrowFactory } from "../../views/arrows/verticalArrow";

export class TextBox extends View {
  textToSearch: HTMLElement;
  textToSearchBox: HTMLElement;
  isEmpty = true;
  textToSearch$: Listenable<string>;
  constructor(parent: HTMLElement, textToSearch$: Listenable<string>) {
    super(DOMHelper.createDivWithParent(parent, null, "searchTabTextBox"));
    this.textToSearch$ = textToSearch$;
    this.textToSearchBox = DOMHelper.createDivWithParent(this.rootElement, null, "searchTabTextToSearchBox");
    this.textToSearch = DOMHelper.createDivWithParent(
      this.textToSearchBox,
      null,
      "searchTabTextToSearch",
      "Un film, une série, une émission"
    );
    textToSearch$.didChange(newValue => {
      this.updateTextFields(newValue);
      if (newValue.length > 0) this.textToSearch.innerText = newValue;
      else this.textToSearch.innerText = "Un film, une série, une émission";
    });
  }
  updateTextFields = (newValue: string) => {
    this.textToSearch.innerText = newValue;
    if (this.textToSearch.offsetWidth > this.textToSearchBox.offsetWidth)
      this.textToSearch.style.left = this.textToSearchBox.offsetWidth - this.textToSearch.offsetWidth - 10 + "px";
    else this.textToSearch.style.left = "0";
  };
}

enum searchTabListItemId {
  keyboard = "keyboard",
  emptyResult = "emptyResult",
}

type searchTabListItemType = searchTabListItemId | ItemCollection;

export class SearchTab extends View {
  textToSearch$ = new Listenable("");
  keyComponent: KeyComponent;
  textBox: TextBox;
  loader?: HTMLElement = undefined;
  mainListData$ = new Listenable<searchTabListItemType[]>([]);
  defaultSearch?: ItemCollection = undefined;
  searchString?: string;
  updateFocusUnregister?: () => void;
  textToSearchUnregister?: () => void;
  mainListUnregister?: () => void;
  constructor() {
    super(DOMHelper.createDivWithParent(null, "SearchTab"));
    this.textBox = new TextBox(this.rootElement, this.textToSearch$);
    this.keyComponent = new KeyComponent(this.textToSearch$);
    this.keyComponent.cacheable = true;

    this.showLoader();

    this._fetchSources();
  }

  onShown() {
    sendPianoAnalytic(
      "page.display",
      {
        page: "recherche",
        page_type: "recherche",
      },
      {}
    );
    sendOrangePageViewEvent({
      pTitle: "recherche",
      providerId: DEFAULT_ORANGE_PROVIDER_ID,
    });
  }

  private _fetchSources() {
    Plugin.getInstance()
      .fetchDefaultSearch()
      .subscribe(
        value => {
          // Here use it to create the UI
          console.log("[SearchPage] fetchDefaultSearchByAge next !", value);
          if (value[0] instanceof ItemCollection) {
            this.defaultSearch = value[0];
            this.setSources(this.defaultSearch);
          }
          this._onSourceReady();
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[SearchPage] fetchDefaultSearchByAge Error !", error);
        },
        () => {
          console.log("[SearchPage] fetchDefaultSearchByAge Complete !");
        }
      );
  }

  private _fetchSourcesSearch(searchString: string) {
    const searchText = searchString;
    Plugin.getInstance()
      .fetchSearch(searchString)
      .subscribe(
        value => {
          // Here use it to get content searched
          Log.api.log("[SearchPage] fetchSearch next !", value);
          sendPianoAnalytic(
            "page.display",
            { page: "resultats", page_type: "recherche" },
            { ise_keyword: searchText, ise_page: "1", ise_result: value.length > 0 ? 1 : 0 }
          );
          this.setSources(new ItemCollection("empty_5302", "empty", "empty", null, value, [], null, false));
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.log("[SearchPage] fetchSearch Error !", error);
        },
        () => {
          Log.api.log("[SearchPage] fetchSearch Complete !");
        }
      );
  }

  private _onSourceReady() {
    this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, "SearchTabList"),
        modelSource: new ListenableSource(this.mainListData$, true),
        viewFactory: model => {
          if (model == searchTabListItemId.keyboard) return this.keyComponent;
          if (model == searchTabListItemId.emptyResult) return new EmptySearchView();
          if (model instanceof ItemCollection) {
            if (
              model.type == "categories" ||
              model.type == "playlist_program" ||
              model.type == "programs" ||
              model.type == "collections"
            ) {
              return new PlaylistProgramSwimlane(model);
            }
            if (model.type == "playlist_video" || model.type == "contents") {
              return new PlaylistVideoSwimlane(model, true);
            }
            if (model.type == "channels") {
              return new ChannelSwimlane(model);
            }
          }
          return new PlaylistProgramSwimlane(model);
        },
        arrowFactory: verticalArrowFactory,
        horizontal: false,
        visibleAfter: 3,
        visibleBefore: 0,
        pageSize: 1,
      },
      mainList => {
        this.hideLoader();
        mainList.setFocusOnIndex(0);
        this.updateFocusUnregister = mainList.focusedId$.didChange((newId, oldId) => {
          if (newId === searchTabListItemId.keyboard && oldId !== searchTabListItemId.keyboard && oldId !== undefined) {
            this.keyComponent?.lowercaseKeyboard?.listOfKeysComponent.setFocusOnIndex(1);
            this.keyComponent.digitKeyboard?.listOfKeysComponent.setFocusOnIndex(1);
            this.keyComponent.UppercaseKeyboard?.listOfKeysComponent.setFocusOnIndex(1);
          }
        });
        this.updateFocusUnregister = mainList.focusedIndex$.didChange((newIndex, oldIndex) => {
          if (this.mainListData$.value[1] === searchTabListItemId.emptyResult) {
            if (newIndex == 1 && oldIndex == 0) {
              mainList.setFocusOnIndex(2);
            }
            if (newIndex == 1 && oldIndex == 2) {
              mainList.setFocusOnIndex(0);
            }
          }
        });
      }
    );
    // hide content behind loader
    this.mainListUnregister = this.mainListData$.didChange(() => {
      this.hideLoader();
    });

    const debouncedSearch = debounce(async (textToSearch: string, isCancel: boolean) => {
      if (isCancel) return;
      //this.showLoader(); // display loader here if you want to display the loader only when the search API is actually called
      if (this.textToSearch$.value.length > 0) {
        this._fetchSourcesSearch(textToSearch);
      } else {
        this._fetchSources();
      }
    }, 1000);

    this.textToSearchUnregister = this.textToSearch$.didChange(textToSearch => {
      this.showLoader(); // display loader here if you want to display the loader anytime the user enter a new letter
      if (textToSearch.length > 0) {
        debouncedSearch(textToSearch, false);
      } else {
        debouncedSearch(textToSearch, true);
        this.defaultSearch && this.setSources(this.defaultSearch);
      }
    });
  }

  private setSources = (source: ItemCollection) => {
    const sourceList: searchTabListItemType[] = [];
    if (Array.isArray(source.items)) {
      source.items.forEach(itemCollection => {
        if (itemCollection instanceof ItemCollection) {
          if (itemCollection.type == "channels") {
            const validChannelUrls = [
              "france-2",
              "france-3",
              "france-4",
              "france-5",
              "slash",
              "la1ere",
              "franceinfo",
              "paris-h24",
            ];
            if (Array.isArray(itemCollection.items)) {
              itemCollection.items = itemCollection.items.filter(
                (element: any) => element instanceof Channel && validChannelUrls.includes(element.extras?.channel_url)
              );
            }
          } else if (itemCollection.type == "contents") {
            itemCollection.title = "Les vidéos";
          } else if (itemCollection.type == "collections") {
            itemCollection.title = "Les collections";
          } else if (itemCollection.type == "programs") {
            itemCollection.title = "Les programmes & évènements";
          }
          sourceList.push(itemCollection);
        }
      });
    }
    if (sourceList.length == 0) {
      this.mainListData$.value = [
        searchTabListItemId.keyboard,
        searchTabListItemId.emptyResult,
        ...(this.defaultSearch === undefined ? [] : this.defaultSearch.items),
      ];
    } else this.mainListData$.value = [searchTabListItemId.keyboard, ...sourceList];
  };
  private showLoader = () => {
    if (this.loader == undefined) {
      this.loader = DOMHelper.createDivWithParent(this.rootElement, "SearchLoader");
    }
  };
  private hideLoader = () => {
    if (this.loader) {
      this.rootElement.removeChild(this.loader);
      this.loader = undefined;
    }
  };

  onRelease = () => {
    this.updateFocusUnregister?.();
    this.textToSearchUnregister?.();
  };
}
