const _userAgent = navigator.userAgent;

export const OrangeDeviceInfo = {
  modelName: _userAgent
    .slice()
    .split(" ")
    .find(item => item.includes("Model"))
    ?.split("/")[1],
  version: _userAgent
    .slice()
    .split(" ")
    .find(item => item.includes("Firmware"))
    ?.split("/")[1],
  isNewBox: (): boolean => {
    return OrangeDeviceInfo.modelName === "IHD92";
  },
} as const;

export const OrangeEnvironmentInfo = () => {
  try {
    for (const param of decodeURI(window.location.search).replace("?", "").split("&")) {
      const [key, value] = param.split("=");
      if (
        key === "env" &&
        (value === "IPTV_EZ" || value === "IPTV_Fiber" || value === "IPTV_EZ_DOM" || value === "IPTV_Fiber_DOM")
      ) {
        return value;
      }
    }
    return "";
  } catch (error) {
    Log.app.log("[deepLinkOrange] failed decoding location.search", window.location.search, error);
    return "";
  }
};
