export type OrangeProviderId =
  | "FR2RNG"
  | "FR3RNG"
  | "FR4RNG"
  | "FR5RNG"
  | "FRINFO"
  | "FRORNG"
  | "SLASHRNG"
  | "OKOORNG"
  | "REGIONSRNG"
  | "FTVRNG";

export const DEFAULT_ORANGE_PROVIDER_ID = "FTVRNG";
