import { DRMData, IPlayerTracks, Player } from "~libs/player";
import { DOMHelper } from "~ui-lib";

import { IYouboraAdapter, YouboraManager } from "../../tools/analytics/youbora";

export interface AudioTrack {
  enabled: boolean;
  id: string;
  kind: string;
  label: string;
  language: string;
  sourceBuffer: SourceBuffer;
}

export interface AudioTrackList {
  readonly length: number;
  [index: number]: AudioTrack;
}

export class PlayerHTML5 extends Player {
  protected _youboraManager: YouboraManager<IYouboraAdapter>;
  private _textTracks?: TextTrackList;
  private _audioTracks?: AudioTrackList;

  constructor(initializeYoubora: boolean) {
    super(initializeYoubora);

    this._youboraManager = new YouboraManager(this);
  }

  init(): void {
    Log.player.log("[HTML5] init");
    this.videoElement = DOMHelper.createElementWithParent("video", this.DOMElement, "playerVideo");
    this.sourceElement = DOMHelper.createSourceWithParent(this.videoElement);

    super.init();
  }

  /**
   * Init listeners
   */

  initListener(): void {
    const that = this;

    this.videoElement.ontimeupdate = () => {
      that.currentTime$.value = (that.videoElement as HTMLMediaElement).currentTime;

      if (that.duration$.value <= 0) {
        that.duration$.value = ~~(this.videoElement?.duration ? this.videoElement?.duration : 0);
      }

      // Ad Controller update
      that.onTimeUpdate(that.position(), that.duration() - that.position(), that.url$.value);
    };
    this.videoElement.onplaying = () => {
      that.onEventPlay();
    };
    this.videoElement.onpause = () => {
      that.onEventPause();
    };
    this.videoElement.onloadstart = () => {
      that.onEventConnect();
    };
    this.videoElement.onloadedmetadata = () => {};
    this.videoElement.onended = () => {
      that.onEventFinish();
    };
    (this.sourceElement as HTMLElement).onerror = e => {
      that.onEventError(e);
    };

    super.initListener();
  }

  /**
   * load source url and play
   */
  load(url: string, drmData?: DRMData): void {
    if (url === undefined) return;

    console.error(url);

    this.videoElement?.removeAttribute("src");

    this.videoElement?.setAttribute("preload", "auto");
    this.videoElement?.setAttribute("src", url);

    this.sourceElement?.setAttribute("type", "video/mp4");
    this.sourceElement?.setAttribute("src", url);

    this.launchPlay();

    this._textTracks = this.videoElement["textTracks"];
    this._audioTracks = this.videoElement["audioTracks"];
  }

  launchPlay(): void {
    if (this.shouldPlayVideo()) {
      this.play();
    }
  }

  /**
   * Play
   */
  play(): void {
    const playPromise = (this.videoElement as HTMLMediaElement).play();

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(e => {
          // Auto-play was prevented
          // Show paused UI.
          Log.player.error("[HTML5][playPromise] ERROR :: " + e.message);
        });
    }
  }

  /**
   * Pause
   */
  pause(): void {
    if (this.shouldPauseVideo()) {
      (this.videoElement as HTMLMediaElement).pause();
    }
  }

  /**
   * Stop
   */
  stop(): void {
    (this.videoElement as HTMLMediaElement).src = "";
  }

  /**
   * Seek
   */
  seek(): void {
    if (this.shouldSeekVideo()) {
      this.onEventSeeking();

      const posSeek = this.position();
      (this.videoElement as HTMLMediaElement).currentTime = posSeek;

      this.play();
    }
  }

  /**
   * Deinit player
   */

  release(): void {
    super.release();

    this.videoElement?.remove();
    this.sourceElement?.remove();
  }

  getAudiosAndSubtitles = (): IPlayerTracks => {
    const tracks: IPlayerTracks = {
      audios: [],
      subtitles: [],
    };

    const textTracks = this._textTracks;
    const audioTracks = this._audioTracks;

    // Subtitles
    if (textTracks !== undefined) {
      Object.keys(textTracks).forEach((key, index) => {
        if (textTracks[index] instanceof TextTrack && textTracks[index].kind == "subtitles") {
          const subtitleTrack: TextTrack = textTracks[index];

          tracks.subtitles.push({
            id: typeof subtitleTrack.id === "string" && subtitleTrack.id !== "" ? subtitleTrack.id : index + "",
            index,
            language: subtitleTrack.language,
            enabled: subtitleTrack.mode === "showing",
            type: "subtitle",
          });
        }
      });
    }

    // Audio tracks
    for (let index = 0; audioTracks !== undefined && index < audioTracks.length; index++) {
      const audioTrack: AudioTrack = audioTracks[index];
      tracks.audios.push({
        id: audioTrack.id,
        index,
        language: audioTrack.language,
        enabled: audioTrack.enabled,
        type: "audio",
      });
    }

    return tracks;
  };

  hideAllSubtitles = () => {
    if (this._textTracks) {
      Object.keys(this._textTracks).forEach((key, index: number) => {
        const track: TextTrack | undefined = this._textTracks?.[index];
        if (track && track.kind === "subtitles") {
          track.mode = "disabled"; //TODO: may be hidden is better
        }
      });
    }
  };

  private _muteAllAudios = () => {
    const audioTracks: AudioTrackList | undefined = this._audioTracks;
    for (let index = 0; audioTracks !== undefined && index < audioTracks.length; index++) {
      audioTracks[index].enabled = false;
    }
  };

  setSubtitle = (index: number) => {
    const track: TextTrack | undefined = this._textTracks?.[index];
    if (track) {
      track.mode = "showing";
    }
  };

  setAudio = (index: number) => {
    this._muteAllAudios();
    const track: AudioTrack | undefined = this._audioTracks?.[index];
    if (track) {
      track.enabled = true;
    }
  };

  getVersion = () => {
    return "HTML5";
  };
}
