export enum MainMenuItemSlug {
  search = "search",
  home = "homepage",
  lives = "directs",
  favorites = "videos",
  accountSettings = "perso",
  categories = "categories",
  kids = "enfants",
  replayOrange = "replay_orange",
  custom = "custom",
}

export type ItemImages = {
  iconOn: string | null;
  iconOff: string | null;
  focusOn: string | null;
  focusOff: string | null;
};

export type ItemMenu = {
  id: string;
  label: string;
  order: number;
  link: string;
  slug: MainMenuItemSlug;
  visible: boolean;
};
