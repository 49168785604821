import "./partnersPage.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "../../../../bud-lite-tv/src/lib";
import { Plugin } from "../../../datas/plugin";
import { navigationStack } from "../../../main";
import { sendPianoAnalytic } from "../../../tools/analytics/piano";
import { AppVendors, Didomi, Position } from "../../../tools/cmp/didomi";
import { DidomiStub } from "../../../tools/cmp/didomiStub";
import { ButtonCmp } from "../../../views/cmp/cmpButton/buttonCmp";
import { CmpMenu, CmpMenuText } from "../../../views/cmp/cmpMenu/cmpMenu";
import { MainMenuItemSlug } from "../../../views/mainMenu/itemMenu";
import { HomeTab } from "../../home/homeTab";
import { pushConnexionPage, pushTabWithMenu } from "../../rootPage";
import { ParametersTab } from "../parameters/parametersPage";
import { PrivatePolicyTab } from "../privacyPolicy/privacyPolicy";
import { Partners, PartnersList } from "./partnersList";
export class PartnersTab extends View {
  private _partnersList: Partners[];

  private readonly itemCmpMenu: ButtonCmp[] = [
    {
      id: 0,
      width: 371,
      text: CmpMenuText.saveChoice,
      onPress: () => {
        const vendors: AppVendors = {
          enabled: [],
          disabled: [],
        };

        for (const vendor of this._partnersList) {
          if (vendor.id !== "All") {
            vendors[vendor.state === Position.allow ? "enabled" : "disabled"].push(vendor.id);
          }
        }

        void Didomi.updateUserConsents({
          vendors,
        });
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_partenaires",
            click: "enregistrer",
            cmp_analytics: Didomi.isPurposeEnabled("mesureda-LjJ4eyi4") ? "true" : "false",
            cmp_custom_content: "false",
          },
          {}
        );
        this._exit();
      },
    },
    {
      id: 1,
      width: 316,
      text: CmpMenuText.acceptAll,
      onPress: () => {
        Didomi.enableAll();
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_parametrer",
            click: "tout_accepter",
            cmp_analytics: "true",
            cmp_custom_content: "true",
          },
          {}
        );
        this._exit();
      },
    },
    {
      id: 2,
      width: 288,
      text: CmpMenuText.rejectAll,
      onPress: () => {
        Didomi.disableAll();
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_parametrer",
            click: "tout_refuser",
            cmp_analytics: "false",
            cmp_custom_content: "false",
          },
          {}
        );
        this._exit();
      },
    },
    {
      id: 3,
      width: 513,
      text: CmpMenuText.privacyPolicy,
      onPress: () => navigationStack.pushPage(new PrivatePolicyTab()),
    },
    {
      id: 4,
      width: 407,
      text: CmpMenuText.myConsent,
      onPress: () => navigationStack.pushPage(new ParametersTab()),
    },
  ];

  constructor() {
    super(DOMHelper.createDivWithParent(null));
    this._partnersList = this.createPartnersList();

    DOMHelper.createDivWithParent(
      this.rootElement,
      "TitlePartners",
      null,
      "Sélectionner les partenaires pour France TV"
    );
    DOMHelper.createDivWithParent(
      this.rootElement,
      "TextPartners",
      null,
      "Vous pouvez paramétrer votre consentement pour chaque partenaire listé ci-dessous individuellement ou pour l'ensemble des partenaires en cliquant sur les interrupteurs correspondants. La position grise exprime le refus de consentement (les traceurs sont désactivés), la position verte exprime le consentement (les traceurs sont activés). Sans action de votre part les traceurs sont désactivés."
    );
    this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource<{ id: "partners"; data: Partners[] } | { id: "menu"; data: ButtonCmp[] }>([
          { id: "partners", data: this._partnersList },
          { id: "menu", data: this.itemCmpMenu },
        ]),
        viewFactory: model => {
          if (model.id === "partners") {
            return new PartnersList(this._partnersList);
          } else {
            return new CmpMenu(model.data);
          }
        },
        horizontal: true,
        pageSize: 2,
        visibleBefore: 1,
        visibleAfter: 1,
        spatialFocus: false,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }

  private createPartnersList = () => {
    const partnersList: Partners[] = [];
    const vendorsList = DidomiStub.cmp.vendors;

    if (vendorsList?.length) {
      vendorsList.forEach(vendor => {
        partnersList.push({
          id: vendor.vendor_id,
          state: Didomi.isVendorEnabled(vendor.vendor_id) ? Position.allow : Position.denied,
          title: vendor.vendor_title,
        });
      });
    }
    return partnersList;
  };

  onShown() {
    sendPianoAnalytic(
      "publisher.impression",
      {
        feature: "cmp_partenaires",
      },
      {}
    );
  }

  private _exit = () => {
    navigationStack.destroyStack();
    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
    } else {
      pushConnexionPage();
    }
  };
}
