import { z } from "zod";

import { OrangeDeviceInfo } from "./orangeDeviceInfo";

export const OrangeError = z.object({
  version: z.string(),
  timestamp: z.number().int(),
  partnerId: z.string(),
  errorCode: z.string(),
  orangeErrorCode: z.number().int(),
  message: z.string().optional(),
  description: z.string().optional(),
  device: z.object({
    model: z.string().optional(),
    version: z.string().optional(),
  }),
  popUp: z
    .object({
      code: z.string(),
      message: z.string(),
    })
    .optional(),
});

export type OrangeError = z.infer<typeof OrangeError>;

const _commonOrangeErrorParams = {
  version: "1.0",
  partnerId: "FTV",
  device: {
    model: OrangeDeviceInfo.modelName,
    version: OrangeDeviceInfo.version,
  },
};

export const orangeErrorBuildMagnetoLoading = (): OrangeError => {
  return orangeErrorBuild({
    errorCode: "H03-FTV",
    orangeErrorCode: 99,
    message: "PLAYER_UNAVAILABLE",
    description: "Problème lié à la lecture des contenus dans le service partenaire lors de l'initialisation du player",
    popUp: {
      code: "H03-FTV",
      message:
        "Accès au programme momentanément indisponible. Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner. Il est possible que ce programme ne soit plus disponible. Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche. Code erreur : H03-FTV",
    },
  });
};

export const orangeErrorBuild = (params: {
  errorCode: string;
  orangeErrorCode: number;
  message?: string;
  description?: string;
  popUp: {
    code: string;
    message: string;
  };
}): OrangeError => {
  return {
    ..._commonOrangeErrorParams,
    timestamp: Date.now(),
    ...params,
  };
};

const TAG_URL = "__URL__";

const _statusCodeOrangeErrorAPIContentMap = {
  [404]: {
    errorCode: "error404",
    message: "CONTENT_NOT_FOUND",
    description: `Problème lié à la navigation lors de l'utilisation du service partenaire - Page indisponible - ${TAG_URL}`,
    popUp: {
      code: "H02-FTV",
      message:
        "Accès au contenu momentanément indisponible. Un problème technique ne permet pas d’accéder au contenu que vous souhaitez visionner. Il est possible que ce contenu ne soit plus disponible. Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H02 dans le moteur de recherche. Code erreur : H02-FTV",
    },
  },
  [500]: {
    errorCode: "error500",
    message: "SERVICE_UNAVAILABLE",
    description: `Problème lié à la navigation lors de l'utilisation du service partenaire - Service indisponible - ${TAG_URL}`,
    popUp: {
      code: "H02-FTV",
      message:
        "Accès au service momentanément indisponible. Un problème technique ne permet pas d’accéder au service que vous souhaitez visionner. Il est possible que ce service ne soit plus disponible. Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H02 dans le moteur de recherche. Code erreur : H02-FTV",
    },
  },
};

export const orangeErrorAPIContentBuild = (params: { url: string; statusCode: 404 | 500 }): OrangeError => {
  const statusCodeParams = _statusCodeOrangeErrorAPIContentMap[params.statusCode];
  return orangeErrorBuild({
    orangeErrorCode: 20,
    errorCode: statusCodeParams.errorCode,
    message: statusCodeParams.message,
    description: statusCodeParams.description.replace(TAG_URL, params.url),
    popUp: statusCodeParams.popUp,
  });
};
