import "./cguPage.scss";

import { CGU, CGUType } from "~models/cgu";
import { Plugin } from "~pages/../datas/plugin";
import { DOMHelper, IPage, Keys, throttle } from "~ui-lib";

import { sendOrangePageViewEvent } from "../../tools/analytics/orangeStats";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { DEFAULT_ORANGE_PROVIDER_ID } from "../../tools/apiOrange/orangeProviderId";

const cguSource = async (parent: HTMLElement, text: string) => {
  const termsInfo = text;
  const newElement = document.createElement("div");
  newElement.innerHTML = termsInfo;
  parent.appendChild(newElement);
};

const scrollsize = (rootHeight: number, scrollHeight: number) => {
  const numberscroll = rootHeight / (1080 / 3);
  const ret = scrollHeight / Math.floor(numberscroll);
  return ret;
};

export class CGUPage implements IPage {
  rootElement: HTMLElement;
  textBox: HTMLElement;
  scrollRoot: HTMLElement;
  scroll: HTMLElement;
  offset = 0;
  offsetScroll = 0;
  sizeScroll = 0;
  private sourceType;

  constructor(source: CGU) {
    this.rootElement = DOMHelper.createDivWithParent(null, null, "cguPageRoot");
    const wheelHandler = (ev: WheelEvent): void => {
      this.termsNav(ev.deltaY < 0);
    };
    this.rootElement.addEventListener("wheel", throttle(wheelHandler, 200).func);

    this.textBox = DOMHelper.createDivWithParent(this.rootElement, null, "textBox");
    // DOMHelper.createDivWithParent(this.textBox, null, "textCGU", source);
    this.scrollRoot = DOMHelper.createDivWithParent(this.rootElement, null, "scrollRoot");
    this.scroll = DOMHelper.createDivWithParent(this.scrollRoot, null, "scroll");

    // cguSource(this.textBox, source).then(res => {
    //   this.sizeScroll = scrollsize(this.textBox.clientHeight, this.scrollRoot.clientHeight - this.scroll.clientHeight);
    // });
    this.sourceType = source.type;
    switch (source.type) {
      case CGUType.cmp:
        this._fetchSourceCGU();
        break;

      case CGUType.mentionsLegales:
        this._fetchSourceMention();
        break;

      default:
        this._fetchSourceCGU();
        break;
    }
  }

  onShown() {
    sendPianoAnalytic(
      "page.display",
      { page: this.sourceType == CGUType.cgu ? "cgu" : "mentions_legales", page_type: "compte" },
      {}
    );
    sendOrangePageViewEvent({
      pTitle: this.sourceType == CGUType.cgu ? "cgu" : "mentions_legales",
      providerId: DEFAULT_ORANGE_PROVIDER_ID,
    });
  }

  private _fetchSourceCGU() {
    Plugin.getInstance()
      .fetchCGU()
      .subscribe(
        value => {
          // Here use it to create the UI
          console.log("[DETAILED] Next !", value);
          cguSource(this.textBox, value[0].resources.cgu).then(res => {
            this.sizeScroll = scrollsize(
              this.textBox.clientHeight,
              this.scrollRoot.clientHeight - this.scroll.clientHeight
            );
          });
          // this._onSourceReady(value[0].items);
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[DETAILED] Error !", error);
        },
        () => {
          console.log("[DETAILED] Complete !");
        }
      );
  }

  private _fetchSourceMention() {
    Plugin.getInstance()
      .fetchMentionLegale()
      .subscribe(
        value => {
          // Here use it to create the UI
          console.log("[DETAILED] Next !", value);
          cguSource(this.textBox, value[0].resources.mention_legal).then(res => {
            this.sizeScroll = scrollsize(
              this.textBox.clientHeight,
              this.scrollRoot.clientHeight - this.scroll.clientHeight
            );
          });
          // this._onSourceReady(value[0].items);
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[DETAILED] Error !", error);
        },
        () => {
          console.log("[DETAILED] Complete !");
        }
      );
  }

  // public onShown() {
  //   this.onFocused();
  // }

  // public onHidden() {
  //   this.rootElement?.parentElement?.removeChild(this.rootElement);
  //   this.scrollRoot?.parentElement?.removeChild(this.scrollRoot);
  // }

  public termsNav(up: boolean) {
    let offset = this.offset;
    let offsetScroll = this.offsetScroll;
    if (up) {
      offsetScroll -= this.sizeScroll;
      offset += 0.5 * 720;
    } else {
      offsetScroll += this.sizeScroll;
      offset -= 0.5 * 720;
    }
    if (offset >= -this.textBox.clientHeight && offset <= 0) {
      this.offset = offset;
      this.offsetScroll = offsetScroll;
      this.textBox.style.marginTop = `${this.offset}px`;
      this.scroll.style.marginTop = `${this.offsetScroll}px`;
    }
  }

  // forward all nav & focus to the list component
  onNav = (key: Keys) => {
    switch (key) {
      // case DS.keys.enter:
      //   return false;
      case Keys.down:
        this.termsNav(false);
        return true;
      case Keys.up:
        this.termsNav(true);
        return true;
      // case DS.keys.left:
      //   DOMHelper.removeClass(this.scroll, "focus");
      //   return false;

      default:
        return false; //this.listComponent.onNav(key);
    }
  };
  // acceptsFocus = () => true;
  onFocused = () => {
    DOMHelper.addClass(this.scroll, "focus");
  };
  // onUnfocused = () => undefined;
}
