import "./accountTab.scss";

import { Plugin } from "~pages/../datas/plugin";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { AccountSwimlane, AccountSwimlaneConnected } from "~views/swimlanes/accountSwimlane";
import { SubscriptionSwimlane } from "~views/swimlanes/subscriptionSwimlane";

import { Config } from "../../config";
import { sendOrangePageViewEvent } from "../../tools/analytics/orangeStats";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { DEFAULT_ORANGE_PROVIDER_ID } from "../../tools/apiOrange/orangeProviderId";
import { magnetoVersion } from "../player/playerMagnetoPage";

export enum listAccount {
  politique = "policy",
  connexion = "logIn",
}

export class AccountTab extends View {
  listComponent: IListComponent<listAccount>;
  constructor() {
    super(DOMHelper.createDivWithParent(null, "AccountTab"));
    const isConnected = Plugin.getInstance().user.isActive();
    const source: listAccount[] = [listAccount.politique];
    Config.enabledFeatures.login && source.push(listAccount.connexion);
    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource(source),
        viewFactory: model => {
          // DON T use store var isConnected here, listComponent.resetContent can be called by PopupValidate, so we need to read the new value of user.isActive()
          if (Plugin.getInstance().user.isActive()) {
            if (model == listAccount.politique) {
              return new AccountSwimlaneConnected();
            } else {
              return new SubscriptionSwimlane(true, this.listComponent);
            }
          } else {
            if (model == listAccount.politique) {
              return new AccountSwimlane();
            } else {
              return new SubscriptionSwimlane(false, this.listComponent);
            }
          }
        },
        horizontal: false,
        pageSize: 2,
      },
      mainList => {
        mainList.setFocusOnId(isConnected ? listAccount.politique : listAccount.connexion);
      }
    );
    DOMHelper.createDivWithParent(
      this.rootElement,
      "NumberVersion",
      null,
      __APP_VERSION__ + (magnetoVersion() !== "" ? "-" + magnetoVersion() : "")
    );
  }

  onShown() {
    sendPianoAnalytic("page.display", { page: "votre_compte", page_type: "compte" }, {});
    sendOrangePageViewEvent({
      pTitle: "compte",
      providerId: DEFAULT_ORANGE_PROVIDER_ID,
    });
  }
}
