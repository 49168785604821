import { DRMData, IPlayerTracks, Player, PlayerState, Track } from "~libs/player";
import { IYouboraAdapter, YouboraManager } from "~tools/analytics/youbora";
import { DOMHelper } from "~ui-lib";

import { MediaInfo, MediaPlayerClass } from "../../../typings/dashjs";

export class PlayerDashJS extends Player {
  protected _youboraManager: YouboraManager<IYouboraAdapter>;
  protected player?: MediaPlayerClass;
  private _textTracks: Track[] = [];
  private _audioTracks: Track[] = [];
  private _drmData?: DRMData;

  private _defaultAudioTrackIndex?: number | undefined;
  private _defaultSubtitleTrackIndex?: number | undefined;
  private _adProgressInt: number | undefined;

  constructor(initializeYoubora: boolean) {
    super(initializeYoubora);

    this.player = window.dashjs.MediaPlayer().create();
    this._youboraManager = new YouboraManager(this);
  }

  private _getActiveTextTrack(list: TextTrackList) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].mode === "showing") {
        return list[i];
      }
    }
  }

  private _changeSubtitlesPosition() {
    if (!this.videoElement) return;
    const currentTrack = this._getActiveTextTrack(this.videoElement.textTracks);
    if (!currentTrack) return;

    const cues = currentTrack.activeCues;
    if (!(cues && cues.length)) return;

    const activeCue = cues[0];
    if (activeCue instanceof VTTCue && !activeCue.vertical) {
      if (activeCue.line === -1 || activeCue.line === "auto") {
        activeCue.line = -3;
      }
    }
  }

  init(): void {
    Log.player.log("[DASHJS] init");
    this.videoElement = DOMHelper.createElementWithParent("video", this.DOMElement, "playerVideo");
    this.videoElement.setAttribute("autoplay", "true");
    DOMHelper.createDivWithParent(this.DOMElement, "ttml-rendering-div");

    super.init();
  }

  /**
   * Init listeners
   */

  initListener(): void {
    this.videoElement.ontimeupdate = () => {
      this.currentTime$.value = (this.videoElement as HTMLMediaElement).currentTime;

      if (this.duration$.value <= 0) {
        this.duration$.value = ~~(this.videoElement?.duration ? this.videoElement?.duration : 0);
      }

      // Ad Controller update
      this.onTimeUpdate(this.position(), this.duration() - this.position(), this.url$.value);

      if (this.isMidRollPlaying() && this.player && this.player.isReady()) {
        this.player.reset();
      }

      // Workaround to prevent the ad from getting stuck at the end on the NewBox
      clearTimeout(this._adProgressInt);
      if (this.isAdVideoPlaying()) {
        this._adProgressInt = window.setTimeout(() => {
          const percent = Math.floor((this.currentTime$.value * 100) / this.duration$.value);
          if (percent >= 97) this.onEventFinish();
        }, 5000);
      }
    };
    this.videoElement.oncanplay = () => {
      if (!(this.player && this.player.isReady())) return;

      const currentSub = this.player.isTextEnabled() ? this.player.getCurrentTrackFor("text") : undefined;
      const currentAudio = this.player.getCurrentTrackFor("audio");

      this._textTracks = [];
      this.player.getTracksFor("text").forEach((track: MediaInfo, index: number) => {
        if (track.roles?.includes("subtitle") && track.index != null && track.lang) {
          this._textTracks.push({
            id: track.index,
            language: track.lang,
            enabled: currentSub ? track.index === currentSub.index : false,
            index,
            type: "subtitle",
            raw: track,
          });
        }
      });

      this._audioTracks = [];
      this.player.getTracksFor("audio").forEach((track: MediaInfo, index: number) => {
        track.lang &&
          this._audioTracks.push({
            id: index,
            language: track.lang,
            enabled: currentAudio ? track.index === currentAudio.index : false,
            index,
            type: "audio",
            raw: track,
          });
      });

      typeof this._defaultAudioTrackIndex === "number" && this.setAudio(this._defaultAudioTrackIndex);
      typeof this._defaultSubtitleTrackIndex === "number" && this.setSubtitle(this._defaultSubtitleTrackIndex);
    };
    this.videoElement.onplaying = () => {
      this.onEventPlay();
    };
    this.videoElement.onpause = () => {
      this.onEventPause();
    };
    this.videoElement.onloadstart = () => {
      this.onEventConnect();
    };
    this.videoElement.onloadedmetadata = () => {};
    this.videoElement.onended = () => {
      this.onEventFinish();
    };
    this.videoElement.onerror = (e: unknown) => {
      this.onEventError(e);
    };

    this.videoElement.textTracks.onchange = () => {
      const track = this._getActiveTextTrack(this.videoElement.textTracks);
      if (track) {
        track.oncuechange = this._changeSubtitlesPosition.bind(this);
      }
    };

    if (this.player) {
      this.player.on(window.dashjs.MediaPlayer.events.ERROR, e => {
        this.onEventError(e.error);
      });
      this.player.on(window.dashjs.MediaPlayer.events.PLAYBACK_ERROR, e => {
        this.onEventError(e.error);
      });
    }

    super.initListener();
  }

  /**
   * load source url and play
   */
  load(url: string, drmData?: DRMData): void {
    if (url === undefined || !this.player) throw new Error();
    Log.player.trace("[DASHJS] loading content:" + url);

    if (url.includes(".mp4")) {
      this.videoElement.src = url;
      this.videoElement.play();
    } else {
      this._drmData = drmData;
      this.player.updateSettings({
        debug: {
          logLevel: window.dashjs.LogLevel.LOG_LEVEL_WARNING,
        },
        streaming: {
          text: {
            defaultEnabled: false,
          },
          abr: {
            maxBitrate: { video: 2000 },
          },
        },
      });
      this.launchPlay();
    }
  }

  launchPlay(): void {
    if (this.shouldPlayVideo() && this.player) {
      this.player.initialize(this.videoElement, this.url$.value, true);

      const TTMLRenderingDiv = document.querySelector("#ttml-rendering-div");
      TTMLRenderingDiv && this.player.attachTTMLRenderingDiv(<HTMLDivElement>TTMLRenderingDiv);

      if (this._drmData) {
        const keySystem = this._drmData.drm_type === "playready" ? "com.microsoft.playready" : "com.widevine.alpha";
        // this.player.setProtectionData({
        //   [keySystem]: {
        //     serverURL: this._drmData.serverURL,
        //     // audioRobustness: "SW_SECURE_CRYPTO",
        //     // videoRobustness: "HW_SECURE_ALL",
        //   },
        // });
      }
    }
  }

  /**
   * Play
   */
  play(): void {
    if (this.state$.value !== PlayerState.PLAYING) {
      (this.videoElement as HTMLMediaElement).play();
    }
  }

  /**
   * Pause
   */
  pause(): void {
    if (this.shouldPauseVideo()) {
      (this.videoElement as HTMLMediaElement).pause();
    }
  }

  /**
   * Stop
   */
  stop(): void {
    (this.videoElement as HTMLMediaElement).src = "";
  }

  /**
   * Seek
   */
  seek(): void {
    if (this.shouldSeekVideo()) {
      this.onEventSeeking();

      (this.videoElement as HTMLMediaElement).currentTime = this.position();

      this.play();
    }
  }

  /**
   * Deinit player
   */

  release(): void {
    super.release();

    if (this.player) {
      try {
        this.player.destroy();
      } catch (e: unknown) {
        Log.app.error(e);
      }
      this.player = undefined;
    }
    this.videoElement?.remove();
    document.querySelector("#ttml-rendering-div")?.remove();
    clearTimeout(this._adProgressInt);
  }

  getAudiosAndSubtitles = (): IPlayerTracks => {
    return {
      audios: this._audioTracks ?? [],
      subtitles: this._textTracks ?? [],
    };
  };

  hideAllSubtitles = () => {
    this._defaultSubtitleTrackIndex = undefined;
    this.player?.enableText(false);
    this._textTracks?.forEach(track => {
      track.enabled = false;
    });
  };

  setSubtitle = (index: number) => {
    const track = this._textTracks?.[index];
    if (track && this.player) {
      this.player.setCurrentTrack(track.raw);
      this.player.enableText(true);
      track.enabled = true;
      this._defaultSubtitleTrackIndex = index;
    }
  };

  setAudio = (index: number) => {
    const track = this._audioTracks?.[index];
    if (track && this.player) {
      this._audioTracks.forEach(track => {
        track.enabled = false;
      });
      this.player.setCurrentTrack(track.raw);
      track.enabled = true;
      this._defaultAudioTrackIndex = index;
    }
  };

  getVersion = () => {
    return this.player?.getVersion() ?? "";
  };
}
