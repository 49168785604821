import "./playerPage.scss";

import { Subscription } from "rxjs";

import { AdController, AdControllerEvent, IAdBreak, IAdConfig } from "~libs/dotscreen-adc";
import { DRMData, Player, PlayerState } from "~libs/player";
import { PlayerDashJS } from "~libs/players/playerDashJS";
import { PlayerHTML5 } from "~libs/players/playerHTML5";
import { PlayerTizen } from "~libs/players/playerTizen";
import { PlayerControlList } from "~pages/player/playerControls";
import { PlayerErrorPage } from "~pages/player/playerErrorPage";
import { pushPlayerPage } from "~pages/rootPage";
import { sendOrangeEvent, sendOrangePageViewEvent, sendOrangeRecoEvent } from "~tools/analytics/orangeStats";
import { ApiOrange } from "~tools/apiOrange/apiOrange";
import { OrangeDeviceInfo } from "~tools/apiOrange/orangeDeviceInfo";
import { orangeErrorBuild } from "~tools/apiOrange/orangeError";
import {
  createListComponent,
  delay,
  DOMHelper,
  IListComponent,
  IPage,
  Keys,
  Listenable,
  platform,
  PlatformType,
  StaticModelSource,
  View,
} from "~ui-lib";

import { Config } from "../../config";
import { parseEdgescapeCountry } from "../../datas/parseEdgescapeCountry";
import { parseMarkerPianoPageDisplay, parseSpritesheets } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { ItemCollection } from "../../models/itemCollection";
import { PlayableItem } from "../../models/playableItem";
import { Spritesheet } from "../../models/spritesheet";
import { PlaylistVideoPlayerSwimlane } from "../../swimlaneViews/playlistVideoPlayerSwimlane";
import { EStat } from "../../tools/analytics/eStat";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { Spideo } from "../../tools/analytics/spideo";
import { getOrangeProviderIdFromBroadcastChannel } from "../../tools/apiOrange/channelOrangeProviderIdMap";
import { DEFAULT_ORANGE_PROVIDER_ID, OrangeProviderId } from "../../tools/apiOrange/orangeProviderId";
import { PlayHistoryHelper } from "../../tools/playerHistoryHelper";
import { checkImage } from "../../tools/tools";
import { LoaderView } from "../loader/loaderPage";
import { ButtonType, PlayerButtonList } from "./playerButtons";
import { SkipButtonStateValue } from "./playerSkipButton";

enum PlayerComponentType {
  controls = "controls",
  nextContent = "nextContent",
}

export let currentPlayer: Player | undefined = undefined;

const spritesheetHandler = (spritesheets: Spritesheet) => {
  return {
    ...spritesheets,
    images: spritesheets.images.map(image => {
      const spritesheetImage = new Image();

      spritesheetImage.onload = () => {
        // Refresh on load
        /**
         * Currently we are not doing anything special
         * when loading the spritesheet image.
         *
         * The main goal is to preload the image
         * to have it ready to display when we will display
         * the spritesheets while seeking.
         */
      };

      spritesheetImage.onerror = () => {
        // Refresh on error
        /**
         * Currently we are not doing anything special
         * when an error occurs during the preloading.
         */
      };

      spritesheetImage.src = image;

      return {
        source: image,
        image: spritesheetImage,
      };
    }),
  };
};

export type PlayerPageSpriteSheet = Omit<Spritesheet, "images"> & {
  images: { source: string; image: HTMLImageElement }[];
};

export type SkipButtonTimecodesObjectType = { [key: string]: { [key: string]: number } };

enum FeatureType {
  beforeAdBreak = "lancement_avant_pub",
  afterAdBreak = "lancement_apres_pub",
  noAdBreak = "lancement_sans_pub",
}

export class PlayerPage extends View implements IPage {
  eStat: EStat;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eStatData: any;
  orangeStatsData?: {
    providerId: OrangeProviderId;
    contentId: string;
    contentLabel: string;
    contentSubLabel: string;
  };
  isAdChange: () => void;
  isLaunchedChange: () => void;
  isLive$ = new Listenable<boolean>(false);
  item: PlayableItem;
  list: IListComponent | undefined;
  loader: LoaderView;
  nextContents?: ItemCollection[];
  private _index?: number; //
  private _nextToPlay?: PlayableItem;
  player: Player;
  playerStateUnregister: () => void;
  private _edgescapePromise: Promise<unknown>;
  private _subAds?: Subscription = undefined;
  private _subHasNext?: Subscription = undefined;
  private _subPlayer?: Subscription = undefined;
  private elementHtmlAdsDuration: HTMLElement | null = null;
  private intervalAdsDuration: NodeJS.Timeout | null = null;
  private spritesheets: PlayerPageSpriteSheet | undefined = undefined;
  private _progressInS?: number = undefined;
  uiVisibilityTimer?: number;
  uiVisible$ = new Listenable<boolean>(false);

  nextEpisodeButtonExists = false;
  skipButtonsTimecodes: SkipButtonTimecodesObjectType = {};
  private _isReleased = false;
  private _intervalSpideoDuration: number | undefined = undefined;
  private expectedAds = { break_ads_number_expected: 0, break_duration_expected: 0 };
  private displayedAds = { break_ads_number: 0, break_duration: 0 };
  private _beforepreRollPlayerPianoEventSent = false;
  private _afterpreRollPlayerPianoEventSent = false;
  private _spideoStartPlayingEventSent = false;
  private _seekTimeListenerForSpideoUnreg?: () => void;

  constructor(item: PlayableItem, progressPercent?: number, nextContents?: ItemCollection[], index?: number) {
    super(DOMHelper.createDivWithParent(null, "playerPage", "playerPage"));

    this.nextContents = nextContents;
    this.item = item;
    this._index = index;
    this._fetchMarkerPiano();
    this.isLive$.value = item?.metadata?.extras?.is_live ? true : false;

    this.eStat = new EStat();

    // manage progress
    const duration = item?.metadata?.duration;
    if (progressPercent !== undefined) {
      this._progressInS = duration * progressPercent;
    } else {
      const user = Plugin.getInstance().user;
      // don't fetch the progress if it is a live or user is not logged in
      if (this.isLive$.value === false && user.isActive()) {
        this._progressInS = PlayHistoryHelper.getCurrentOffset(this.item);
      }
    }
    if (this._progressInS !== undefined) {
      this._progressInS = Math.max(0, Math.min(duration, this._progressInS));
      if (duration <= 0 || this._progressInS <= 0 || this._progressInS > 0.96 * duration) {
        this._progressInS = undefined;
      }
    }

    // get next to play
    if (this.nextContents && this.nextContents.length) {
      this.nextContents[0]?.items.forEach((item: unknown, index: number) => {
        if (this.item == item) {
          this._nextToPlay = this.nextContents?.[0]?.items?.[index + 1];
        }
      });
    }

    // Show loader and start anim
    this.loader = new LoaderView();
    this.rootElement.appendChild(this.loader.rootElement);
    this.loader.start();

    // Active video defined in application background
    document.getElementById("videoBackground")?.classList.add("active");

    // Get Geographical location for GeoBlocking
    this._edgescapePromise = Plugin.getInstance().getEdgescapeJson().toPromise();

    this.player =
      platform.type === PlatformType.tizen
        ? new PlayerTizen(true)
        : OrangeDeviceInfo.isNewBox()
        ? new PlayerDashJS(true)
        : new PlayerHTML5(true);
    currentPlayer = this.player;

    this.playerStateUnregister = this.player.state$.didChange(state => {
      switch (state) {
        case PlayerState.BUFFERING:
          break;
        case PlayerState.ENDED:
          if (!this.player.isAdVideoPlaying()) {
            if (this.nextContents && this.nextContents[0] && this.player.isEnded()) {
              this.endVideo();
            } else {
              this.exitPlayer();
            }
          }
          break;
        case PlayerState.ERROR:
          this._onError("LOAD_CONTENT_ERROR");
          break;
        case PlayerState.IDLE:
          break;
        case PlayerState.PAUSED:
          window.clearTimeout(this.uiVisibilityTimer);
          if (this.player.isAdVideoPlaying() === false) {
            this._sendSpideoEvent("pause");
          }
          break;
        case PlayerState.PLAYING:
          if (this._spideoStartPlayingEventSent === false && this.player.isAdVideoPlaying() === false) {
            this._sendSpideoEvent("startPlaying");
            this._spideoStartPlayingEventSent = true;
            this._initSpideoTimer();
          }
          this.showPlayerUI();
          if (!this.player.isAdVideoPlaying() && this.eStat.isPauseMidroll) {
            this.player.sendEStat(PlayerState.PLAYING);
          }
          break;
        case PlayerState.PREPARING:
          break;
        case PlayerState.UNSET:
          break;
        case PlayerState.SEEKING:
          window.clearTimeout(this.uiVisibilityTimer);
          break;
        default:
          Log.player.warn("Unknown player state :: ", state);
          break;
      }
    });

    this.isAdChange = this.player.adPlaying$.didChange(isPlaying => {
      if (isPlaying) {
        // if (window.plugin) {
        //   window.plugin?.getAdsAdapter()?.fireBreakStart();
        // }
        const view = this.list?.viewFromIndex(this.list.focusedIndex$.value);
        if (view instanceof PlayerControlList) {
          const viewButton = view.list?.viewFromIndex(view.list.focusedIndex$.value);
          if (viewButton instanceof PlayerButtonList) {
            viewButton.removeAllButtons();
          }
        }
        this.hidePlayerUI();
      } else {
        // if (window.plugin) {
        //   window.plugin?.getAdsAdapter()?.fireBreakStop();
        // }
        (async () => {
          await delay(200);
          this.showPlayerUI();
        })();
      }
    });

    this.isLaunchedChange = this.player.videoLaunched$.didChange(isLaunched => {
      if (isLaunched) {
        if (this.eStatData && this.eStatData.serial) {
          this.eStat.auth(this.eStatData.serial, () =>
            this.eStat.sendState(
              this.eStatData,
              PlayerState.PLAYING,
              this.player.currentTime$.value,
              this.item.id,
              this.player.adPlaying$
            )
          );
        }

        this.eStat.createPolling(
          this.eStatData,
          this.player.currentTime$,
          this.item.id,
          this.player.state$,
          this.player.adPlaying$
        );
        this.player.sendEStat = (playerState: PlayerState) => {
          this.eStat.sendState(
            this.eStatData,
            playerState,
            this.player.seekTime$.value !== undefined ? this.player.seekTime$.value : this.player.currentTime$.value,
            this.item.id,
            this.player.adPlaying$
          );
        };
      }
    });
  }

  endVideo = () => {
    if (this._nextToPlay) {
      pushPlayerPage(
        this._nextToPlay,
        undefined,
        (this.list?.viewFromId(this.nextContents?.[0]?.id) as PlaylistVideoPlayerSwimlane)?.getProgressPercentFromId(
          this._nextToPlay.id
        ),
        this.nextContents
      );
    }
    this.exitPlayer();
    if (this._nextToPlay) {
      // must display element videoBackground deactivate when player is closed
      const DOMVideo = document.getElementById("videoBackground");
      if (DOMVideo) {
        DOMVideo.classList.add("active");
        DOMVideo.hidden = false;
      }
    }
  };

  onShown() {
    this._sendPianoAnalyticsPageDisplay();
  }

  // mechanizme to fetch markerPiano only one time
  private _fetchMarkerPianoPromise?: Promise<NonNullable<ReturnType<typeof parseMarkerPianoPageDisplay>>>;
  private _fetchMarkerPiano = () => {
    if (this._fetchMarkerPianoPromise === undefined) {
      return (this._fetchMarkerPianoPromise = new Promise((resolve, reject) => {
        const markerPiano = parseMarkerPianoPageDisplay(this.item.extras);
        if (markerPiano === undefined) {
          Plugin.getInstance()
            .fetchDetailed(this.item)
            .subscribe(value => {
              const newMarkerPiano = parseMarkerPianoPageDisplay(value?.[0]?.extras);
              if (newMarkerPiano === undefined) {
                Log.analytics.error("Failed to send piano analytics");
              } else {
                resolve(newMarkerPiano);
              }
            }, reject);
        } else {
          resolve(markerPiano);
        }
      }));
    } else {
      return this._fetchMarkerPianoPromise;
    }
  };

  private _sendPianoAnalyticsPageDisplay = () => {
    this._fetchMarkerPiano()
      .then(markerPiano => {
        sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
      })
      .catch((e: unknown) => {
        Log.analytics.error("Failed to send piano analytics", e);
      });
  };

  private _sendPianoAnalyticsPlayerEvent = (feature: FeatureType) => {
    this._fetchMarkerPiano()
      .then(markerPiano => {
        const player_version = this.player.getVersion();
        const position = this._index !== undefined ? this._index + 1 : 1;

        const commonProperties: Record<string, string | boolean | number> = {
          ...markerPiano.additional_properties,
          feature,
          player_version,
          position,
          ...this.expectedAds,
        };
        if (this.item?.itemCollection?.type !== undefined) {
          commonProperties["zone"] = this.item.itemCollection.type;
        }

        if (feature === FeatureType.beforeAdBreak) {
          sendPianoAnalytic("player.start", {}, commonProperties);
        } else {
          const startContentProperties = {
            langue_demarrage: "defaut_fr-francais",
            sous_titres_demarrage: "defaut_null-aucun",
            vitesse_demarrage: "defaut_1",
            qualite_demarrage: "defaut_automatique",
            accessibilite_demarrage: "disabled",
          };

          if (feature === FeatureType.afterAdBreak) {
            sendPianoAnalytic(
              "player.start",
              {},
              {
                ...commonProperties,
                ...this.displayedAds,
                ...startContentProperties,
              }
            );
          }
          if (feature === FeatureType.noAdBreak) {
            // TODO: maybe there are several causes (adBlocked or noAds)
            // this.item.extras.ads_blocked || this.expectedAds.break_ads_number_expected === 0
            const cause = "pas_de_publicite_renvoyee";
            sendPianoAnalytic(
              "player.start",
              {},
              {
                ...commonProperties,
                ...this.displayedAds,
                ...startContentProperties,
                cause,
              }
            );
          }
        }
      })
      .catch((e: unknown) => {
        Log.analytics.error("Failed to send piano analytics", e);
      });
  };

  private _calculateAdNumberAndDuration = (adBreak: any) => {
    if (adBreak["vmap:AdSource"] && adBreak["vmap:AdSource"].length) {
      adBreak = adBreak["vmap:AdSource"][0];
      if (adBreak["vmap:VASTAdData"] && adBreak["vmap:VASTAdData"].length) {
        adBreak = adBreak["vmap:VASTAdData"][0];
        if (adBreak["VAST"] && adBreak["VAST"].length) {
          adBreak = adBreak["VAST"][0];
          if (adBreak["Ad"] && adBreak["Ad"].length) {
            Log.ads.log("Nb ads : " + adBreak["Ad"].length);
            this.expectedAds.break_ads_number_expected = adBreak["Ad"].length;
            adBreak["Ad"].forEach((ad: any) => {
              this.expectedAds.break_duration_expected += this._convertTimeToSeconds(
                ad.InLine[0].Creatives[0].Creative[0].Linear[0].Duration[0].$
              );
            });
          } else {
            Log.ads.error("VAST but no Ad");
          }
        }
      }
    }
  };

  private _convertTimeToSeconds = (time: string): number => {
    const hours = Number(time.slice(0, 2));
    const minutes = Number(time.slice(3, 5));
    const seconds = Number(time.slice(-2));

    const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalTimeInSeconds;
  };

  private _createProgressBarNextVideo = () => {
    if (!this.nextEpisodeButtonExists) {
      const box = DOMHelper.createDivWithParent(this.rootElement, "ItemVideoViewBox", "ItemVideoViewBox");
      if (this._nextToPlay) {
        const imgUrl = this._nextToPlay.getLandscapeTileImgUrl();
        if (imgUrl.length) {
          const elementImg = DOMHelper.createDivImg(box, null, "elementImg");
          checkImage(
            imgUrl,
            () => {
              elementImg.style.background = `url("${imgUrl}") no-repeat`;
              elementImg.style.backgroundSize = "100%";
              const NextContentProgressBarBox = DOMHelper.createDivWithParent(
                box,
                "NextContentProgressBarBox",
                "NextContentProgressBarBox"
              );
              const NextContentProgressBarPercent = DOMHelper.createDivWithParent(
                NextContentProgressBarBox,
                "NextContentProgressBarPercent",
                "NextContentProgressBarPercent"
              );
              DOMHelper.createDivWithParent(
                NextContentProgressBarPercent,
                null,
                "titleNextContent",
                this._nextToPlay?.extras.program.title || ""
              );
              DOMHelper.createDivWithParent(
                NextContentProgressBarPercent,
                null,
                "subtitleNextContent",
                this._nextToPlay?.extras.episode_title || ""
              );
            },
            () => {}
          );
        }
      }
    }
  };

  init = (): void => {
    // Ads control pre/mid/post rolls and play content accordingly
    this.adController();
  };

  createListPlayer(current: any /*, value: any*/) {
    let hasContent = false;
    if (this.nextContents && this.nextContents[0]?.items && this.nextContents[0].items.length) {
      hasContent = true;
      this.nextContents.sort((a: ItemCollection, b: ItemCollection) => {
        // Here we sort the swimlanes
        if (Array.isArray(a.items) && Array.isArray(b.items)) {
          // first the swimlane that include the live that the page currently play
          if (a.items.find(item => this.item.id == item.id)) return -1;
          if (b.items.find(item => this.item.id == item.id)) return 1;
          // then the live channel swmilane
          if (a.type == "playlist_channel") return -1;
          if (b.type == "playlist_channel") return 1;
          // then the others ordering by length
          return a.items.length > b.items.length ? -1 : 1;
        } else {
          return 1;
        }
      });
    }

    const uiContainer = DOMHelper.createDivWithParent(
      this.rootElement,
      "uiContainer",
      "uiContainer" + (hasContent ? " hasContent" : "") + (this.isLive$.value ? " live" : "")
    );
    this.delegate = this.list = createListComponent(
      {
        rootElement: uiContainer,
        modelSource: new StaticModelSource(
          hasContent ? [PlayerComponentType.controls, ...this.nextContents!] : [PlayerComponentType.controls]
        ),
        viewFactory: model => {
          switch (model) {
            case PlayerComponentType.controls:
              return new PlayerControlList(
                {
                  isLive$: this.isLive$,
                  uiVisible$: this.uiVisible$,
                  player: this.player,
                  item: this.item,
                  exitPlayer: this.exitPlayer,
                  skipButtonsTimecodes: this.skipButtonsTimecodes,
                  endVideo: this.endVideo.bind(this),
                },
                this.item,
                this.spritesheets
              );
          }
          return new PlaylistVideoPlayerSwimlane(
            model as ItemCollection,
            this.item.id,
            this,
            this.item.metadata.extras.is_live,
            this.nextContents
          );
        },
        pageSize: 1,
        visibleAfter: 1,
        mouseFocusInPageOnly: true,
        horizontal: false,
      },
      () => {
        Log.player.info("Ending loader animation");
        this.loader.end(platform.type !== PlatformType.orange, () => {
          //current.video.url = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

          this.rootElement.removeChild(this.loader.rootElement);
          this.loadSrc(
            current.video.url,
            current.video.drm ? { drm_type: current.video.drm_type, serverURL: current.video.token } : undefined
          );
          this.eStatData = current.markers.estat;
        });
      }
    );
  }

  playContent = (callbackAdController?: (url: string) => void): void => {
    // Example to fetch replay url
    const _fetchPlayer = (countryCode: string | undefined) => {
      this._subPlayer = Plugin.getInstance()
        .fetchPlayer(this.item, countryCode)
        .subscribe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (value: any) => {
            if (OrangeDeviceInfo.isNewBox() && value?.video?.format !== "dash") {
              const playerErrorPage = new PlayerErrorPage(
                "Ce programme n’est pas disponible sur votre box TV.\nRetrouvez-le sur le site et l’application france.tv.",
                () => {
                  navigationStack.removePage(playerErrorPage);
                },
                false
              );
              navigationStack.pushPage(playerErrorPage);
              navigationStack.removePage(this);
              return;
            }

            const parsedSpritesheets = parseSpritesheets(value?.video?.spritesheets ?? []);
            this.spritesheets = parsedSpritesheets !== undefined ? spritesheetHandler(parsedSpritesheets) : undefined;

            if (this.item.extras.ads_blocked || this.expectedAds.break_ads_number_expected === 0) {
              this._sendPianoAnalyticsPlayerEvent(FeatureType.noAdBreak);
            }

            this.nextEpisodeButtonExists = this.isObjectComplete(value.video.coming_next);
            if (this.nextEpisodeButtonExists && this._nextToPlay) {
              this.buildSkipButtonsObject(value.video.coming_next, "coming_next");
            }
            if (this.isObjectComplete(value.video.previously)) {
              this.buildSkipButtonsObject(value.video.previously, "previously");
            }
            if (this.isObjectComplete(value.video.skip_intro)) {
              this.buildSkipButtonsObject(value.video.skip_intro, "skip_intro");
            }
            Log.player.log("[PLAYER] Next !", value);

            this.player.initYouboraVideo({
              "content.title": value.markers.npaw.title,
              "content.program": value.markers.npaw.program,
              "content.duration": value.video.duration,
              "content.isLive": value.video.is_live,
              "content.season": value.markers.npaw.season,
              "content.episodeTitle": value.markers.npaw.title_episode,
              "content.channel": value.markers.npaw.channel,
              "content.id": value.markers.npaw.content_id,
              "content.type": value.markers.npaw.content_type,
              "content.genre": value.markers.npaw.content_genre,
              "content.drm": value.markers.npaw.drm_type,
              "app.name": __APP_NAME__,
              "app.releaseVersion": __APP_VERSION__,
              "content.resource": value.video.url,
              "content.customDimension.1": value.markers.npaw.customDimension1,
              "content.customDimension.2": value.markers.npaw.customDimension2,
              "content.customDimension.3": value.markers.npaw.customDimension3,
              "content.customDimension.4": value.markers.npaw.channel,
              "content.customDimension.5": value.markers.npaw.content_genre,
              "content.customDimension.6": value.markers.npaw.content_type,
              "content.customDimension.7": value.markers.npaw.customDimension7,
              "content.customDimension.8": value.markers.npaw.customDimension8,
            });

            if (callbackAdController) {
              /**
               * We are passing video URL to AdController,
               * this is used on midroll since video url has a token which can be expired.
               * This will call again `.playContent()` method to get an up to date url with a valid token
               */
              callbackAdController(value.video.url);
            } else {
              try {
                this._sendSpideoEvent("play");

                this.orangeStatsData = value?.markers?.orange;
                sendOrangePageViewEvent({
                  pTitle: "Player",
                  pSubtitle: this.orangeStatsData?.contentSubLabel ?? this.orangeStatsData?.contentLabel,
                  providerId: this.orangeStatsData?.providerId ?? DEFAULT_ORANGE_PROVIDER_ID,
                });
                sendOrangeEvent({
                  eventType: "play",
                  category: "player",
                  providerId: this.orangeStatsData?.providerId ?? DEFAULT_ORANGE_PROVIDER_ID,
                  contentID: this.orangeStatsData?.contentId,
                  contentLabel: this.orangeStatsData?.contentLabel,
                  contentSubLabel: this.orangeStatsData?.contentSubLabel,
                });
                if (this.orangeStatsData) {
                  sendOrangeRecoEvent({
                    eventType: "play",
                    providerId: this.orangeStatsData.providerId ?? DEFAULT_ORANGE_PROVIDER_ID,
                    contentID: this.orangeStatsData.contentId,
                    contentLabel: this.orangeStatsData.contentLabel,
                    contentSubLabel: this.orangeStatsData.contentSubLabel,
                  });
                }
                this.player.init();
                this._createProgressBarNextVideo();
              } catch (error) {
                this.player?.getYouboraManager?.()?.getAdapter?.()?.fireError?.();
                Log.player.error(`init failed: ${error}`);
              }

              this.createListPlayer(value);
            }
          },
          error => {
            // Here use it to trigger and display an error
            Log.player.error("[PLAYER] Error !", error);

            this._onError([2008, 2009].includes(error.backendCode) ? "GEOLOC_ERROR" : "LOAD_CONTENT_ERROR");
          },
          () => {
            Log.player.log("[PLAYER] Complete !");
          }
        );
    };

    // waiting for countryCode (from edgescape) to be fetched before the app fetchs the video
    // if the app received the response and the playerPage was released, the app doesn't fetch the video
    this._edgescapePromise
      .then(response => {
        if (this._isReleased) return;
        const countryCode = parseEdgescapeCountry(response);
        _fetchPlayer(countryCode);
      })
      .catch(e => {
        Log.app.error(e);
        if (this._isReleased) return;
        // somthing went wrong with the edgescape API, the app fetchs the video anyway
        _fetchPlayer(undefined);
      });
  };

  loadSrc = (url: string, drmData?: DRMData): void => {
    setTimeout(() => {
      this._seekTimeListenerForSpideoUnreg = this.player.seekTime$.didChange(seekTime => {
        if (seekTime !== undefined) {
          if (this.player.isAdVideoPlaying() === false) {
            this._sendSpideoEvent("seek");
          }
        }
      });
      if (this._progressInS) {
        this.player.setSeekTime(this._progressInS);
      }
      try {
        this.player.setSrc(url, drmData);
      } catch (e) {
        this._onError("PLAYER_UNAVAILABLE");
      }
    }, 10);
  };

  startDurationAds = () => {
    const adInfo: IAdBreak = this.player.adDelegate?.getCurrentAdInfo();
    let durationInSeconds = adInfo.duration;
    const adsCount = adInfo.count;

    this.displayedAds.break_ads_number += 1;
    this.displayedAds.break_duration += durationInSeconds;

    this.elementHtmlAdsDuration = DOMHelper.createDivWithParent(
      this.rootElement,
      "AdsDuration",
      null,
      `publicité ${adInfo.index + 1}/${adsCount} - ${
        durationInSeconds > 9 ? durationInSeconds.toString() : "0" + durationInSeconds.toString()
      } sec`
    );
    this.intervalAdsDuration = setInterval(() => {
      durationInSeconds--;
      if (this.elementHtmlAdsDuration) {
        this.elementHtmlAdsDuration.innerText = `publicité ${adInfo.index + 1}/${adsCount} - ${
          durationInSeconds > 9
            ? durationInSeconds.toString()
            : durationInSeconds < 0
            ? "00"
            : "0" + durationInSeconds.toString()
        } sec`;
      }
    }, 1000);
  };

  stopDurationAds = () => {
    if (this.elementHtmlAdsDuration) {
      this.elementHtmlAdsDuration.remove();
    }
    if (this.intervalAdsDuration) {
      clearInterval(this.intervalAdsDuration);
    }
  };

  // For Ads
  adController = (): void => {
    let isAds = false;

    this._subAds = Plugin.getInstance()
      .fetchAvailableAds(this.item)
      .subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value => {
          isAds = true;
          Log.player.log("[adController] Next !", value);

          const _prerollAdBreak = value.prerollAdBreak;
          const _midrollAdBreak = value.midrollAdBreak;
          const _postrollAdBreak = value.postrollAdBreak;

          if (!this._beforepreRollPlayerPianoEventSent && _prerollAdBreak !== undefined) {
            this._calculateAdNumberAndDuration(_prerollAdBreak[0]);
            this._sendPianoAnalyticsPlayerEvent(FeatureType.beforeAdBreak);
            this._beforepreRollPlayerPianoEventSent = true;
          }

          const adConfig: IAdConfig = {
            prerollAdBreak: _prerollAdBreak,
            midrollAdBreak: _midrollAdBreak,
            postrollAdBreak: _postrollAdBreak,
            maxAdsPerVast: 4, // !!! VERY IMPORTANT TO SET THIS VALUE IN ORDER TO PLAY MORE THAN 1 VIDEO PER VAST
            logger: function (msg: string) {
              Log.ads.log(msg);
            },
            delegate: {
              shouldGetUrl: (_adController: AdController, _callback: () => void) => {
                return true;
              },
              shouldPlayPreRoll: (_adController: AdController) => {
                return true;
              },
              shouldPlayMidRoll: (_adController: AdController) => {
                return true;
              },
              shouldPlayPostRoll: (_adController: AdController) => {
                return false;
              },
              shouldStopAdVideo: (_adController: AdController) => {
                return true;
              },
            },
            listener: {
              adControllerDidStartVideoAd: (_adController: AdController, _type: string) => {
                Log.ads.log("[AdController][adControllerDidStartVideoAd]");
                /*
                 ** Starts a specific pause hit at the beginning of a midRoll
                 ** Pass this.eStat.isPauseMidroll = true
                 ** This flag prevents the sending of events during all concecutive midroll
                 */
                switch (_type) {
                  case "pre-roll":
                    this.startDurationAds();
                    break;
                  case "mid-roll": {
                    this.eStat.sendPauseMidroll(
                      this.eStatData,
                      this.player.currentTime$.value,
                      this.item.id,
                      this.player.adPlaying$
                    );
                    this.startDurationAds();
                    break;
                  }
                }
              },
              adControllerDidEndVideoAd: (_adController: AdController, _type: string) => {
                Log.ads.log("[AdController][adControllerDidEndVideoAd]");
                switch (_type) {
                  case "pre-roll":
                    this.stopDurationAds();
                    break;
                  case "mid-roll":
                    this.stopDurationAds();
                    break;
                }
              },
              adControllerDidUpdateProgress: (
                _adController: AdController,
                _theElapsedSeconds: number,
                _theRemainingSeconds: number
              ) => {
                Log.ads.log("[adController][adControllerDidUpdateProgress]");
              },
              adControllerOnEvent: (_adController: AdController, event: AdControllerEvent) => {
                const adInfo = _adController.VideoPlayerDelegate.getCurrentAdInfo();

                if (adInfo !== undefined) {
                  const adAdapter = this.player.getYouboraManager().getAdsAdapter();
                  const adIndex = adInfo.index;
                  const adCount = adInfo.count;

                  switch (event) {
                    case "start": {
                      /**
                       * User is watching its first ad on Player Page
                       * Firing `/adManifest`, `/adBreakStart`
                       */
                      if (adIndex === 0) {
                        adAdapter?.fireManifest();

                        adAdapter?.fireBreakStart();
                      }

                      let position: "pre" | "mid" | "post" = "pre";
                      switch (adInfo.timeOffset) {
                        case "pre-roll":
                          position = "pre";
                          break;
                        case "mid-roll":
                          position = "mid";
                          break;
                        case "post-roll":
                          position = "post";
                          break;
                      }

                      /**
                       * Ad starts to play
                       * Firing `/adStart`, `/adJoinTime`
                       */
                      const params = {
                        position,
                        adNumber: adIndex + 1,
                        adResource: encodeURI(adInfo.videoURL),
                        adCampaign: adInfo.name,
                      } as const;

                      adAdapter?.fireStart(params);

                      adAdapter?.fireJoin({
                        ...params,
                        adTitle: adInfo.name,
                      });
                      break;
                    }
                    case "firstQuartile": {
                      /**
                       * User watched 25% of the ad video
                       * Firing `/adQuartile`
                       */
                      adAdapter?.fireQuartile(1);
                      break;
                    }
                    case "midpoint": {
                      /**
                       * User watched 50% of the ad video
                       * Firing `/adQuartile`
                       */
                      adAdapter?.fireQuartile(2);
                      break;
                    }
                    case "thirdQuartile": {
                      /**
                       * User watched 75% of the ad video
                       * Firing `/adQuartile`
                       */
                      adAdapter?.fireQuartile(3);
                      break;
                    }
                    case "complete": {
                      /**
                       * User has completed to watch an ad
                       * Firing `/adStop`
                       */
                      adAdapter?.fireStop();

                      /**
                       * User has watched all the ads
                       * Firing `/adBreakStop`
                       */
                      if (adIndex === adCount - 1) {
                        adAdapter?.fireBreakStop();
                        if (!this._afterpreRollPlayerPianoEventSent) {
                          this._sendPianoAnalyticsPlayerEvent(FeatureType.afterAdBreak);
                          this._afterpreRollPlayerPianoEventSent = true;
                        }
                      }
                      break;
                    }
                  }
                }
              },
            },
          };

          const adController = new AdController(adConfig, this.playContent);
          this.player.adController(adController);
        },
        error => {
          // Here use it to trigger and display an error
          Log.player.error("[adController] Error !", error);
          this.playContent();
        },
        () => {
          Log.player.log("[adController] Complete !");
          this.playContent();
        }
      );
  };

  onRelease = () => {
    this._isReleased = true;
    this.playerStateUnregister();
    this.eStat.clearPolling();
    window.clearInterval(this._intervalSpideoDuration);
    window.clearTimeout(this.uiVisibilityTimer);
    document.getElementById("videoBackground")?.classList.remove("active");
    this._seekTimeListenerForSpideoUnreg?.();
    this._sendSpideoEvent("stop");
    this.eStat.sendStop(this.eStatData, this.player.currentTime$.value, this.item.id);
    this._subAds?.unsubscribe();
    this._subPlayer?.unsubscribe();
    this._subHasNext?.unsubscribe();
    this.loader.onRelease();
    currentPlayer = undefined;
    // The player have to be the last to be released, if the player is released before the others,
    // all listeners on the player will be triggered and potentially put the playerPage in a unwanted state
    // Found by fixing https://jira.ftven.net/browse/SMARTTV-176
    // also fix this.eStat.sendStop sent with currenTime to 0 instead of real current progress
    this.player.release();
  };

  exitPlayer = () => {
    const user = Plugin.getInstance().user;

    sendOrangeEvent({
      eventType: "stop",
      category: "player",
      providerId: this.orangeStatsData?.providerId ?? DEFAULT_ORANGE_PROVIDER_ID,
      contentID: this.orangeStatsData?.contentId,
      contentLabel: this.orangeStatsData?.contentLabel,
      contentSubLabel: this.orangeStatsData?.contentSubLabel,
    });
    if (this.orangeStatsData) {
      sendOrangeRecoEvent({
        eventType: "stop",
        providerId: this.orangeStatsData.providerId,
        contentID: this.orangeStatsData.contentId,
        contentLabel: this.orangeStatsData.contentLabel,
        contentSubLabel: this.orangeStatsData.contentSubLabel,
      });
    }

    if (!this.player.isAdVideoPlaying() && this.isLive$.value === false && user.isActive()) {
      // ensure offset if between 0 and duration
      const offset = Math.max(
        0,
        Math.min(this.item.metadata.duration, Math.floor(this.player.seekTime$.value ?? this.player.currentTime$.value))
      );
      Plugin.getInstance()
        .putHits(user, this.item, offset)
        .subscribe(
          value => {
            Log.api.log("[PlayerPage] putHits next !", value);
          },
          error => {
            Log.api.log("[PlayerPage] putHits Error !", error);
          }
        );
      ApiOrange.fetchReplayPosition(this.item.id, offset, getOrangeProviderIdFromBroadcastChannel(this.item));
      PlayHistoryHelper.updateOffset(this.item, offset);
    }
    navigationStack.removePage(this);
  };

  showPlayerUI() {
    if (!this.uiVisible$.value && !this.player.adPlaying$.value) {
      this.uiVisible$.value = true;
      this.rootElement.style.background =
        "linear-gradient(180deg, rgba(35, 35, 35, 0) 0%, rgba(35, 35, 35, 0.82) 100%)";
      this.delegate?.onShown?.();
      //document.getElementById("playerInfoBanner")?.classList.remove("hidden");
    }
    window.clearTimeout(this.uiVisibilityTimer);
    this.uiVisibilityTimer = window.setTimeout(() => {
      this.hidePlayerUI();
    }, 5000);
  }

  hidePlayerUI() {
    this.uiVisible$.value = false;
    this.rootElement.style.background = "";
    this.setFocusOnButton();
    //document.getElementById("playerInfoBanner")?.classList.add("hidden");
  }

  //receives an object, returns true if none of the values inside are null, false if otherwise
  isObjectComplete(object: { [key: string]: number | null }) {
    return Object.values(object).every(value => value !== null);
  }

  buildSkipButtonsObject(object: { [key: string]: number }, keyName: string) {
    this.skipButtonsTimecodes[keyName] = {
      timecode: object.timecode,
      time_before_dismiss: object.timecode + object.time_before_dismiss,
      duration: object.timecode + object.duration,
    };
  }

  setFocusOnButton() {
    this.list?.setFocusOnIndex(0);
    const view = this.list?.viewFromIndex(this.list.focusedIndex$.value);
    if (view instanceof PlayerControlList) {
      view.list?.setFocusOnIndex(1);
      const viewButton = view.list?.viewFromIndex(view.list.focusedIndex$.value);
      if (viewButton instanceof PlayerButtonList) {
        //After ui is hidden check if any of the skip buttons are visible, if so put focus on that button
        if (viewButton.skipIntroButton?.buttonState$.value == SkipButtonStateValue.visible) {
          viewButton.buttonList?.setFocusOnIndex(5);
        } else if (viewButton.skipRecapButton?.buttonState$.value == SkipButtonStateValue.visible) {
          viewButton.buttonList?.setFocusOnIndex(4);
        } else if (viewButton.nextEpisodeButton?.buttonState$.value == SkipButtonStateValue.visible) {
          viewButton.buttonList?.setFocusOnIndex(3);
        } else {
          viewButton.buttonList?.setFocusOnIndex(1);
        }
      }
    }
  }

  private _initSpideoTimer() {
    if (this.isLive$.value === false) {
      this._intervalSpideoDuration = window.setInterval(() => {
        if (this.player.state$.value === PlayerState.PLAYING) {
          this._sendSpideoEvent("playing");
        }
      }, 60 * 1000);
    }
  }

  private _sendSpideoEvent = (eventName: "playing" | "stop" | "pause" | "startPlaying" | "seek" | "play") => {
    if (this.isLive$.value === false) {
      if (eventName === "playing" || eventName === "stop" || eventName === "pause") {
        const duration = Math.max(0, this.item.metadata.duration);
        const offset = Math.max(
          0,
          Math.min(duration, Math.floor(this.player.seekTime$.value ?? this.player.currentTime$.value))
        );
        const progressPercent = Math.ceil(duration <= 0 || offset <= 0 ? 0 : (offset / duration) * 100);
        Spideo.sendSpideoEvent({
          eventName,
          videoId: this.item.id,
          timeViewed: offset,
          completion: progressPercent,
        });
      } else {
        Spideo.sendSpideoEvent({ eventName, videoId: this.item.id });
      }
    }
  };

  private _displayErrorPopup = (message: string) => {
    this.player.getYouboraManager().getAdapter()?.fireError();
    const playerErrorPage = new PlayerErrorPage(
      message,
      () => {
        navigationStack.removePage(playerErrorPage);
      },
      false
    );
    navigationStack.pushPage(playerErrorPage);
    navigationStack.removePage(this);
  };

  private _onError = (errorCode: string) => {
    const description =
      "Problème lié à la lecture des contenus dans le service partenaire lors de l'initialisation du player";
    const popupMsg =
      "Accès au programme momentanément indisponible.\n" +
      " \n" +
      " Un problème technique ne permet pas d’accéder au programme que vous souhaitez visionner.\n" +
      " Il est possible que ce programme ne soit plus disponible.\n" +
      " \n" +
      " Si le problème persiste, rendez-vous sur orange.fr, rubrique assistance, tapez H03 dans le moteur de recherche.\n" +
      " \n" +
      " Code erreur : H03-FTV ";

    const error = orangeErrorBuild({
      errorCode: "H03-FTV",
      orangeErrorCode: 99,
      popUp: {
        code: "H03-FTV",
        message: popupMsg,
      },
      message: errorCode,
      description,
    });
    ApiOrange.fetchErrorLogsCollector(error);

    this._displayErrorPopup(popupMsg);
  };

  onNav = (key: Keys): boolean => {
    const view = this.list?.viewFromIndex(this.list.focusedIndex$.value);
    switch (key) {
      //triggers when skip button is pressed while the rest of the ui is hidden
      case Keys.select:
        if (view instanceof PlayerControlList && this.uiVisible$.value == false) {
          view.list?.setFocusOnIndex(1);
          const viewButton = view.list?.viewFromIndex(view.list.focusedIndex$.value);
          if (viewButton instanceof PlayerButtonList) {
            switch (viewButton.buttonList?.focusedId$.value) {
              case "skipIntro":
                if (viewButton.skipIntroButton?.buttonState$.value == SkipButtonStateValue.visible)
                  viewButton.skipToValue(ButtonType.skipIntro);
                break;
              case "skipRecap":
                if (viewButton.skipRecapButton?.buttonState$.value == SkipButtonStateValue.visible)
                  viewButton.skipToValue(ButtonType.skipRecap);
                break;
              case "nextVideo":
                if (viewButton.nextEpisodeButton?.buttonState$.value == SkipButtonStateValue.visible)
                  viewButton.endVideo();
                break;
            }
          }
        }
        break;
      case Keys.stop:
      case Keys.back:
        this.exitPlayer();
        if (platform.type === PlatformType.orange && Config.openedPlayerFromDeeplink) {
          platform.exit();
        }
        return true;
    }
    if (this.player.state$.value != PlayerState.SEEKING) {
      if (this.uiVisible$.value == false) {
        this.showPlayerUI();
        return true;
      } else this.showPlayerUI();
    }
    return false;
  };
}
