import "./playerControls.scss";

import { Player } from "~libs/player";
import { createListComponent, DOMHelper, IListComponent, Listenable, StaticModelSource, View } from "~ui-lib";

import { PlayableItem } from "../../models/playableItem";
import { InfoBanner } from "./infobanner";
import { PlayerButtonList } from "./playerButtons";
import { PlayerPageSpriteSheet, SkipButtonTimecodesObjectType } from "./playerPage";
import { PlayerProgress } from "./playerProgress";

enum PlayerComponentType {
  progress = "progress",
  buttonList = "buttonList",
}

export interface PlayerControlParams {
  isLive$: Listenable<boolean>;
  uiVisible$: Listenable<boolean>;
  player: Player;
  item: PlayableItem;
  skipButtonsTimecodes: SkipButtonTimecodesObjectType;
  exitPlayer: () => void;
  endVideo: () => void;
}

export class PlayerControlList extends View {
  uiVisibleUnregister: () => void;
  private isLiveChange: () => void;
  isLive$: Listenable<boolean>;
  list: IListComponent | undefined;

  constructor(params: PlayerControlParams, item: PlayableItem, spritesheets: PlayerPageSpriteSheet | undefined) {
    super(DOMHelper.createDivWithParent(null, null, "PlayerControlListContainer"));
    new InfoBanner(this.rootElement, item, params.isLive$.value);

    this.isLive$ = params.isLive$;
    this.list = this.delegate = createListComponent({
      rootElement: DOMHelper.createDivWithParent(this.rootElement, "controls", "controls"),
      modelSource: new StaticModelSource(
        // On affiche pas les boutons quand on joue un flux live. Faudra le remetre quand il faudra les implémenter
        this.isLive$.value
          ? [PlayerComponentType.progress]
          : [PlayerComponentType.progress, PlayerComponentType.buttonList]
      ),
      viewFactory: model => {
        switch (model) {
          case PlayerComponentType.progress:
            return new PlayerProgress(this.isLive$, params.player, params.item, spritesheets);

          case PlayerComponentType.buttonList:
            return new PlayerButtonList(
              this.isLive$,
              item.metadata,
              params.player,
              params.endVideo,
              params.skipButtonsTimecodes
            );
        }
      },
      horizontal: false,
    });
    this.uiVisibleUnregister = params.uiVisible$.didChange(uiVisible => {
      //this.rootElement.hidden = !uiVisible;
      const uiContainer = document.getElementById("uiContainer");
      if (uiContainer) {
        if (uiVisible) {
          DOMHelper.addClass(uiContainer, "visible");
        } else {
          DOMHelper.removeClass(uiContainer, "visible");
        }
      }
    });

    this.isLiveChange = this.isLive$.didChange(isLive => {
      const uiContainer = document.getElementById("uiContainer");
      if (uiContainer) {
        if (isLive) {
          DOMHelper.addClass(uiContainer, "live");
        } else {
          DOMHelper.removeClass(uiContainer, "live");
        }
      }
    });
  }

  onRelease = () => {
    this.uiVisibleUnregister();
    this.isLiveChange();
  };
}
