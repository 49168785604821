import "./eventTab.scss";

import { Event } from "~models/event";
import { ItemCollection } from "~models/itemCollection";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { MEASwimlane } from "~swimlaneViews/meaSwimlane";
import { PlaylistMixedSwimlane } from "~swimlaneViews/playlistMixedSwimlane";
import { PlaylistProgramSwimlane } from "~swimlaneViews/playlistProgramSwimlane";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";

import { parseMarkerPianoPageDisplay } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { PlaylistTagSwimlane } from "../../swimlaneViews/playlistTagSwimlane";
import { sendOrangePageViewEvent } from "../../tools/analytics/orangeStats";
import { sendClickPianoEventOnSelectTile, sendPianoAnalytic } from "../../tools/analytics/piano";
import { DEFAULT_ORANGE_PROVIDER_ID } from "../../tools/apiOrange/orangeProviderId";
import { mappingMedalsEvent } from "../../tools/idalgo/idalgo";
import { MedalBoardView } from "../../views/medalBoardView";
import { LoaderStaticPageHelper } from "../loaderStatic/loaderStaticPage";
import { MedalsBoardPage } from "../medalsBoardPage/medalsBoardPage";
import { pushTabWithMenu } from "../rootPage";

export class EventTab extends View {
  listComponent?: IListComponent<ItemCollection>;
  private _scrollIndexUnregister?: () => void;
  private _source: Event;
  /**
   * Source has been fetched.
   * Meaning that source data is not partial.
   */
  private _isSourceReady = false;
  private readonly _position: number | undefined;

  constructor(source: Event, position?: number) {
    super(DOMHelper.createDivWithParent(null, "EventTab"));
    this._source = source;
    this._position = position;

    // Show loader and start anim
    LoaderStaticPageHelper.display();

    this._fetchSource();
  }

  onShown() {
    if (this._isSourceReady) {
      this._sendAnalyticsData();
    }
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDetailed(this._source)
      .subscribe(
        value => {
          sendClickPianoEventOnSelectTile(this._source, value[0], this._position);

          // Here use it to create the UI
          Log.api.log("[DETAILED EVENT] Next !", value);
          this._source = value[0];
          const items = value[0]?.items ? value[0].items : [];
          this._onSourceReady(items);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.error("[DETAILED EVENT] Error !", error);
        },
        () => {}
      );
  }

  private _onSourceReady(source: ItemCollection[]) {
    new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      source: this._source.getBackgroundImgUrl(),
    });
    this._isSourceReady = true;
    this._sendAnalyticsData();

    const logoImgUrl = this._source.getLogoImgUrl();
    //FIXME: when data is updated, check if logo image returns smthing or not
    if (logoImgUrl.length && this._source.type !== "tag") {
      DOMHelper.createImg(this.rootElement, "EventLogo", null, logoImgUrl, "");
    }

    const medalsEvent = mappingMedalsEvent(this._source.extras?.url_complete);
    if (medalsEvent !== undefined) {
      const medalsBoardElement = new ItemCollection("medalBoard", "medal_board", "", "", [], [], [], false);
      //The board of medals must be in second position
      source.splice(1, 0, medalsBoardElement);
    }

    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, null, "listContainer"),
        modelSource: new StaticModelSource(source),
        viewFactory: model => {
          if (model.type == "mise_en_avant") {
            return new MEASwimlane(model);
          } else if (model.type == "playlist_video") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "playlist_program") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "playlist_mixed") {
            return new PlaylistMixedSwimlane(model);
          } else if (model.type == "playlist_collection") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "live") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "medal_board" && medalsEvent !== undefined) {
            return new MedalBoardView(medalsEvent);
          } else if (model.type == "playlist_tag") {
            return new PlaylistTagSwimlane(model, this._source.getBackgroundImgUrl(), this._source.getLogoImgUrl());
          } else {
            //default
            return new PlaylistMixedSwimlane(model);
          }
        },
        arrowFactory: verticalArrowFactory,
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
        mouseFocusInPageOnly: true,
        onSelect: model => {
          if (model.type == "medal_board" && medalsEvent !== undefined) {
            pushTabWithMenu(new MedalsBoardPage(medalsEvent), "MedalsBoardPage");
            return true;
          }
          return false;
        },
      },
      mainList => {
        LoaderStaticPageHelper.hide();

        const defaultIndex = 0;
        mainList.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(mainList.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );

    this._scrollIndexUnregister = this.listComponent.scrollIndex$.didChange((newIndex, oldIndex) => {
      DOMHelper.addClass(this.listComponent?.viewFromIndex(newIndex)?.rootElement, "onTop");
      DOMHelper.removeClass(this.listComponent?.viewFromIndex(oldIndex)?.rootElement, "onTop");
      if (newIndex !== undefined) {
        this.listComponent?.setFocusOnIndex(newIndex);
      }
    });
  }

  private _sendAnalyticsData = () => {
    const markerPiano = parseMarkerPianoPageDisplay(this._source.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
    } else {
      Log.analytics.error("Failed to send piano analytics");
    }
    sendOrangePageViewEvent({
      pTitle: this._source.title,
      providerId: DEFAULT_ORANGE_PROVIDER_ID,
    });
  };

  onRelease = () => {
    this._scrollIndexUnregister?.();
    LoaderStaticPageHelper.hide();
  };
}
