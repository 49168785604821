"use strict";

import "./loaderStaticPage.scss";

import { DOMHelper, platform, PlatformType, View } from "~ui-lib";

import { OrangeDeviceInfo } from "../../tools/apiOrange/orangeDeviceInfo";

class LoaderStaticView extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, null, "loaderStatic"));
    DOMHelper.createDivWithParent(
      this.rootElement,
      undefined,
      "loaderStaticText",
      "Chargement en cours,\nveuillez patienter..."
    );
  }
}

const _loaderStaticView = new LoaderStaticView();
let _navigationStackElement: HTMLElement | undefined | null = undefined;

export const LoaderStaticPageHelper =
  platform.type === PlatformType.orange && OrangeDeviceInfo.isNewBox()
    ? {
        init: (navigationStackElement: HTMLElement | undefined | null) => {
          _navigationStackElement = navigationStackElement;
        },
        display: () => {
          _navigationStackElement?.appendChild(_loaderStaticView.rootElement);
        },
        hide: () => {
          _loaderStaticView.rootElement.parentElement?.removeChild(_loaderStaticView.rootElement);
        },
        isDisplayed: (): boolean => {
          return !!_loaderStaticView.rootElement.parentElement;
        },
      }
    : {
        init: (navStackDivElement: HTMLElement | undefined | null) => {},
        display: () => {},
        hide: () => {},
        isDisplayed: (): boolean => {
          return false;
        },
      };
