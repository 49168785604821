import { EMPTY, Observable } from "rxjs";
import { catchError, mergeMap, toArray } from "rxjs/operators";

import { platform, PlatformType } from "../../bud-lite-tv/src/lib";
import { ItemCollection } from "../models/itemCollection";
import { PlayHistoryHelper } from "../tools/playerHistoryHelper";
import { BackendErrorException, UnknownException } from "./exceptions";
import { parseItems } from "./parser";
import { Plugin } from "./plugin";

const fetchResumeSwimlaneOrange = (): Observable<any[]> => {
  return Plugin.getInstance()
    .fetchContentByIds(PlayHistoryHelper.getIds())
    .pipe(
      catchError((e: BackendErrorException | UnknownException) => {
        Log.app.error(e);
        return EMPTY;
      }),
      mergeMap(json => {
        const itemCollection = new ItemCollection(
          "seeks_orange_" + Math.random().toString(36).substr(2, 9),
          "seeks",
          "Mes lectures en cours",
          undefined,
          json,
          [],
          {},
          false
        );
        Log.app.log("collection resume ", json);
        return parseItems(itemCollection);
      }),
      toArray()
    );
};

export const fetchResumeSwimlane = async (): Promise<any[]> => {
  if (platform.type === PlatformType.orange) {
    return fetchResumeSwimlaneOrange().toPromise();
  } else {
    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      return Plugin.getInstance().fetchResumeSwimlane(user).toPromise();
    }
  }

  return [];
};
