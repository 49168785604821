import "./digitButtonView.scss";

import { DOMHelper, View } from "~ui-lib";

export class DigitButtonViewItem extends View {
  constructor(digit: string) {
    super(DOMHelper.createDivWithParent(null, null, "digitButtonViewItem"));
    DOMHelper.createDivWithParent(this.rootElement, null, "digitButtonViewItemText", digit);
  }
}
