import "./accountSwimlane.scss";

import { CGU, CGUType } from "~models/cgu";
import { CGUPage } from "~pages/cgu/cguPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { accountView } from "~views/accountCardView";

import { Config } from "../../config";
import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { ParametersTab } from "../../pages/cmp/parameters/parametersPage";
import { sendOrangeEvent } from "../../tools/analytics/orangeStats";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { DEFAULT_ORANGE_PROVIDER_ID } from "../../tools/apiOrange/orangeProviderId";

export class AccountSwimlane extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, "AccountSwimlane", "accountSwimlane"));
    if (Config.enabledFeatures.login) {
      const text = DOMHelper.createDivWithParent(this.rootElement, "staticTextBox");
      DOMHelper.createDivWithParent(text, null, "accountTitleText", "commencez, reprenez, suivez");
      DOMHelper.createDivWithParent(
        text,
        null,
        "accountText",
        "Grâce à votre compte, retrouvez tous vos favoris et reprenez vos vidéos là où vous les avez arrêtées. Il suffit de se connecter !"
      );
    }
    const listBox = DOMHelper.createDivWithParent(this.rootElement, null, "listBox");
    const source: CGU[] = [];
    Config.enabledFeatures.cmp && source.push({ id: CGUType.cmp, type: CGUType.cmp, title: CGUType.cmp });
    source.push({ id: CGUType.mentionsLegales, type: CGUType.mentionsLegales, title: CGUType.mentionsLegales });
    Config.enabledFeatures.account && source.push({ id: CGUType.cgu, type: CGUType.cgu, title: CGUType.cgu });

    this.delegate = createListComponent({
      rootElement: listBox,
      modelSource: new StaticModelSource(source),
      viewFactory: model => new accountView(model),
      horizontal: false,
      pageSize: 3,
      spatialFocus: true,
      onSelect: this.onSelect,
    });
  }
  onSelect = (model: CGU): boolean => {
    switch (model.type) {
      case CGUType.cgu:
        sendPianoAnalytic("click.action", {}, { feature: "unitaire_cta_cgu", click: "cgu" });
        navigationStack.pushPage(new CGUPage(model));
        return true;
      case CGUType.mentionsLegales:
        sendPianoAnalytic("click.action", {}, { feature: "unitaire_cta_mentions_legales", click: "mentions_legales" });
        sendOrangeEvent({
          eventType: "click",
          category: "unitaire_cta_mentions_legales",
          label: "Mentions légales",
          providerId: DEFAULT_ORANGE_PROVIDER_ID,
        });
        navigationStack.pushPage(new CGUPage(model));
        return true;
      case CGUType.cmp:
        sendPianoAnalytic(
          "click.action",
          {},
          { feature: "unitaire_cta_gerer_mes_consentements", click: "gerer_mes_consentements" }
        );
        navigationStack.pushPage(new ParametersTab());
        return true;
    }
    return false;
  };
}

export class AccountSwimlaneConnected extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, "AccountSwimlaneConnected", "AccountSwimlaneConnected"));
    const text = DOMHelper.createDivWithParent(this.rootElement, "staticTextBox");
    DOMHelper.createDivWithParent(text, null, "accountTitleText", "Bienvenue !");
    DOMHelper.createDivWithParent(
      text,
      null,
      "accountText",
      "Vous êtes connecté" +
        (Plugin.getInstance().user.infos?.email ? " avec votre adresse " + Plugin.getInstance().user.infos?.email : "")
    );
    const listBox = DOMHelper.createDivWithParent(this.rootElement, null, "listBox");
    const source: CGU[] = [];
    Config.enabledFeatures.cmp && source.push({ id: CGUType.cmp, type: CGUType.cmp, title: CGUType.cmp });
    source.push({ id: CGUType.mentionsLegales, type: CGUType.mentionsLegales, title: CGUType.mentionsLegales });
    Config.enabledFeatures.account && source.push({ id: CGUType.cgu, type: CGUType.cgu, title: CGUType.cgu });

    this.delegate = createListComponent({
      rootElement: listBox,
      modelSource: new StaticModelSource(source),
      viewFactory: model => new accountView(model),
      horizontal: false,
      pageSize: 3,
      spatialFocus: true,
      onSelect: this.onSelect,
    });
  }
  onSelect = (model: CGU): boolean => {
    switch (model.type) {
      case CGUType.cgu:
        sendPianoAnalytic("click.action", {}, { feature: "unitaire_cta_cgu", click: "cgu" });
        navigationStack.pushPage(new CGUPage(model));
        return true;
      case CGUType.mentionsLegales:
        sendPianoAnalytic("click.action", {}, { feature: "unitaire_cta_mentions_legales", click: "mentions_legales" });
        sendOrangeEvent({
          eventType: "click",
          category: "unitaire_cta_mentions_legales",
          label: "Mentions légales",
          providerId: DEFAULT_ORANGE_PROVIDER_ID,
        });
        navigationStack.pushPage(new CGUPage(model));
        return true;
      case CGUType.cmp:
        sendPianoAnalytic(
          "click.action",
          {},
          { feature: "unitaire_cta_gerer_mes_consentements", click: "gerer_mes_consentements" }
        );
        navigationStack.pushPage(new ParametersTab());
        return true;
    }
    return false;
  };
}
